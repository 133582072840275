import actions from "../../constants/actionsTypes";
import { INotes, INote } from "../reducers/notes";

interface IAddNotes extends INote {
  onSuccess: () => void;
}

export const getNotesStart = (params: { [key: string]: string }) => ({
  type: actions.notes.get.start,
  payload: params,
});

export const getNotesSuccess = (payload: INotes) => ({
  type: actions.notes.get.success,
  payload,
});

export const getNotesFailure = (error: any) => ({
  type: actions.notes.get.fail,
  payload: error,
});

export const resetNotes = () => ({
  type: actions.notes.reset,
});

export const addNotesStart = (payload: IAddNotes) => ({
  type: actions.notes.add.start,
  payload,
});

export const addNotesSuccess = () => ({
  type: actions.notes.add.success,
});

export const addNotesFailure = (error: any) => ({
  type: actions.notes.add.fail,
  payload: error,
});

export const updateNotesStart = (payload: Partial<IAddNotes>) => ({
  type: actions.notes.update.start,
  payload,
});

export const updateNotesSuccess = () => ({
  type: actions.notes.update.success,
});

export const updateNotesFailure = (error: any) => ({
  type: actions.notes.update.fail,
  payload: error,
});

