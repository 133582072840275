import React from "react";

import closeIcon from "../../assets/images/ic-close-grey.svg";
import { IProps } from "./Modal.component";
import { useAutoFocus } from "./Modal.hooks";

import "./Modal.styles.scss";

interface IContentrops extends Partial<IProps> {
  classes: string;
  bodyClasses: string;
  onEsc: (e: React.KeyboardEvent) => void;
}

export default function ModalContent({
  classes,
  bodyClasses,
  onClose,
  title,
  children,
  onEsc,
}: IContentrops) {
  const ref = useAutoFocus();
  return (
    <div className={classes} tabIndex={-1} ref={ref} onKeyDown={onEsc}>
      <div className="modal__overlay" onClick={onClose}>
        &nbsp;
      </div>
      <div className={bodyClasses}>
        <div className="modal__header">
          <span className="modal__title">{title}</span>
          <span className="modal__close" onClick={onClose}>
            <img
              src={closeIcon}
              className="modal__close-icon"
              alt="close-icon"
            />
          </span>
        </div>
        <div className="modal__wrapper">{children}</div>
      </div>
    </div>
  );
}
