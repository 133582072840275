import actions from "../../constants/actionsTypes";
import PATIENT_STATUSES from "../../constants/patientStatuses";
import { Action } from "./index";

export interface Address {
  address: string;
  city: string;
  state: string;
  zip: string;
}

export interface Physician {
  company: string;
  firstName: string;
  lastName: string;
  phone: string;
  uuid?: string;
}

export interface Doctor {
  userFirstName: string;
  userLastName: string;
  userId: number;
}

export interface IPatient {
  address: Address | null;
  city: any;
  dob: string;
  firstName: string;
  lastName: string;
  company: string;
  sex: string;
  primaryPhone: string;
  secondaryPhone: string;
  healthCondition: string;
  physician?: Physician | null;
  doctors: Doctor[];
  state: string;
  uuid: string;
  untrained: boolean;
  firstCase?: number;
  ccm: boolean;
  rpm: boolean;
  cde: boolean;
  mht: boolean;
  mh: boolean;
  authorizedPatientRepresentative: string;
  lastFaceToFace: string;
  relationship: string;
  consentDate: string;
  demographicInformationProvided: boolean;
  insuranceInformationProvided: boolean;
  lastMeasurement: string;
  isAlert: boolean | null;

  primaryInsuranceCompanyName: string;
  primaryInsurancePolicyHolder: string;
  primaryInsuranceGroupNumber: string;
  primaryInsuranceMemberId: string;
  insuranceImage: string | File | null;
  backsiteInsuranceImage: string | File | null;
  demographicSheetImage: string | File | null;
  primaryInsuranceDeviceCoverageVerified: boolean | null;
  primaryInsuranceOutOfNetworkCoverage: boolean | null;

  secondaryInsuranceCompanyName: string;
  secondaryInsurancePolicyHolder: string;
  secondaryInsuranceGroupNumber: string;
  secondaryInsuranceMemberId: string;
  secondaryInsuranceDeviceCoverageVerified: boolean | null;
  secondaryInsuranceOutOfNetworkCoverage: boolean | null;

  email: string;
  hhAlternatePhoneNumber: string | null;
  hhEmail: string | null;
  hhName: string | null;
  hhPhoneNumber: string | null;
  hhPrimaryContact: string | null;
  hhScheduleFriday: string | null;
  hhScheduleMonday: string | null;
  hhScheduleSaturday: string | null;
  hhScheduleSunday: string | null;
  hhScheduleThursday: string | null;
  hhScheduleTuesday: string | null;
  hhScheduleWednesday: string | null;
  healthcareCareGiverName: string | null;
  status: PATIENT_STATUSES | null;
}

export interface SearchPatientState {
  data: Array<IPatient>;
  cities: string[];
  companies: string[];
  states: string[];
  loading: boolean;
  loadingTable: boolean;
  count: number;
}

export interface PatientsState {
  search: SearchPatientState;
  error?: null | any;
}

const initialState: PatientsState = {
  search: {
    data: [],
    cities: [],
    companies: [],
    states: [],
    loading: false,
    loadingTable: false,
    count: 0,
  },
};

export default function events(
  state: PatientsState = initialState,
  action: Action
) {
  switch (action.type) {
    case actions.patients.list.get:
      return {
        ...state,
        search: {
          ...state.search,
          loading: true,
          loadingTable: true,
        },
      };
    case actions.patients.list.success:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload.results,
          count: action.payload.count,
          loading: false,
          loadingTable: false,
        },
      };
    case actions.patients.list.reset:
      return {
        ...state,
        search: initialState.search,
      };
    case actions.patients.list.fail:
      return {
        ...state,
        search: initialState.search,
      };

    case actions.patients.export.get:
      return {
        ...state,
        search: {
          ...state.search,
          loading: true,
          loadingTable: true,
        },
      };
    case actions.patients.export.success:
      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          loadingTable: false,
        },
      };
    case actions.patients.export.fail:
      return {
        ...state,
        search: initialState.search,
        error: action.payload,
      };
    default:
      return state;
  }
}
