import React from "react";

import "./InputEye.style.scss";
import ShowIcon from "../../assets/images/ic-show-eye.svg";
import HideIcon from "../../assets/images/ic-hide-eye.svg";

interface IProps {
  showEye: boolean;
  toogleEye: () => void;
}

export default function InputEye({ showEye, toogleEye }: IProps) {
  return (
    <div className="icon-wrapper">
      <img onClick={toogleEye} src={showEye ? ShowIcon : HideIcon} alt="eye" />
    </div>
  );
}
