import actions from "../../constants/actionsTypes";
import { IHealthGoals, IHealthGoal } from "../reducers/healthGoals";

export const getHealthGoalsStart = (params: { [key: string]: string }) => ({
  type: actions.healthGoals.get.start,
  payload: params,
});

export const getHealthGoalsSuccess = (payload: IHealthGoals) => ({
  type: actions.healthGoals.get.success,
  payload,
});

export const getHealthGoalsFailure = (error: any) => ({
  type: actions.healthGoals.get.fail,
  payload: error,
});

export const resetHealthGoals = () => ({
  type: actions.healthGoals.reset,
});

export const updateHealthGoalsStart = (payload: {
  patient: string;
  data: Array<IHealthGoal | null> | null;
}) => ({
  type: actions.healthGoals.update.start,
  payload,
});

export const updateHealthGoalsSuccess = (payload: IHealthGoals) => ({
  type: actions.healthGoals.update.success,
  payload,
});
export const updateHealthGoalsFailure = (error: any) => ({
  type: actions.healthGoals.update.fail,
  payload: error,
});
