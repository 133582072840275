import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { authSelector } from "../../store/sagas/auth";
import { LOCAL_DASHBOARD } from "../../constants/urls";
import leftArrow from "../../assets/images/ic-left-arrow.svg";
import rpmIcon from "../../assets/images/ic-rpm-nav-icon.svg";
import logo from "../../assets/images/logo.png";
import MAItems from "./MAItems.component";
import PCPItems from "./PCPItems.component";
import {
  useBackPressHandler,
  useToggleNavigationHandler,
} from "./Navigation.hooks";
import close from "../../assets/images/ic-close-grey.svg";
import DocItems from "./DocItems.component";
import TaskTimerPanel from "../TaskTimerPanel";
import { useTaskTimer } from "../../hooks";

import "./styles.scss";

export default function Navigation() {
  const navigationRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const { jobTitle } = useSelector(authSelector);
  const { showTimerPanel } = useTaskTimer();

  const isPreviewPath = pathname === LOCAL_DASHBOARD;

  const handleBackPress = useBackPressHandler();
  const handleToggleNavigation = useToggleNavigationHandler(navigationRef);

  if (isPreviewPath) {
    return null;
  }

  return (
    <div className="navigation__wrapper" ref={navigationRef}>
      <div
        className="navigation__wrapper__background"
        onClick={handleToggleNavigation}
      />
      <div className="navigation__headline">
        <div className="navigation__burger" onClick={handleToggleNavigation}>
          <span />
          <span />
          <span />
        </div>
        <img
          src={logo}
          alt="CareHalo"
          className="navigation__logo navigation__logo--mobile"
        />
      </div>
      <div className="navigation">
        <div className="navigation__top-bar">
          <div>
            <img
              src={leftArrow}
              alt="arrow"
              onClick={handleBackPress}
              className="navigation__top-bar-icon-back"
            />
            <img
              src={logo}
              alt="logo"
              className="navigation__top-bar-icon-logo"
            />
          </div>
          <img
            src={close}
            alt="close"
            onClick={handleToggleNavigation}
            className="block md:hidden mr-6"
          />
        </div>
        <div className="navigation__group">
          <img src={rpmIcon} alt="rpm" />
          <span>RPM</span>
        </div>
        {jobTitle === "Primary_Care_Physician" && (
          <PCPItems onClick={handleToggleNavigation} />
        )}
        {(jobTitle === "medical_assistant" || jobTitle === "doctor") && (
          <MAItems onClick={handleToggleNavigation} />
        )}
        {jobTitle === "doctor" && <DocItems onClick={handleToggleNavigation} />}
        {showTimerPanel && <TaskTimerPanel />}
      </div>
    </div>
  );
}
