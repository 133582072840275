import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import homeIcon from "../../assets/images/ic-home.svg";
import play_button from "../../assets/images/ic-play-button.svg";
import { useHeaderContext } from "../../context/header";
import defaultAvatarIcon from "../../assets/images/ic_avatar_default.svg";

import {
  LOCAL_DASHBOARD,
  LOCAL_SEARCH,
  LOCAL_PATIENT,
} from "../../constants/urls";
import ProfileModal from "../ProfileModal";
import { Button } from "../Form/Button";
import { authSelector } from "../../store/sagas/auth";
import { useCheckAvatarLink, useHeaderModal } from "./Header.hooks";

import "./Header.styles.scss";

function Header() {
  const { showModal, toggleModal } = useHeaderModal();
  const { isBrokenLink, onErrorHandler } = useCheckAvatarLink();

  const history = useHistory();
  const auth = useSelector(authSelector);

  const isPreviewPath = history.location.pathname === LOCAL_DASHBOARD;
  const isSearchPath = history.location.pathname === LOCAL_SEARCH;
  const { header } = useHeaderContext();
  const isHeaderBlock = header && !!Object.values(header).length;
  const isAuth = auth.token;

  return (
    <div className="header">
      <div className="header__left-container">
        {!isPreviewPath && !isHeaderBlock && (
          <>
            <img src={homeIcon} alt="home icon" className="header__icon" />
            <p className="header__title-text">RPM</p>
          </>
        )}
        {isHeaderBlock && header}
        {isSearchPath && (
          <Link to={LOCAL_PATIENT.replace("/:id", "")}>
            <Button
              type="primary"
              value="Add new patient"
              className="ml-4 md:ml-8"
            />
          </Link>
        )}
      </div>
      {isAuth && (
        <div className="header__logout" onClick={toggleModal}>
          {auth.avatar && !isBrokenLink && (
            <img
              src={auth.avatar}
              alt="intervention"
              className="header__logout__profile-icon"
              onError={onErrorHandler}
            />
          )}
          {(!auth.avatar || isBrokenLink) && (
            <img
              src={defaultAvatarIcon}
              alt="intervention"
              className="header__logout__profile-icon"
            />
          )}
          <label className="header__logout_text">{`${auth.firstName} ${auth.lastName}`}</label>
          <img
            src={play_button}
            alt="logout"
            className="header__logout__icon"
          />
        </div>
      )}
      {isAuth && <ProfileModal isDisabled={!showModal} onClose={toggleModal} />}
    </div>
  );
}

export default Header;
