import actions from "../../constants/actionsTypes";
import { Case } from "../../models/basicModels";
import { InterventionState } from "../../components/Intervention";

export interface CasesAction {
  limit: number;
  offset: number;
}

export interface UpdateCaseAction {
  case: Case;
}

export interface GetCaseAction {
  id: number;
}

export const underReviewCases = (payload: CasesAction) => ({
  type: actions.cases.get.review,
  payload,
});

export const historyCases = (payload: CasesAction) => ({
  type: actions.cases.get.history,
  payload,
});

export const unsuccessfulCases = (payload: CasesAction) => ({
  type: actions.cases.get.unsuccessful,
  payload,
});

export const updateCase = (payload: Case) => ({
  type: actions.cases.update.send,
  payload,
});

export const resetCaseUpdate = () => ({
  type: actions.cases.creation.reset,
});

export const createCase = (payload: InterventionState) => ({
  type: actions.cases.creation.create,
  payload,
});

export const getCase = (payload: GetCaseAction) => ({
  type: actions.cases.view.get,
  payload,
});

export const getChainCase = (payload: number) => ({
  type: actions.cases.chain.get,
  payload,
});
export const resetCurrentCase = () => ({
  type: actions.cases.view.reset,
});
