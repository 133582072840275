import React from "react";
import ReactPDF, { View, Text } from "@react-pdf/renderer";
import styles from "./pdf.styles";

interface IProps {
  style?: ReactPDF.Style;
  name?: string;
  dob?: string;
}

function PDFFooter({ style, name, dob }: IProps) {
  const showInfo = name!! || dob!!;
  return (
    <View
      fixed
      wrap={false}
      style={[styles.footer, style || {}]}
      render={({ pageNumber }) => {
        return (
          <>
            <View style={styles.snippet}>
              <Text style={styles.subtitle}>Direct questions to:</Text>
              <Text style={[styles.text, { marginLeft: 5 }]}>
                hello@carehalo.com
              </Text>
            </View>
            {showInfo && (
              <View>
                {name!! && <Text style={styles.infoText}>{name}</Text>}
                {dob!! && <Text style={styles.infoText}>{dob}</Text>}
              </View>
            )}
            <Text style={styles.paginationText}>{String(pageNumber)}</Text>
          </>
        );
      }}
    />
  );
}

export default PDFFooter;
