import { put } from "redux-saga/effects";
import { createSelector } from "reselect";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";
//@ts-ignore
import { svgAsPngUri } from "save-svg-as-png";

import { errorNotify } from "../../components/Toaster/toster";
import { recognizeErrorMessage } from "../../utils/errors";
import actions from "../../constants/actionsTypes";
import { API_PATIENT_REPORT } from "../../constants/urls";

import { AppState } from "../reducers";
import { IPatientReports, IMeasurement } from "../reducers/patientReport";
import {
  setPatientReportChartsStart,
  setPatientReportChartsSuccess,
  getPatientReportDataStart,
  getPatientReportDataFailure,
  getPatientReportDataSuccess,
} from "../actions/patientReport";
import { parseFromString } from "./utils";
import { IPatientReportPayload } from "../actions/patientReport";
import sortByTimeCallback from "../../utils/sortByTime";

export const patientReportSelector = createSelector(
  ({ patientReport }: AppState): IPatientReports => patientReport,
  (patientReport) => patientReport
);

export function* setPatientReportChartSaga({
  payload,
}: ReturnType<typeof setPatientReportChartsStart>) {
  try {
    const charts: IPatientReportPayload = {};
    if (payload.charts.bloodPressure) {
      const bloodPressureSvgElem = parseFromString(
        payload.charts.bloodPressure || ""
      );
      const bloodPressureUri = yield svgAsPngUri(bloodPressureSvgElem);
      charts.bloodPressure = bloodPressureUri;
    }
    if (payload.charts.pulse) {
      const pulseSvgElem = parseFromString(payload.charts.pulse);
      const pulseUri = yield svgAsPngUri(pulseSvgElem);
      charts.pulse = pulseUri;
    }
    if (payload.charts.glucose) {
      const glucoseSvgElem = parseFromString(payload.charts.glucose);
      const glucoseUri = yield svgAsPngUri(glucoseSvgElem);
      charts.glucose = glucoseUri;
    }
    if (payload.charts.bodyScale) {
      const bodyScaleSvgElem = parseFromString(payload.charts.bodyScale);
      const scaleUri = yield svgAsPngUri(bodyScaleSvgElem);
      charts.bodyScale = scaleUri;
    }
    if (payload.charts.thermometer) {
      const thermometerSvgElem = parseFromString(payload.charts.thermometer);
      const thermometerUri = yield svgAsPngUri(thermometerSvgElem);
      charts.thermometer = thermometerUri;
    }
    if (payload.charts.pulseOximeter) {
      const oximeterSvgElem = parseFromString(payload.charts.pulseOximeter);
      const oximeterUri = yield svgAsPngUri(oximeterSvgElem);
      charts.pulseOximeter = oximeterUri;
    }
    yield put(setPatientReportChartsSuccess(charts));
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put({
      type: actions.patientReport.charts.fail,
      payload: { errors: errorMsg },
    });
    errorNotify(errorMsg);
  }
}

export function* getPatientReportDataStartSaga({
  payload: { startDate, endDate, ...rest },
}: ReturnType<typeof getPatientReportDataStart>) {
  const formattedCreateDateGte = startDate?.setUTCHours(0, 0, 0, 0);
  const formattedCreateDateLte = endDate?.setUTCHours(23, 59, 59, 0);
  const params = snakeCaseKeys({
    ...rest,
    createDateGte: formattedCreateDateGte && new Date(formattedCreateDateGte),
    createDateLte: formattedCreateDateLte && new Date(formattedCreateDateLte),
  });
  try {
    const { data } = yield axios.get(API_PATIENT_REPORT, {
      params,
    });

    const list = camelCaseKeys<IMeasurement[]>(data)
      .map((item) => ({
        ...item,
        measurementResult: Number(item.measurementResult),
      }))
      .sort((a, b) => sortByTimeCallback(a.createdDate, b.createdDate));

    yield put(
      getPatientReportDataSuccess({
        list,
        ...rest,
        endDate,
        startDate,
        showNoDataModal: !camelCaseKeys(data).length,
      })
    );
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put(getPatientReportDataFailure(errorMsg));
    errorNotify(errorMsg);
  }
}
