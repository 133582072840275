import actions from "../../constants/actionsTypes";

export interface LoginAction {
  username: string;
  password: string;
}

export const login = (payload: LoginAction) => ({
  type: actions.login.signin.start,
  payload,
});

export const logout = () => ({
  type: actions.logout.start,
});

export const logoutTimeOut = () => ({
  type: actions.logoutTimeOut.start_time_out,
});
