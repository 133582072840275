import { createSelector } from "reselect";
import { call, put } from "redux-saga/effects";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";

import { AppState } from "../reducers";
import {
  addTaskTimerFail,
  addTaskTimerStart,
  addTaskTimerSuccess,
  updateTaskTimerFail,
  updateTaskTimerStart,
  updateTaskTimerSuccess,
  deleteTaskTimerFail,
  deleteTaskTimerStart,
  deleteTaskTimerSuccess,
} from "../actions/taskTimer";
import { errorNotify, successNotify } from "../../components/Toaster/toster";
import { API_TIMER_TASK } from "../../constants/urls";
import { recognizeErrorMessage } from "../../utils/errors";
import { addNotesSaga, updateNotesSaga } from "./notes";
import { addNotesStart, updateNotesStart } from "../actions/notes";
import { INote } from "../reducers/notes";

export const taskTimerSelector = createSelector(
  ({ taskTimer }: AppState) => taskTimer,
  (taskTimer) => taskTimer
);

export function* addTaskTimerSaga({
  payload,
}: ReturnType<typeof addTaskTimerStart>) {
  try {
    if (!payload.patient) {
      return;
    }
    const note: INote = yield addNotesSaga(
      addNotesStart({
        onSuccess: () => {},
        firstName: "",
        lastName: "",
        patient: payload.patient,
        message: payload.note || "",
        title: "Task Timer",
      })
    );
    if (!note?.id) {
      throw new Error();
    }
    const { data, status } = yield axios.post(
      API_TIMER_TASK,
      snakeCaseKeys({ ...payload, note: note.id }),
      {
        validateStatus: (status) => {
          return (
            Number(status) === 200 ||
            Number(status) === 201 ||
            Number(status) === 400
          );
        },
      }
    );
    if (status === 400) {
      yield put(addTaskTimerFail(camelCaseKeys(data)));
      throw new Error("Invalid data, please check your form");
    }
    const transformedData = camelCaseKeys(data, { deep: true });
    yield put(addTaskTimerSuccess(transformedData));
    yield call(successNotify, "Time has been added!");
    yield call(payload.onSuccess);
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield call(errorNotify, errorMsg);
  }
}

export function* updateTaskTimerSaga({
  payload,
}: ReturnType<typeof updateTaskTimerStart>) {
  try {
    yield updateNotesSaga(
      updateNotesStart({
        ...payload.note,
        onSuccess: () => {},
      })
    );
    const {status, data}= yield axios.patch(
      `${API_TIMER_TASK}${payload.id}/`,
      snakeCaseKeys({ ...payload, note: payload.note?.id }),
      {
        validateStatus: (status) => {
          return (
            Number(status) === 200 ||
            Number(status) === 201 ||
            Number(status) === 400
          );
        },
      }
    );
    if (status === 400) {
      yield put(updateTaskTimerFail(camelCaseKeys(data)));
      throw new Error("Invalid data, please check your form");
    }
    yield put(updateTaskTimerSuccess());
    yield call(successNotify, "Time has been updated!");
    yield call(payload.onSuccess);
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield call(errorNotify, errorMsg);
  }
}

export function* deleteTaskTimerSaga({
  payload,
}: ReturnType<typeof deleteTaskTimerStart>) {
  try {
    yield axios.delete(`${API_TIMER_TASK}${payload.id}/`);
    yield put(deleteTaskTimerSuccess());
    yield call(payload.onSuccess);
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put(deleteTaskTimerFail(error));
    yield call(errorNotify, errorMsg);
  }
}
