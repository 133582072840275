import React, { useState, useCallback } from "react";

import { useAuth } from "../../hooks";

export function useModal() {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal, setShowModal]);
  return { showModal, setShowModal, toggleModal };
}

export function useIdleHandler(
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
) {
  const { token } = useAuth();
  const isAuth = token?.length;

  return useCallback(() => {
    if (isAuth) {
      setShowModal(true);
    }
  }, [isAuth, setShowModal]);
}
