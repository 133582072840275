import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { clientsSelector } from "../store/sagas/clients";
import { getClientsStart } from "../store/actions/clients";

export default function useClients() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClientsStart());
  }, [dispatch]);
  return useSelector(clientsSelector);
}
