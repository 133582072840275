import { Action } from "./index";
import actions from "../../constants/actionsTypes";

export interface SearchPanelStorageState {
  name: string | undefined;
  city: string | undefined;
  state: string | undefined;
  company: string | undefined;
  untrained: boolean | undefined | "none";
}

export const initialSearchPanelState: SearchPanelStorageState = {
  name: "",
  city: undefined,
  state: undefined,
  company: undefined,
  untrained: undefined,
};

export default function events(
  state: SearchPanelStorageState = initialSearchPanelState,
  action: Action
) {
  switch (action.type) {
    case actions.searchPanelFilters.get:
      return { ...state };
    case actions.searchPanelFilters.update:
      return { ...state, ...action.payload };
    case actions.searchPanelFilters.reset:
      return { ...state, state: initialSearchPanelState };
    default:
      return state;
  }
}
