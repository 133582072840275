import React, { SyntheticEvent } from "react";

import leftArrow from "../../assets/images/ic-left-arrow.svg";

interface IProps {
  children: string | false | React.ReactNode;
  onArrowClick: (e: SyntheticEvent) => void;
}

function HeaderBlock({ children, onArrowClick }: IProps) {
  return (
    <div className="uppercase flex items-center">
      <img
        src={leftArrow}
        alt="arrow"
        onClick={onArrowClick}
        className="cursor-pointer"
      />
      <div className="ml-2 md:ml-4">{children}</div>
    </div>
  );
}
HeaderBlock.defaultProps = {
  title: "Add new Patient",
  onArrowClick: () => {},
};
export default HeaderBlock;
