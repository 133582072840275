import React from "react";

import "./styles.scss";
import NavigationItem from "./NavigationItem";
import tableGrid from "../../assets/images/ic-table-grid.svg";
import musicaSearcher from "../../assets/images/ic-musica-searcher.svg";
import { LOCAL_CHATS, LOCAL_SEARCH, LOCAL_SUMMARY_REPORT } from "../../constants/urls";

interface IProps {
  onClick: () => void;
}

export default function PCPItemsComponent({ onClick }: IProps) {
  return (
    <>
      <NavigationItem
        image={musicaSearcher}
        text="Patients"
        to={LOCAL_SEARCH}
        onClick={onClick}
      />
      <NavigationItem
        image={tableGrid}
        text="Summary Report"
        to={LOCAL_SUMMARY_REPORT}
        onClick={onClick}
      />
        <NavigationItem
        image={tableGrid}
        text="Chats"
        to={LOCAL_CHATS}
        onClick={onClick}
      />
    </>
  );
}
