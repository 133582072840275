import actions from "../../constants/actionsTypes";
import { IDevices } from "../reducers/devices";

interface IDeviceParams {
  deviceType: string;
  emptyUser?: boolean;
}

export const getDevicesStart = (payload: IDeviceParams) => ({
  type: actions.devices.start,
  payload,
});

export const getDevicesSuccess = (payload: IDevices) => ({
  type: actions.devices.success,
  payload,
});

export const getDevicesFailure = (error: any) => ({
  type: actions.devices.fail,
  payload: error,
});

export const resetDevices = () => ({
  type: actions.devices.reset,
});
