import * as yup from "yup";

import { FIELD_NAMES } from "../../constants/taskTimer";

function validateDateEndTime(this: yup.TestContext, value: number) {
  if (this.parent[FIELD_NAMES.START_TIME] && value) {
    return !(this.parent[FIELD_NAMES.START_TIME] > value);
  }
  return true;
}

function validateDateStartTime(this: yup.TestContext, value: number) {
  if (this.parent[FIELD_NAMES.END_TIME] && value) {
    return !(this.parent[FIELD_NAMES.END_TIME] < value);
  }
  return true;
}

export default function validationSchema() {
  return yup.object().shape({
    [FIELD_NAMES.START_TIME]: yup
      .date()
      .nullable()
      .required("Field is required")
      .test(
        "match",
        "Start time can't be less, than end time",
        validateDateStartTime
      ),
    [FIELD_NAMES.END_TIME]: yup
      .date()
      .required("Field is required")
      .test(
        "match",
        "End time can't be less, than start time",
        validateDateEndTime
      ),
    [FIELD_NAMES.DURATION]: yup
      .number()
      .min(1, "Duration ca't be empty")
      .required("Field is required"),
    [FIELD_NAMES.NOTE]: yup.string().nullable().required("Field is required"),
    [FIELD_NAMES.TASK_TYPE]: yup
      .string()
      .nullable()
      .required("Field is required"),
  });
}
