import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { LOCAL_LOGIN } from "../../constants/urls";

interface Props extends RouteProps {
  isAuth: boolean;
  component: any;
}

const AuthRoute = ({ component: Component, isAuth, ...rest }: Props) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth === true ? <Component {...props} /> : <Redirect to={LOCAL_LOGIN} />
    }
  />
);

export default AuthRoute;
