import { Action } from "./index";
import actions from "../../constants/actionsTypes";

export interface SettingsStorageState {
  header: boolean;
  navigation: boolean;
  printHeader: boolean;
  printName: string;
}

const initialState: SettingsStorageState = {
  header: true,
  navigation: true,
  printHeader: false,
  printName: "",
};

export default function events(
  state: SettingsStorageState = initialState,
  action: Action
) {
  switch (action.type) {
    case actions.settings.setup:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
