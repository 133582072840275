import actions from "../../constants/actionsTypes";
import { ISummaryReport } from "../reducers/summaryReport";
import { ValuesType } from "../../components/SumaryReport/SummaryReport.types";

export const getSummaryReportStart = (
  deviceType: ValuesType,
  format?: string | null
) => ({
  type: actions.summaryReport.get.start,
  payload: { format, ...deviceType },
});

export const getSummaryReportSuccess = (payload: ISummaryReport) => ({
  type: actions.summaryReport.get.success,
  payload,
});

export const getSummaryReportFailure = (error: any) => ({
  type: actions.summaryReport.get.fail,
  payload: error,
});

export const resetSummaryReportModal = () => ({
  type: actions.summaryReport.resetModal,
});

export const sortSummaryReportStart = (payload: ISummaryReport['sortBy']) => ({
  type: actions.summaryReport.sort.start,
  payload,
});

export const sortSummaryReportSuccess = (payload: ISummaryReport) => ({
  type: actions.summaryReport.sort.success,
  payload,
});

export const resetSummaryReport = () => ({
  type: actions.summaryReport.reset,
});