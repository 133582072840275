import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";

export interface IPrimaryCarePhysician {
  error?: null | { [key: string]: string[] };
  loading?: boolean;
  isSuccess?: boolean;
}

export interface IPhysicians {
  error?: any;
  loading?: boolean;
}

const initialState: IPrimaryCarePhysician = {
  error: null,
  loading: false,
};

export default function Physicians(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.primaryCarePhysician.add.start:
      return {
        ...state,
        loading: true,
        isSuccess: false,
      };
    case actionTypes.primaryCarePhysician.add.success:
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    case actionTypes.primaryCarePhysician.add.fail:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isSuccess: false,
      };
    case actionTypes.primaryCarePhysician.reset:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
