import React, { ReactNode } from "react";

import "./styles.scss";

interface Props {
  onSubmit: () => void;
  children: ReactNode;
  className?: string;
}

export const Form = ({ onSubmit, children, className }: Props) => {
  const submit = (e: React.FormEvent): void => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <form className={className ? className : "form"} onSubmit={submit}>
      {children}
    </form>
  );
};
