import { OptionProps } from "../models/basicModels";
import { WaitingPeriod, requiredSoapNotes } from "../constants/soapNotes";
import { SoapNotesType, SoapNotesValuesType } from "../models/soapNotes";

export function getWaitingPeriodDS(): OptionProps[] {
  return Object.values(WaitingPeriod).map((period) => ({
    name: period,
    value: period,
  }));
}

export const checkForRequiredSoapNotes = (
  existNotes: SoapNotesValuesType
): boolean => {
  for (let selected of requiredSoapNotes) {
    if (!existNotes[selected]) {
      return false;
    }
  }
  return true;
};

export const getSoapNoteTitle = (soapNotesType: SoapNotesType): string => {
  switch (soapNotesType) {
    case SoapNotesType.chiefComplaint:
      return "Chief Complaint";
    case SoapNotesType.location:
      return "Location";
    case SoapNotesType.quality:
      return "Quality";
    case SoapNotesType.severity:
      return "Severity";
    case SoapNotesType.duration:
      return "Duration";
    case SoapNotesType.timing:
      return "Timing";
    case SoapNotesType.context:
      return "Context";
    case SoapNotesType.modifyingFactors:
      return "Modifying factors";
    case SoapNotesType.medicalHistory:
      return "Medical history";
    case SoapNotesType.medications:
      return "Medications";
    case SoapNotesType.lastFoodFluids:
      return "Last Food & Fluids";
    case SoapNotesType.exerciseActivity:
      return "Exercise/Activity";
    case SoapNotesType.clinicalExam:
      return "Clinical exam:";
    case SoapNotesType.assessment:
      return "Assessment";
    case SoapNotesType.plan:
      return "Plan";
    case SoapNotesType.waitingPeriod:
      return "Waiting period";
    default:
      return "";
  }
};
