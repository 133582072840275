import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { logoutTimeOut } from "../../store/actions/auth";

export function useLogoutTimeoutHandler() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(logoutTimeOut());
  }, [dispatch]);
}

export function useTimer() {
  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const [currentCount, setCurrentCount] = useState(60);
  const logoutHandler = useLogoutTimeoutHandler();
  useEffect(() => {
    if (currentCount <= 0) {
      logoutHandler();
      setSessionTimeOut(true);
    }
    const countDown = () => {
      if (currentCount >= 1) {
        setCurrentCount(currentCount - 1);
      }
    };
    const id = setInterval(countDown, 1000);
    return () => clearInterval(id);
  }, [currentCount, logoutHandler]);
  return { sessionTimeOut, currentCount };
}

export function useLogoutHandler(closeModal: () => void) {
  const logoutHandler = useLogoutTimeoutHandler();
  return useCallback(() => {
    logoutHandler();
    closeModal();
  }, [closeModal, logoutHandler]);
}
