import actions from "../../constants/actionsTypes";
import { IDevices, IDevice } from "../reducers/devices";
export const getPatientDevicesStart = () => ({
  type: actions.patientDevices.get.start,
});

export const getPatientDevicesSuccess = (payload: IDevices) => ({
  type: actions.patientDevices.get.success,
  payload,
});

export const getPatientDevicesFailure = (error: any) => ({
  type: actions.patientDevices.get.fail,
  payload: error,
});

export const addPatientDevicesStart = (payload: any) => ({
  type: actions.patientDevices.add.start,
  payload,
});

export const addPatientDevicesSuccess = (payload: { list: IDevice[] }) => ({
  type: actions.patientDevices.add.success,
  payload,
});

export const addPatientDevicesFailure = (error: any) => ({
  type: actions.patientDevices.add.fail,
  payload: error,
});

export const resetPatientDevicesStart = () => ({
  type: actions.patientDevices.reset,
});
