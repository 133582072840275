import { StyleSheet } from "@react-pdf/renderer";
import calculatePts from "../../utils/calculatePts";

export default StyleSheet.create({
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: calculatePts(24),
    lineHeight: 2,
    opacity: 0.8,
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: calculatePts(24),
    lineHeight: 2,
    fontWeight: "semibold",
  },
  paginationText: {
    fontFamily: "Open Sans",
    fontSize: calculatePts(24),
    lineHeight: 2,
    opacity: 0.8,
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingVertical: calculatePts(41),
    marginTop: calculatePts(10),
    borderTop: calculatePts(1),
    borderColor: "#E5E5E5",
    justifyContent: "space-between",
  },
  snippet: {
    display: "flex",
    flexDirection: "row",
  },
  infoText: {
    fontFamily: "Open Sans",
    fontSize: calculatePts(18),
    lineHeight: 1.5,
    opacity: 0.8,
  }
});
