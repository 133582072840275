import React from "react";
import { useField } from "formik";

import { SelectField, Props as SelectFieldProps } from "../SelectField";

interface ISelectField extends SelectFieldProps {
  name: string;
  label?: string;
  className?: string;
}

function FormikSelectField({ name, ...rest }: ISelectField) {
  const [field, meta, { setValue, setTouched }] = useField(name);
  const error = (!!meta.touched && meta.error) || "";
  return (
    <SelectField
      {...field}
      {...rest}
      error={error}
      //@ts-ignore
      onChange={setValue}
      onBlur={setTouched}
    />
  );
}

export default FormikSelectField;
