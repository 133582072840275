import React from "react";
import ReactPDF, { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  col: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "white",
  },
});

interface IProps {
  children: React.ReactNode | React.ReactNodeArray;
  style?: ReactPDF.Style;
}
function PDFCol({ children, style }: IProps) {
  return <View style={[styles.col, style || {}]}>{children}</View>;
}

export default PDFCol;
