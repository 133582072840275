import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: "white",
  },
});

interface IProps {
  children: React.ReactNode | React.ReactNodeArray;
}
function Table({ children }: IProps) {
  return <View style={styles.table}>{children}</View>;
}

export default Table;
