import React from "react";
import ReactDOM from "react-dom";

import "./Loader.styles.scss";
import spinnerIcon from "../../assets/images/ic-loader-large.svg";
import { useLoader } from "./Loader.hooks";

export function Loader() {
  const el = useLoader();
  return ReactDOM.createPortal(
    <div className="loader__block">
      <img src={spinnerIcon} className="loader__element" alt="loader" />
    </div>,
    el
  );
}

export default Loader;
