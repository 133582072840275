import actions from "../../constants/actionsTypes";
import { ITimer, ITimerData } from "../reducers/taskTimer";

interface IData extends Partial<Omit<ITimerData, "note">> {
  note?: string | null;
  onSuccess: () => void;
}
interface IUpdData extends ITimerData {
  onSuccess: () => void;
}

interface IPayload extends Partial<Omit<ITimer, "data">> {
  data?: Partial<ITimer["data"]>;
}

export const taskTimerStart = (payload: IPayload) => ({
  type: actions.taskTimer.timer.start,
  payload,
});

export const taskTimerStop = (payload: IPayload) => ({
  type: actions.taskTimer.timer.stop,
  payload,
});

export const taskTimerPause = (payload: IPayload) => ({
  type: actions.taskTimer.timer.pause,
  payload,
});

export const addTaskTimerStart = (payload: IData) => ({
  type: actions.taskTimer.add.start,
  payload,
});

export const addTaskTimerSuccess = (payload: IPayload) => ({
  type: actions.taskTimer.add.success,
  payload,
});

export const addTaskTimerFail = (payload: any) => ({
  type: actions.taskTimer.add.fail,
  payload,
});

export const updateTaskTimerStart = (payload: IUpdData) => ({
  type: actions.taskTimer.update.start,
  payload,
});

export const updateTaskTimerSuccess = () => ({
  type: actions.taskTimer.update.success,
});

export const updateTaskTimerFail = (payload: any) => ({
  type: actions.taskTimer.update.fail,
  payload,
});

export const resetTaskTimer = () => ({
  type: actions.taskTimer.reset,
});

export const showTaskTimer = () => ({
  type: actions.taskTimer.panel.show,
});

export const hideTaskTimer = () => ({
  type: actions.taskTimer.panel.hide,
});

export const showTaskTimerForm = (payload: { isEditing: boolean }) => ({
  type: actions.taskTimer.form.show,
  payload,
});

export const hideTaskTimerForm = () => ({
  type: actions.taskTimer.form.hide,
});

export const getTaskTimerStart = (payload: string) => ({
  type: actions.taskTimer.get.start,
  payload,
});

export const getTaskTimerSuccess = (payload: IPayload) => ({
  type: actions.taskTimer.get.success,
  payload,
});

export const getTaskTimerFail = (payload: any) => ({
  type: actions.taskTimer.get.fail,
  payload,
});

export const deleteTaskTimerStart = (payload: {
  id: number | string;
  onSuccess: () => void;
}) => ({
  type: actions.taskTimer.delete.start,
  payload,
});

export const deleteTaskTimerSuccess = () => ({
  type: actions.taskTimer.delete.success,
});

export const deleteTaskTimerFail = (payload: any) => ({
  type: actions.taskTimer.delete.fail,
  payload,
});

export const setTaskTimerForm = (payload: Partial<ITimer>) => ({
  type: actions.taskTimer.form.set,
  payload,
});
export const resetTaskTimerData = () => ({
  type: actions.taskTimer.timer.resetData,
});

export const resetStartedTaskTimer = () => ({
  type: actions.taskTimer.resetStarted,
});