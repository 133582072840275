import React from "react";

import "./styles.scss";
import Input, { InputProps } from "../Input";

export interface Props extends InputProps {
  label?: string;
  inputValue: string;
  value: string;
  name?: string;
}

export const RadioField = ({ inputValue, ...rest }: Props) => {
  const {
    id,
    label,
    className,
    value,
    meta: { error = "" } = {},
    tabIndex,
  } = rest;

  const classNameArray = ["radio__wrapper"];

  className && className.length && classNameArray.push(className);

  error.length > 0 && classNameArray.push("radio__wrapper__error");

  const newProps = {
    ...rest,
    type: "radio",
    value,
    checked: inputValue === value,
    tabIndex: undefined,
  };

  return (
    <div className={classNameArray.join(" ")}>
      <label htmlFor={id} tabIndex={tabIndex} className="radio__label">
        <Input {...newProps} className="radio__input" />
        <span className="radio__mark" />
        {label}
      </label>
    </div>
  );
};
