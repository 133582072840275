import React, { memo, useRef } from "react";
import { Formik } from "formik";

import Modal from "../Modal";

import TaskTimerForm from "./TaskTimerForm.component";
import validationSchema from "./TaskTimerForm.schema";
import {
  useSubmitHandler,
  useInitialValues,
  useCloseModal,
  useErrors,
  useCloseResetHandler
} from "./TaskTimerForm.hooks";

import { useTaskTimer } from "../../hooks";

function TaskTimerPanelContainer() {
  const { showForm, isEditing, data, loading } = useTaskTimer();
  const formikRef =  useRef()
  const closeModal = useCloseModal();
  const onSubmit = useSubmitHandler();
  const initialValues = useInitialValues(isEditing);
  const title = isEditing
    ? "Edit tracking period"
    : "Please, submit tracking period";
  const errors = useErrors(formikRef);
const onCloseReset = useCloseResetHandler()
  return (
    <div className="relative">
      <div className="flex absolute">
        <Modal
          isDisabled={!showForm}
          onClose={closeModal}
          title={title}
          usePortal
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema()}
            onSubmit={onSubmit}
            enableReinitialize
            //@ts-ignore
            initialErrors={errors}
          >
            {({ ...rest }) => (
              <TaskTimerForm
                {...rest}
                onCancel={closeModal}
                isEditing={isEditing}
                profile={data.profile}
                loading={loading}
                onCloseReset={onCloseReset}
              />
            )}
          </Formik>
        </Modal>
      </div>
    </div>
  );
}
export default memo(TaskTimerPanelContainer);
