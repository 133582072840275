import React from "react";
// Components
import { ScreenWrapper } from "../../ScreenWrapper";
// Icons
import { Case } from "../../../models/basicModels";
import { SoapNotesType } from "../../../models/soapNotes";
import SoapNotesColumn from "./SoapNotesColumn";

const column1: SoapNotesType[] = [SoapNotesType.chiefComplaint];
const column2: SoapNotesType[] = [
  SoapNotesType.location,
  SoapNotesType.severity,
  SoapNotesType.timing,
  SoapNotesType.context,
  SoapNotesType.modifyingFactors,
  SoapNotesType.medicalHistory,
  SoapNotesType.medications,
];
const column3: SoapNotesType[] = [
  SoapNotesType.quality,
  SoapNotesType.duration,
];

interface Props {
  case?: Case;
}

class SoapNotes extends React.Component<Props> {
  render(): React.ReactNode {
    if (
      !this.props.case ||
      Object.keys(this.props.case.soapNotes).length === 0
    ) {
      return null;
    }
    return (
      <>
        <p className="soap-notes__label">Soap notes</p>
        <ScreenWrapper leftBorderColor="#b6e0f8" className="soap-notes-wrapper">
          <div className="screen-wrapper__section">
            <SoapNotesColumn case={this.props.case} notes={column1} />
          </div>
          <div className="screen-wrapper__section">
            <SoapNotesColumn case={this.props.case} notes={column2} />
            <SoapNotesColumn case={this.props.case} notes={column3} />
          </div>
        </ScreenWrapper>
      </>
    );
  }
}

export default SoapNotes;
