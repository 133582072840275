import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  taskTimerStop,
  showTaskTimerForm,
} from "../store/actions/taskTimer";
import { resetTaskTimerData } from "../store/actions/taskTimer";
import TIMER_STATES from "../constants/timerStates";
import { useTaskTimer } from "../hooks";

function useUpdateDuration() {
  const { startTimeHistory, duration } = useTaskTimer();

  return useCallback(
    (data: Date) => {
      const lastStartTime = [...startTimeHistory].pop()?.getTime();
      const memoized = lastStartTime
        ? data.getTime() - lastStartTime + Number(duration)
        : duration;
      return memoized;
    },
    [duration, startTimeHistory]
  );
}

function useFinishTimerHandler() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(resetTaskTimerData());
    dispatch(showTaskTimerForm({ isEditing: false }));
  }, [dispatch]);
}

export default function useStopTimerHandler() {
  const dispatch = useDispatch();
  const { state, duration } = useTaskTimer();
  const onFinish = useFinishTimerHandler();

  const getDuration = useUpdateDuration();
  return useCallback(() => {
    if (TIMER_STATES.STOPPED !== state && state !== TIMER_STATES.INITIAL) {
      dispatch(
        taskTimerStop({
          endTime: new Date().toISOString(),
          duration:
            TIMER_STATES.PAUSED === state ? duration : getDuration(new Date()),
          state: TIMER_STATES.STOPPED,
        })
      );
    }
    onFinish();
  }, [dispatch, getDuration, state, duration, onFinish]);
}
