import React from "react";
import NavigationPrompt, { ChildData } from "react-router-navigation-prompt";
import * as H from "history";

interface IProps {
  children: (props: ChildData) => React.ReactNode | React.ReactNodeArray;
  when: (currentLocation: H.Location, nextLocation?: H.Location) => boolean;
}

export interface NavigationModalProps extends ChildData {
  title?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  message?: string;
  className?: string;
}

export default function NavigationPropmpt({ when, children }: IProps) {
  return <NavigationPrompt when={when}>{children}</NavigationPrompt>;
}
