export enum WaitingPeriod {
  h8 = "8h",
  h16 = "16h",
  h24 = "24h",
  h32 = "32h",
  h48 = "48h",
}

export enum ClinicalExam {
  Extraoral = "Extraoral",
  Intraoral = "Intraoral",
}

export enum SoapNotesType {
  chiefComplaint = "chiefComplaint",
  location = "location",
  severity = "severity",
  quality = "quality",
  duration = "duration",
  timing = "timing",
  context = "context",
  modifyingFactors = "modifyingFactors",
  medicalHistory = "medicalHistory",
  medications = "medications",
  lastFoodFluids = "lastFoodFluids",
  exerciseActivity = "exerciseActivity",
  clinicalExam = "clinicalExam",
  assessment = "assessment",
  plan = "plan",
  waitingPeriod = "waitingPeriod",
}

export const requiredSoapNotes: SoapNotesType[] = [
  SoapNotesType.chiefComplaint,
  SoapNotesType.location,
  SoapNotesType.severity,
  SoapNotesType.quality,
  SoapNotesType.duration,
  SoapNotesType.timing,
  SoapNotesType.context,
  SoapNotesType.modifyingFactors,
  SoapNotesType.medicalHistory,
  SoapNotesType.medications,
  SoapNotesType.assessment,
  SoapNotesType.plan,
];
