import axios from "axios";
import { put } from "redux-saga/effects";
import { createSelector } from "reselect";
import camelCaseKeys from "camelcase-keys";

import { errorNotify } from "../../components/Toaster/toster";
import { recognizeErrorMessage } from "../../utils/errors";
import { API_CLIENTS } from "../../constants/urls";

import { AppState } from "../reducers";
import { IClients } from "../reducers/clients";
import { getClientsSuccess, getClientsFailure } from "../actions/clients";

export const clientsSelector = createSelector(
  ({ clients }: AppState): IClients => clients,
  ({ list }) => list
);

export function* getClientsSaga() {
  try {
    const {
      data,
    } = yield axios.get(API_CLIENTS);
    yield put(
      getClientsSuccess({ list: camelCaseKeys(data, { deep: true }) })
    );
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put(getClientsFailure(errorMsg));
    errorNotify(errorMsg[0]);
  }
}
