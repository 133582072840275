import axios from "axios";
import { Dispatch } from "redux";

import { logout } from "../store/actions/auth";
import { BASE_URL } from "../constants/urls";

export const setDefaultAxios = (dispatch: Dispatch): void => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.Accept = "application/json";
  axios.defaults.headers["Content-Type"] = "application/json";

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      return Promise.reject(error);
    }
  );
};

export const setAuthorizationHeader = (token: string | null): void => {
  token !== null &&
    token.length > 0 &&
    (axios.defaults.headers.Authorization = `Token ${token}`);
};

export const removeAuthorizationHeader = (): void => {
  delete axios.defaults.headers.Authorization;
};
