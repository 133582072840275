import React from "react";
// Components
import { Case } from "../../../models/basicModels";
import { SoapNotesType } from "../../../models/soapNotes";
import { getSoapNoteTitle } from "../../../utils/soapNotes";

interface Props {
  case?: Case;
  notes: SoapNotesType[];
}

class SoapNotesColumn extends React.Component<Props> {
  render(): React.ReactNode {
    return (
      <div className="screen-wrapper-column screen-wrapper-column--nospacing">
        {this.props.notes.map((value: SoapNotesType, index: number) => {
          return (
            <React.Fragment key={`value-${index}`}>
              <p className="screen-wrapper-column__title">
                {getSoapNoteTitle(value)}
              </p>
              {this.props.case && (
                <p className="screen-wrapper-column__value">
                  {this.props.case.soapNotes[value]}
                </p>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default SoapNotesColumn;
