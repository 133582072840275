import axios from "axios";
import { put, call } from "redux-saga/effects";
import { createSelector } from "reselect";
import camelCaseKeys from "camelcase-keys";

import { errorNotify } from "../../components/Toaster/toster";
import { recognizeErrorMessage } from "../../utils/errors";
import actions from "../../constants/actionsTypes";
import { API_LOGIN, API_LOGOUT_TIME_OUT } from "../../constants/urls";
import {
  setAuthorizationHeader,
  removeAuthorizationHeader,
} from "../../utils/axios";
import { AppState } from "../reducers";
import { Action } from "../reducers";

const LOCALSTORAGE_TOKEN = "success_token";
const LOCALSTORAGE_USER = "user";

export const authSelector = createSelector(
  ({ auth }: AppState) => auth,
  (auth) => auth
);

export function* authLoginSaga(action: Action) {
  try {
    const {
      data,
      data: { token },
    } = yield axios.post(API_LOGIN, action.payload);
    yield call(setAuthorizationHeader, token);
    const transformedData = camelCaseKeys(data, { deep: true });
    yield put({
      type: actions.login.signin.success,
      payload: {
        ...transformedData,
      },
    });
    yield localStorage.setItem(LOCALSTORAGE_TOKEN, token);
    yield localStorage.setItem(
      LOCALSTORAGE_USER,
      JSON.stringify(transformedData)
    );
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put({
      type: actions.login.signin.fail,
      payload: { errors: errorMsg },
    });
    errorNotify(errorMsg[0]);
  }
}

export function* authCheckLocalStorageSaga() {
  const token = yield localStorage.getItem(LOCALSTORAGE_TOKEN);
  if (token !== null) {
    yield put({
      type: actions.login.signin.success,
      //@ts-ignorets
      payload: JSON.parse(localStorage.getItem(LOCALSTORAGE_USER)),
    });
  } else {
    yield put({ type: actions.logout.start });
  }
}

export function* clearLocalStorageSaga() {
  yield localStorage.removeItem(LOCALSTORAGE_TOKEN);
  yield localStorage.removeItem(LOCALSTORAGE_USER);
  yield call(removeAuthorizationHeader);
}

export function* logoutTimeOutSaga() {
  try {
    yield call(clearLocalStorageSaga);
    yield axios.get(API_LOGOUT_TIME_OUT);
  } catch (error) {
    console.log(error);
  }
}
