import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";

export interface ResetPasswordState {
  success: boolean;
  loading: boolean;
  error?: string | string[];
  isReseted?: boolean;
  isTokenVerified?: boolean | null;
  tokenExpired?: boolean;
}

const initialState: ResetPasswordState = {
  success: false,
  loading: false,
  error: undefined,
};

export default function resetPassword(
  state: ResetPasswordState = initialState,
  action: Action
) {
  switch (action.type) {
    case actionTypes.changePassword.resetPassword.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.changePassword.resetPassword.success:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case actionTypes.changePassword.resetPassword.fail:
      return {
        ...state,
        error: action.payload,
        success: false,
        loading: false,
      };
    case actionTypes.changePassword.resetPassword.reset_error:
      return {
        ...state,
        error: undefined,
        success: false,
        loading: false,
      };
    case actionTypes.changePassword.resetToken.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.changePassword.resetToken.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.changePassword.resetToken.fail:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionTypes.changePassword.resetForgottenPassword.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.changePassword.resetForgottenPassword.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.changePassword.resetForgottenPassword.fail:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
