export default function sortByTimeCallback(a: Date | string, b: Date | string) {
  if (typeof a === "string") {
    if (a && b) {
      if (new Date(a) < new Date(b)) {
        return -1;
      }
      if (new Date(a) > new Date(b)) {
        return 1;
      }
      return 0;
    } else {
      return 0;
    }
  }
  if (a && b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  } else {
    return 0;
  }
}
