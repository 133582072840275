import React, { SyntheticEvent } from "react";

import "./styles.scss";
import Input, { InputProps } from "../Input";

interface Props extends InputProps {
  type?: "default" | "primary";
  onClick?: (e?: SyntheticEvent) => void;
  buttonType?: "submit" | "button";
}

export const Button = ({
  className,
  buttonType,
  type = "default",
  ...rest
}: Props) => {
  const newProps = {
    ...rest,
    type: buttonType,
  };

  return (
    <Input {...newProps} className={`button button__${type} ${className}`} />
  );
};
Button.defaultProps = {
  buttonType: "submit",
};
export default Button;
