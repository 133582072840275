import { SUMMARY_REPORT_FORM_FIELDS } from "../../constants/summaryReportFormFields";

import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";
import { IReport } from "../../models/basicModels";
import { SUMMARY_SORT_FIELDS } from "../../constants/summarySortFields";
export type { IReport } from "../../models/basicModels";
export { default as SORT_FIELDS } from "../../constants/summarySortFields";

export interface ISummaryReport {
  list: IReport[];
  [SUMMARY_REPORT_FORM_FIELDS.FROM_DATE]?: Date | null;
  [SUMMARY_REPORT_FORM_FIELDS.TO_DATE]?: Date | null;
  [SUMMARY_REPORT_FORM_FIELDS.CLIENT]?: string;
  error?: any;
  loading?: boolean;
  showNoDataModal?: boolean;
  sortBy?: SUMMARY_SORT_FIELDS | "";
}

const initialState: ISummaryReport = {
  [SUMMARY_REPORT_FORM_FIELDS.FROM_DATE]: null,
  [SUMMARY_REPORT_FORM_FIELDS.TO_DATE]: null,
  [SUMMARY_REPORT_FORM_FIELDS.CLIENT]: "",
  list: [],
  error: null,
  loading: false,
  showNoDataModal: false,
  sortBy: "",
};

export default function SummaryReport(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.summaryReport.get.start:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case actionTypes.summaryReport.get.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.summaryReport.get.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.summaryReport.sort.success:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.summaryReport.resetModal:
      return {
        ...state,
        showNoDataModal: false,
      };
    case actionTypes.summaryReport.reset:
      return initialState;
    default:
      return state;
  }
}
