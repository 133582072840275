import React from "react";
import "./styles.scss";
import MeasurementsValue from "./Values";
import { Measurement, MeasurementSetValue } from "../../models/basicModels";

export type MeasurementValueDirection = "horizontal" | "vertical";

interface Props {
  measurements: Measurement[];
  hideDataPicker?: boolean;
  // todo: make onChange required. Used also in outreach cases
  onChange?: (measurementValue: MeasurementSetValue) => void;
  direction?: MeasurementValueDirection;
  onValidate?: () => void;
  validate: boolean;
  disabled: boolean;
}

interface State {
  values: any;
}

class MeasurementsValueList extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
    direction: "vertical",
    validate: false,
  };

  state = {
    values: {},
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.measurements.length !== prevProps.measurements.length) {
      const values = Object.fromEntries(
        Object.entries(this.state.values).filter(([k]) =>
          this.props.measurements.some((r) =>
            r.values.find((value1) => value1.name === k)
          )
        )
      );
      this.setState({
        values,
      });
    }
  }

  render(): React.ReactNode {
    const {
      measurements,
      hideDataPicker,
      direction,
      validate,
      disabled,
    } = this.props;

    const classNames: string = `measurement_values measurement_values__${direction}`;
    const itemClassNames: string =
      direction === "horizontal" ? "measurement_values__item__left-margin" : "";
    return (
      <div style={{ opacity: disabled ? 0.6 : 1 }} className={classNames}>
        {measurements.map((item: Measurement, index: number) => (
          <MeasurementsValue
            key={index}
            className={index > 0 ? itemClassNames : ""}
            measurement={item}
            hideDataPicker={hideDataPicker}
            validate={validate}
            values={this.state.values}
            onChange={this.isMeasurementValueSet}
            disabled={disabled}
          />
        ))}
      </div>
    );
  }

  isMeasurementValueSet = (measurementValue: MeasurementSetValue) => {
    this.setState(
      {
        values: {
          ...this.state.values,
          [measurementValue.key]: measurementValue.value,
        },
      },
      () => {
        this.props.onChange && this.props.onChange(measurementValue);
      }
    );
  };
}

export default MeasurementsValueList;
