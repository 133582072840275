import { put, select } from "redux-saga/effects";

import { createSelector } from "reselect";
import snakeCaseKeys from "snakecase-keys";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import { camelToSnake } from "../../utils/stringUtils";

import {
  getNotificationsStart,
  getNotificationsFailure,
  getNotificationsSuccess,
} from "../actions/notifications";
import { AppState } from "../reducers";
import { API_NOTIFICATIONS } from "../../constants/urls";
import { patientSelector } from "../sagas/patient";
import { PatientData } from "../reducers/patient";

export const notificationListSelector = createSelector(
  ({ notifications }: AppState) => notifications,
  ({ data }) => data
);
export const notificationSelector = createSelector(
  ({ notifications }: AppState) => notifications,
  (notifications) => notifications
);

export function* getNotificationsSaga({
  payload,
}: ReturnType<typeof getNotificationsStart>) {
  try {
    const patient: PatientData = yield select(patientSelector);
    if (!patient?.uuid) {
      throw new Error("There is no patient!");
    }
    const params = snakeCaseKeys({
      ...payload,
      patient: payload.patientId ? payload.patientId : patient.uuid,
      ordering: payload.ordering ? camelToSnake(payload.ordering) : "",
    });
    const { data } = yield axios.get(API_NOTIFICATIONS, { params });
    yield put(
      getNotificationsSuccess({
        data: camelCaseKeys(data.results),
        count: data.count,
      })
    );
  } catch (error) {
    yield put(getNotificationsFailure(error));
  }
}
