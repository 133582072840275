import { Action } from "./index";
import actions from "../../constants/actionsTypes";
import { FollowUpCase, HoldingCase } from "../../components/Cases/models";
import { HistoryCase, UnderReviewCase } from "../../components/DocCases/models";
import { Case, Chain } from "../../models/basicModels";

interface ICase {
  data: Case[];
  count: number;
  loading: boolean;
}

interface FollowUp extends ICase {
  data: FollowUpCase[];
}

interface Holding extends ICase {
  data: HoldingCase[];
}

interface Review extends ICase {
  data: UnderReviewCase[];
}

interface History extends ICase {
  data: HistoryCase[];
}

interface Unsuccessful extends ICase {}

interface SingleCase {
  data?: Case;
  loading: boolean;
  error?: string;
}

interface CreateCase {
  result: string;
  caseID?: number;
}

export interface CaseChain {
  data?: Chain[];
}

export interface CasesStorageState {
  followUp: FollowUp;
  holding: Holding;
  review: Review;
  history: History;
  unsuccessful: Unsuccessful;
  view: SingleCase;
  sent: Boolean;
  create: CreateCase;
  chain: CaseChain;
  loading: Boolean;
  loadingTableReview: Boolean;
  loadingTableHistory: Boolean;
  loadingTableUnsuccessful: Boolean;
  loadingTableHolding: Boolean;
  loadingTableFollowUp: Boolean;
}

const initialState: CasesStorageState = {
  followUp: {
    data: [],
    count: 0,
    loading: true,
  },
  holding: {
    data: [],
    count: 0,
    loading: true,
  },
  review: {
    data: [],
    count: 0,
    loading: true,
  },
  history: {
    data: [],
    count: 0,
    loading: true,
  },
  unsuccessful: {
    data: [],
    count: 0,
    loading: true,
  },
  view: {
    data: undefined,
    loading: true,
    error: undefined,
  },
  create: {
    result: "fail",
  },
  sent: false,
  loading: false,
  loadingTableReview: false,
  loadingTableHistory: false,
  loadingTableUnsuccessful: false,
  loadingTableHolding: false,
  loadingTableFollowUp: false,
  chain: {
    data: [],
  },
};

export default function events(
  state: CasesStorageState = initialState,
  action: Action
) {
  switch (action.type) {
    case actions.cases.get.followup:
      return {
        ...state,
        loadingTableFollowUp: true,
      };
    case actions.cases.get.holding:
      return {
        ...state,
        loadingTableHolding: true,
      };
    case actions.cases.get.review:
      return {
        ...state,
        loadingTableReview: true,
      };
    case actions.cases.get.history:
      return {
        ...state,
        loadingTableHistory: true,
      };
    case actions.cases.get.unsuccessful:
      return {
        ...state,
        loadingTableUnsuccessful: true,
      };
    case actions.cases.get.untrained:
      return {
        ...state,
        loadingTableUntrained: true,
      };
    case actions.cases.view.get:
      return {
        ...state,
        view: initialState.view,
      };
    case actions.cases.view.success:
      return {
        ...state,
        view: {
          data: action.payload,
          loading: false,
        },
      };
    case actions.cases.view.fail:
      return {
        ...state,
        view: {
          data: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    case actions.cases.success.followup:
      return {
        ...state,
        followUp: {
          ...action.payload,
          loading: false,
        },
        loadingTableFollowUp: false,
      };
    case actions.cases.success.holding:
      return {
        ...state,
        holding: {
          ...action.payload,
          loading: false,
        },
        loadingTableHolding: false,
      };
    case actions.cases.success.review:
      return {
        ...state,
        review: {
          ...action.payload,
        },
        loadingTableReview: false,
      };
    case actions.cases.success.history:
      return {
        ...state,
        history: {
          ...action.payload,
          loading: false,
        },
        loadingTableHistory: false,
      };
    case actions.cases.success.unsuccessful:
      return {
        ...state,
        unsuccessful: {
          ...action.payload,
          loading: false,
        },
        loadingTableUnsuccessful: false,
      };
    case actions.cases.creation.create:
      return {
        ...state,
        ...action.payload,
        sent: false,
        loading: true,
      };
    case actions.cases.creation.reset:
      return {
        ...state,
        ...action.payload,
        sent: false,
        loading: false,
      };
    case actions.cases.creation.success:
      return {
        ...state,
        create: {
          ...action.payload,
        },
        loading: false,
        sent: true,
      };
    case actions.cases.creation.fail:
      return {
        ...state,
        create: {
          ...action.payload,
        },
        sent: false,
      };
    case actions.cases.update.start:
      return {
        ...state,
        sent: false,
        loading: true,
      };
    case actions.cases.update.success:
      return {
        ...state,
        sent: true,
        loading: false,
      };
    case actions.cases.update.fail:
      return {
        ...state,
        sent: false,
        loading: false,
      };
    case actions.cases.chain.success:
      return {
        ...state,
        chain: {
          ...action.payload,
        },
      };
    case actions.cases.view.reset:
      return {
        ...state,
        view: initialState.view,
        chain: initialState.chain,
        loading: false
      };
    default:
      return state;
  }
}
