import { useCallback, useEffect, useState } from "react";

export default function usePushModal(isPushModal: boolean) {
  const [pushModal, setPushModal] = useState<boolean>();
  const togglePushIsOpen = useCallback(() => {
    setPushModal((state) => !state);
  }, []);
  useEffect(() => {
    if (isPushModal) {
      setPushModal(true);
    }
  }, [isPushModal]);

  return { pushModal, setPushModal, togglePushIsOpen };
}
