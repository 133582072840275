export const TASK_TYPE_OPTIONS = [
  { name: "48hr Notification Follow up", value: "notif_48h" },
  { name: "Account Maintenance", value: "acc_maintenance" },
  { name: "Alert Review", value: "alert_review" },
  { name: "Edit Profile", value: "edit_profile" },
  { name: "Insurance verification", value: "insurance_verification" },
  { name: "Technical Support", value: "technical_supp" },
  { name: "Training Call", value: "training_call" },
  { name: "Welcome Call", value: "welcome_call" },
  { name: "Wellness Call", value: "wellness_call" },
  { name: "CCM - Monthly Call", value: "monthly_call" },
  { name: "CCM – Social Services", value: "social_services" },
  { name: "CCM – Med Serv Assist", value: "med_serv_assist" },
];
