import React from "react";
import Highcharts from "highcharts";

import { SoapNotesValuesType } from "./soapNotes";

import { IPatient } from "../store/reducers/patients";
import { DiabeticTriageState } from "../components/DiabeticTriage";
import { MEASUREMENT_TYPES } from "./measuarementTypes";
import measurementsOldNames from "../constants/measurementsOldNames";
import TIMER_STATES from "../constants/timerStates";
import { FIELD_NAMES as TIMER_FIELD_NAMES } from "../constants/taskTimer";
import { SELECT_NONE } from "../constants/filters";
import PATIENT_STATUSES from "../constants/patientStatuses";

export interface CallResult {
  date: string;
  result: string;
  comment: string;
  medicalAssistantFirstName?: string;
  medicalAssistantLastName?: string;
}
export interface Chain {
  id: number;
  cause: string;
  closedAt: string;
  createdAt: string;
}

export enum CaseCause {
  intervention = "Intervention",
  outreach = "Outreach",
}

export interface Address {
  zip: string;
  city: string;
  state: string;
  address: string;
}

export interface Case {
  id: number;
  status: "followup" | "review" | "close" | "unsuccessful";
  createdAt: string;
  doctorId: number;
  doctorFirstName: string;
  doctorLastName: string;
  measurements: any[];
  medicalAssistantId: number;
  patient: IPatient;
  soapNotes: SoapNotesValuesType;
  summary: string;
  escalation: string;
  holdTo: string;
  closedAt: string;
  cause: CaseCause;
  diabeticTriage: DiabeticTriageState;
  callResults: CallResult[];
  lastCallResultDate: string;
}

export interface Measurement {
  type: measurementsOldNames;
  icon: string;
  values: MeasurementValue[];
  orderNumber: number;
}

export interface MeasurementValue {
  name: string;
  minValue: number;
  maxValue: number;
}

export interface MeasurementSetValue {
  measurement: Measurement;
  key: string;
  value: number;
  date?: string;
}

export interface MeasurementStepsValue {
  type: measurementsOldNames;
  column1: string;
  column2: string;
  values: string;
}

export interface IDevice {
  id: string;
  type: string;
  manufacturer: string;
  model: string;
  status: string | null;
  user?: string | undefined;
  assignStatus?: null | "assign" | "unassign";
}

export interface IReport {
  uuid: string;
  firstName: string;
  lastName: string;
  dob: string;
  company: string;
  timer: number;
  total: number;
  daysWithReading: number;
  bloodPressure: number;
  glucose: number;
  pulseOx: number;
  temp: number;
  weight: number;
}

export type HighchartsRefCallback = (
  instance: {
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  } | null
) => void;

export type IMeasurement = {
  measurementResult: number | null;
  measurementType: MEASUREMENT_TYPES;
  createdDate: string;
  patientId: string;
  frontId: string;
  isDeleted?: boolean;
  isManuallyEntered?: boolean;
};

export interface INote {
  patient: string;
  firstName: string;
  lastName: string;
  createdDate?: string;
  message: string;
  title: string;
  id?: number;
}

export interface ITimerData {
  id?: number;
  [TIMER_FIELD_NAMES.START_TIME]: string | null;
  [TIMER_FIELD_NAMES.END_TIME]: string | null;
  [TIMER_FIELD_NAMES.TASK_TYPE]: string | null;
  [TIMER_FIELD_NAMES.DURATION]: number | null;
  [TIMER_FIELD_NAMES.NOTE]: Partial<INote> | null;
  [TIMER_FIELD_NAMES.PATIENT]?: string | null;
  [TIMER_FIELD_NAMES.CREATED_DATE]?: string | null;
  [TIMER_FIELD_NAMES.PROFILE]?: {
    id: number;
    name: string;
    lastName: string;
  };
}

export interface ITimer {
  startTimeHistory: Array<Date | null>;
  endTime: string | null;
  duration: number | null;
  patient: string | null;
  showTimerPanel: boolean;
  state: TIMER_STATES;
  loading: boolean;
  error: any;
  showForm: boolean;
  isEditing: boolean;
  data: ITimerData; //form
}

export interface IReportItem extends Omit<IReport, "timer"> {
  timer: string;
}

export interface OptionProps {
  name: string | number;
  value: string | number;
  id?: string | number;
}
export interface SearchPatientProps {
  cities: Array<OptionProps>;
  companies: Array<OptionProps>;
  states: Array<OptionProps>;
  loading?: boolean;
  statuses?: Array<OptionProps>;
  client?:  boolean | typeof SELECT_NONE | string;           //may be need to remove
}

export interface SearchPanelState {
  name: string;
  city: string | undefined;
  company: string | undefined;
  state: string | undefined;
  modalDisabled: boolean;
  untrained?: boolean | typeof SELECT_NONE;
  lastMeasurementLt?: boolean | typeof SELECT_NONE | string;
  isAlert?: boolean | typeof SELECT_NONE | string;
  status?: PATIENT_STATUSES;
  client?:  boolean | typeof SELECT_NONE | string;
}
