import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";
import { ITimer } from "../../models/basicModels";

export interface ITimers {
  startDate: string | null;
  endDate: string | null;
  patientId: string | null;
  loading: boolean;
  error: any;
  count?: number;
  prev?: string;
  next?: string;
  data: ITimer["data"][];
}

const initialState: ITimers = {
  startDate: null,
  endDate: null,
  patientId: null,
  data: [],
  loading: false,
  error: null,
};

export default function taskTimer(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.tasksTimers.get.start:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case actionTypes.tasksTimers.get.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.tasksTimers.get.fail:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionTypes.tasksTimers.download.start:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case actionTypes.tasksTimers.download.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.tasksTimers.download.fail:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionTypes.tasksTimers.reset:
      return initialState;
    default:
      return state;
  }
}
