//https://stackoverflow.com/a/25961926
export default function stringToDate(
  _date: string,
  _format: string,
  _dateDelimiter: string,
  _formatDelimiter: string
): Date {
  const formatLowerCase = _format.toLowerCase();
  const formatItems = formatLowerCase.split(_formatDelimiter);
  const dateItems = _date.split(_dateDelimiter);
  const monthIndex = formatItems.indexOf("mm");
  const dayIndex = formatItems.indexOf("dd");
  const yearIndex = formatItems.indexOf("yyyy");
  let month = parseInt(dateItems[monthIndex]);
  month -= 1;
  const formatedDate = new Date(
    Number(dateItems[yearIndex]),
    month,
    Number(dateItems[dayIndex]),
    0,
    0
  );
  return formatedDate;
}
