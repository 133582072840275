export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isString(value: any): boolean {
  return typeof value === "string" || value instanceof String;
}

export function camelToSnake(string:string) {
  return string.replace(/[\w]([A-Z])/g, function(m) {
      return m[0] + "_" + m[1];
  }).toLowerCase();
}