import React from "react";
import "../styles.scss";
import { Measurement } from "../../../models/basicModels";
import { Checkbox } from "../../Form/Checkbox";

interface Props {
  measurement: Measurement;
  selected: boolean;
  onChange?: (measurement: Measurement) => void;
  disabled: boolean;
}

const MeasurementsItem = ({
  measurement,
  selected,
  onChange,
  disabled,
}: Props) => {
  return (
    <div
      className="measurement_list__item"
    >
      <Checkbox
        value="checkbox"
        name="checkbox"
        checked={selected}
        label={measurement.type}
        onChange={() => onChange && onChange(measurement)}
        disabled={disabled}
        icon={measurement.icon}
      />
    </div>
  );
};

export default MeasurementsItem;

MeasurementsItem.defaultProps = {
  selected: false,
};
