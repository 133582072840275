import React from "react";

import "./styles.scss";
import { Button } from "../Form/Button";
import { useLogoutHandler, useTimer } from "./TimerModal.hooks";

interface IProps {
  closeModal: () => void;
}

export default function TimerModal({ closeModal }: IProps) {
  const { sessionTimeOut, currentCount } = useTimer();
  const handleLogout = useLogoutHandler(closeModal);

  return (
    <>
      {!sessionTimeOut ? (
        <div>
          <p>
            Please, click “Continue to keep working” or “Log out” to end your
            session now.
          </p>
          <p>Your online session will expire in {currentCount} sec</p>
          <div className="buttons-timeout-logout">
            <Button
              onClick={() => handleLogout()}
              className="logout-button"
              value="Log out"
            />
            <Button
              onClick={() => closeModal()}
              type="primary"
              value="Continue"
            />
          </div>
        </div>
      ) : (
        <div>
          <p>
            Your session has expired. You will be redirected to the “Log in”
            page.
          </p>
          <div className="buttons-timeout-logout">
            <Button
              onClick={() => handleLogout()}
              className="logout-button"
              value="Ok"
            />
          </div>
        </div>
      )}
    </>
  );
}
