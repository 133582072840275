import actions from "../../constants/actionsTypes";

export interface ResetPasswordAction {
  password: string;
  newPassword: string;
  rePassword: string;
  closeModal?: () => void;
  resetForm?: any;
}

export interface ForgotAction {
  email: string;
}

export const sendForgotEmail = (payload: ForgotAction) => ({
  type: actions.forgot.start,
  payload,
});

export const resetPassword = (payload: ResetPasswordAction) => ({
  type: actions.changePassword.resetPassword.start,
  payload,
});

export const setResetTokenSuccess = (payload: {
  isTokenVerified: boolean;
  tokenExpired: boolean;
}) => ({
  type: actions.changePassword.resetToken.success,
  payload,
});

export const setResetTokenFail = (error: any) => ({
  type: actions.changePassword.resetToken.fail,
  payload: error,
});

export const resetForgottenPasswordStart = (payload: {
  password: string;
  token: string;
}) => ({
  type: actions.changePassword.resetForgottenPassword.start,
  payload,
});

export const resetForgottenPasswordSuccess = (payload: {
  isReseted: boolean;
}) => ({
  type: actions.changePassword.resetForgottenPassword.success,
  payload,
});

export const resetForgottenPasswordFail = (error: any) => ({
  type: actions.changePassword.resetForgottenPassword.fail,
  payload: error,
});

export const setResetTokenStart = (payload: string) => ({
  type: actions.changePassword.resetToken.start,
  payload,
});
