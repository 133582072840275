import axios from "axios";
import { put, select, call } from "redux-saga/effects";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";
import { saveAs } from "file-saver";

import { API_PATIENT, API_GET_PATIENT_EXPORT } from "../../constants/urls";
import actions from "../../constants/actionsTypes";
import { recognizeErrorMessage } from "../../utils/errors";
import { errorNotify } from "../../components/Toaster/toster";
import { authSelector } from "./auth";
import { Auth } from "../reducers/auth";
import { SELECT_NONE } from "../../constants/filters";
import {
  getPatientsExportStart,
  getPatientsExportSuccess,
  getPatientsExportFail,
} from "../actions/patients";

export function* getPatientsSaga({
  payload,
}: {
  type: string;
  payload: { [key: string]: string | undefined };
}) {
  try {
    const auth: Auth = yield select(authSelector);
    const params = Object.entries(payload).reduce((result, [key, value]) => {
      result[key] = value === SELECT_NONE ? undefined : value;
      return result;
    }, {} as { [key: string]: string | undefined });
    if (auth.jobTitle === "Primary_Care_Physician") {
      params.pcp = auth.pcpUuid;
    }
    const { data } = yield axios.get(API_PATIENT, {
      params: snakeCaseKeys({ ...params, name: payload.name || "" }),
    });

    if (!data.error) {
      const transformedData = camelCaseKeys(data, { deep: true });
      yield put({
        type: actions.patients.list.success,
        payload: transformedData,
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put({
      type: actions.patients.list.fail,
      payload: { errors: errorMsg },
    });
    errorNotify(errorMsg);
  }
}

export function* getPatientsExportSaga({
  payload: { modalDisabled, ...payload },
}: ReturnType<typeof getPatientsExportStart>) {
  try {
    const auth: Auth = yield select(authSelector);
    const params = Object.entries(payload).reduce((result, [key, value]) => {
      result[key] = value === SELECT_NONE ? undefined : value;
      return result;
    }, {} as { [key: string]: string | undefined });
    if (auth.jobTitle === "Primary_Care_Physician") {
      params.pcp = auth.pcpUuid;
    }
    const { data } = yield axios.get(API_GET_PATIENT_EXPORT, {
      params: snakeCaseKeys({ ...params }),
    });

    if (!data.error) {
      const blob = new Blob([data]);
      yield call(saveAs, blob, "patients.csv");
      yield put(getPatientsExportSuccess());
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield call(getPatientsExportFail, error);
    errorNotify(errorMsg);
  }
}
