import axios from "axios";
import { put, select, all, call } from "redux-saga/effects";
import { createSelector } from "reselect";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";

import { errorNotify, warningNotify } from "../../components/Toaster/toster";
import { recognizeErrorMessage } from "../../utils/errors";
import { API_DEVICES } from "../../constants/urls";
import { IAdditionalDevice } from "../../components/PatientForm/PatientForm.types";

import { AppState } from "../reducers";
import { PatientData } from "../reducers/patient";

import {
  getPatientDevicesSuccess,
  getPatientDevicesFailure,
  addPatientDevicesSuccess,
  addPatientDevicesFailure,
} from "../actions/patientDevices";
import { patientSelector } from "./patient";
import { IPatientDevices } from "../reducers/patientDevices";
import { getDevicesSuccess, resetDevices } from "../actions/devices";
import { devicesSelector, buildDevicesList } from "../sagas/devices";
import { IDevice } from "../reducers/devices";

export const patientDevicesSelector = createSelector(
  ({ patientDevices }: AppState): IPatientDevices => patientDevices,
  (devices) => devices.list
);

export function* getPatientDevicesSaga() {
  const patient: PatientData = yield select(patientSelector);
  const params = snakeCaseKeys({ userId: patient.uuid });
  if (patient.uuid) {
    try {
      const { data } = yield axios.get(API_DEVICES, {
        params,
      });
      const list = camelCaseKeys(data, { deep: true });
      const devices = yield select(devicesSelector);
      yield put(
        getDevicesSuccess({ list: { ...devices, ...buildDevicesList(list) } })
      );
      yield put(getPatientDevicesSuccess({ list }));
    } catch (error) {
      const errorMsg = recognizeErrorMessage(error);
      yield put(getPatientDevicesFailure(errorMsg));
      errorNotify(errorMsg[0]);
    }
  }
}

export function* addPatientDevicesSaga({
  payload,
}: {
  type: string;
  payload: (IAdditionalDevice | null)[];
}) {
  try {
    const devices: { [key: string]: IDevice } = yield select(devicesSelector);
    const responses: {
      data: IDevice;
      status: number;
      config: { data: string };
    }[] = yield all(
      payload.map(({ ...item }) => {
        return call(() =>
          axios.put(`${API_DEVICES}${item.id}/`, snakeCaseKeys(item), {
            validateStatus: (status) => {
              return Number(status) === 200 || Number(status) === 400;
            },
          })
        );
      })
    );
    const withErrors = responses.filter((item) => item?.status === 400);

    const buildedData = responses
      .map((item) => camelCaseKeys(item.data))
      .filter((item) => item.user);
    yield put(addPatientDevicesSuccess({ list: buildedData }));
    const newDevices = { ...devices, ...buildDevicesList(buildedData) };
    if (withErrors.length) {
      yield call(warningNotify, "Please, check devices one more time");
      yield put(resetDevices());
    } else {
      yield put(getDevicesSuccess({ list: newDevices }));
    }
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put(addPatientDevicesFailure(errorMsg));
    errorNotify(errorMsg[0]);
  }
}
