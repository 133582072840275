import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormikHelpers, FormikErrors } from "formik";

import { resetPasswordSelector } from "../../store/sagas/resetPassword";
import { resetPassword } from "../../store/actions/resetPassword";
import { ValuesType } from "./ResetPasswordModal.types";
import { INITIAL_VALUES } from "./ResetPasswordModal.constants";

export function useResetPassword() {
  return useSelector(resetPasswordSelector);
}

export function useSubmitHandler(closeModal: () => void) {
  const dispatch = useDispatch();
  return useCallback(
    (
      form: Parameters<typeof resetPassword>[0],
      { resetForm }: FormikHelpers<ValuesType>
    ) => {
      //put Values Type
      dispatch(resetPassword({ ...form, closeModal, resetForm }));
    },
    [dispatch, closeModal]
  );
}

export function useCancelHandler(
  closeModal: () => void,
  resetForm: FormikHelpers<ValuesType>["resetForm"]
) {
  return useCallback(() => {
    closeModal();
    resetForm();
  }, [closeModal, resetForm]);
}

export function useErrors() {
  const error = useResetPassword()?.error;
  const transformedErrors = useMemo(() => {
    if (error) {
      return Object.entries(INITIAL_VALUES).reduce((result, [key]) => {
        //@ts-ignore
        result[key] = Array.isArray(error) ? error.join(", ") : error;
        return result;
      }, {} as FormikErrors<ValuesType>);
    }
    return null;
  }, [error]);
  return transformedErrors;
}
