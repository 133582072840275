export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL_PRODUCTION
    : process.env.REACT_APP_BASE_URL_DEVELOPMENT;
  
export const API_URL =process.env.REACT_APP_API_URL
// Local
export const LOCAL_LOGIN = "/login";
export const LOCAL_FORGOT = "/forgot";
export const LOCAL_NEW_PASSWORD = "/new-password";
export const LOCAL_SEARCH = "/search";
export const LOCAL_DASHBOARD = "/dashboard";
export const LOCAL_CASES = "/cases";
export const LOCAL_ORDERS = "/orders";
export const LOCAL_INTERVENTION = "/intervention";
export const LOCAL_DOC_CASES = "/doc_cases";
export const LOCAL_OUTREACH = "/outreach";
export const LOCAL_PATIENT = "/patient/:id?";
export const LOCAL_SUMMARY_REPORT = "/summary-report";
export const LOCAL_CHATS = "/chats";
export const LOCAL_PATIENT_REPORT = "/patient-report";
export const LOCAL_PCP = "/primary-care-physician";
export const LOCAL_PROFILE = "/profile/:id?";

// API
export const API_LOGIN = "api/login/";
export const API_FORGOT = "api/forgot_password/";
export const API_PATIENT = "api/patient/";
export const API_CASE_CREATE = "api/add_new_case/";
export const API_UNDER_REVIEW_CASES = "api/case/under_review/";
export const API_HISTORY_CASES = "api/case/history/";
export const API_CASE_FOLLOWUP = "api/case/followup";
export const API_CASE_HOLDING = "api/case/holding";
export const API_CASE_CHAIN = "api/case/chain";
export const API_CASE_UNSUCCESSFUL = "api/case/unsuccessful";
export const API_CASE = "api/case";
export const API_LOGOUT_TIME_OUT = "api/logout";
export const API_RESET = "api/change_password/";
export const API_CLIENTS = "api/client/";
export const API_DEVICES = "api/device/";
export const API_PHYSICIANS = "api/physician/";
export const API_SUMMARY_REPORT = "api/report/summary/";
export const API_PATIENT_REPORT = "api/report/patient/";
export const API_PRIMARY_CARE_PHYSICIAN = "api/physician/";
export const API_NOTIFICATIONS = "api/notification/";
export const API_NOTES = "api/journal/";
export const API_HEALTH_GOALS = "api/health_goal/";
export const API_PCP_RATE = "api/pcp_dashboard/";
export const API_VITAL_ALERTS = "api/alert/";
export const API_ZIP = "api/zipcode/";
export const API_RESET_FORGOTTEN_TOKEN = "set-password/";
export const API_CHECK_TOKEN = "api/check_token/";
export const API_TIMER_TASK = "api/task_timer/";
export const API_TIMER_TASK_REPORT = "api/tasktimer-csv-export/";
export const API_GET_PATIENT_EXPORT = "api/patient-export/";
