import { createSelector } from "reselect";
import { call, put, select } from "redux-saga/effects";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";
import { saveAs } from "file-saver";
import moment from "moment";

import { AppState } from "../reducers";
import {
  getTasksTimersStart,
  getTasksTimersSuccess,
  getTasksTimersFail,
  downloadTasksTimersReportStart,
  downloadTasksTimersReportSuccess,
  downloadTasksTimersReportFail,
} from "../actions/tasksTimers";
import { errorNotify } from "../../components/Toaster/toster";
import { API_TIMER_TASK, API_TIMER_TASK_REPORT } from "../../constants/urls";
import { recognizeErrorMessage } from "../../utils/errors";
import { patientSelector } from "./patient";
import { camelToSnake } from "../../utils/stringUtils";

export const tasksTimersSelector = createSelector(
  ({ tasksTimers }: AppState) => tasksTimers,
  (tasksTimers) => tasksTimers
);

export function* getTasksTimersSaga({
  payload,
}: ReturnType<typeof getTasksTimersStart>) {
  try {
    const patient: AppState["patient"]["data"] = yield select(patientSelector);
    if (patient.uuid) {
      const { data } = yield axios.get(API_TIMER_TASK, {
        params: snakeCaseKeys({
          ...payload,
          patientId: patient.uuid,
          ordering: camelToSnake(payload.ordering || ""),
        }),
      });
      const { results, ...rest } = camelCaseKeys(data, { deep: true });
      yield put(getTasksTimersSuccess({ ...rest, data: results }));
    }
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put(getTasksTimersFail(error));
    yield call(errorNotify, errorMsg);
  }
}

export function* downloadTasksTimersSaga({
  payload,
}: ReturnType<typeof downloadTasksTimersReportStart>) {
  try {
    const { data } = yield axios.get(API_TIMER_TASK_REPORT, {
      params: snakeCaseKeys(payload),
    });
    const blob = new Blob([data]);
    yield call(
      saveAs,
      blob,
      `report_${payload.firstName}_${payload.lastName}_${moment(
        payload.startDate
      ).format("MM/DD/YY")}-${moment(payload.endDate).format("MM/DD/YY")}.csv`
    );
    yield put(downloadTasksTimersReportSuccess());
  } catch (error) {
    yield put(downloadTasksTimersReportFail(error));
  }
}
