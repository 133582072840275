import { put, select, call } from "redux-saga/effects";

import { createSelector } from "reselect";
import snakeCaseKeys from "snakecase-keys";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";

import {
  getHealthGoalsFailure,
  getHealthGoalsStart,
  getHealthGoalsSuccess,
  updateHealthGoalsStart,
  updateHealthGoalsSuccess,
  updateHealthGoalsFailure,
} from "../actions/healtGoals";
import { API_HEALTH_GOALS } from "../../constants/urls";
import { errorNotify, successNotify } from "../../components/Toaster/toster";
import { recognizeErrorMessage } from "../../utils/errors";

import { AppState } from "../reducers";
import { patientSelector } from "./patient";
import { PatientData } from "../reducers/patient";
import { IHealthGoal } from "../reducers/healthGoals";

export const healthGoalsSelector = createSelector(
  ({ healthGoals }: AppState) => healthGoals,
  (healthGoals) => healthGoals
);

export function* getHealthGoalssSaga({
  payload,
}: ReturnType<typeof getHealthGoalsStart>) {
  try {
    const patient: PatientData = yield select(patientSelector);
    if (!patient?.uuid) {
      throw new Error("There is no patient!");
    }
    const params = snakeCaseKeys({
      ...payload,
      patientId: patient.uuid,
    });
    const { data } = yield axios.get(API_HEALTH_GOALS, { params });
    yield put(
      getHealthGoalsSuccess({
        data: camelCaseKeys(data),
      })
    );
  } catch (error) {
    yield put(getHealthGoalsFailure(error));
  }
}

export function* updateHealthGoalsSaga({
  payload,
}: ReturnType<typeof updateHealthGoalsStart>) {
  const transformed = { ...payload, data: snakeCaseKeys(payload.data || []) };
  try {
    const { data } = yield axios.post(API_HEALTH_GOALS, transformed);
    const list = camelCaseKeys<IHealthGoal[]>(data.data);
    yield put(updateHealthGoalsSuccess({ data: list }));
    yield call(successNotify, "Health Goals are updated!");
  } catch (error) {
    yield put(updateHealthGoalsFailure(error));
    errorNotify(recognizeErrorMessage(error));
  }
}
