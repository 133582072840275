import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as H from "history";
import { matchPath } from "react-router";
import { useStopTimerHandler } from "../hooks";

import { showTaskTimer, hideTaskTimer } from "../store/actions/taskTimer";

export function useShowHandler(allowRoutes: string[], show: boolean) {
  return useCallback(
    (currentLocation: H.Location, nextLocation?: H.Location) => {
      let matchedPath = false;
      if (nextLocation) {
        matchedPath = !!allowRoutes.find(
          (route) =>
            !!matchPath(nextLocation.pathname, {
              path: route,
              exact: true,
              strict: true,
            })
        );
      }
      if (matchedPath) {
        return false;
      }

      return !!show;
    },
    [allowRoutes, show]
  );
}

export function useManagetaskTimer() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showTaskTimer());
    return () => {
      dispatch(hideTaskTimer());
    };
  }, [dispatch]);
}

export function useOnConfirm() {
  const onStop = useStopTimerHandler();
  return useCallback(
    (onCancel: () => void) => () => {
      onStop();
      onCancel();
    },
    [onStop]
  );
}
