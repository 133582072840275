import React from "react";
import ReactPDF, { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
  },
});

interface IProps {
  children: React.ReactNode | React.ReactNodeArray;
  style?: ReactPDF.Style;
}
function PDFRow({ children, style }: IProps) {
  return <View style={[styles.row, style || {}]}>{children}</View>;
}

export default PDFRow;
