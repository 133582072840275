import React from "react";
import "./cases.scss";
// Redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../store/reducers";
import {
  Case,
  CaseCause,
  Chain,
  Measurement,
  MeasurementSetValue,
  MeasurementStepsValue,
} from "../../models/basicModels";
import groupMeasurementValues from '../../utils/groupMeasurementValues'

import processMeasurementValue from "../../utils/processMeasurementValue";

import prepareMeasurement from "../../utils/prepareMeasurement";

import measurementsOldNames from "../../constants/measurementsOldNames";
import measurementsDefaultData from "../../constants/measurementsDefaultData";
// Components
import { ScreenWrapper } from "../ScreenWrapper";
import MeasurementsList from "../MeasurementsList";
import MeasurementsValueList from "../MeasurementValues";
import { getMeasurementIconByOldName } from "../../utils/getMeasurementIcon";
// Icons
import SoapNotes from "./FieldSets/SoapNotes";
import CaseInformation from "./FieldSets/CaseInformation";
import ProvidersInformation from "./FieldSets/ProvidersInformation";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import SoapNotesColumn from "./FieldSets/SoapNotesColumn";
import { ClinicalExam, SoapNotesType } from "../../models/soapNotes";
import { Button } from "../Form";
import {
  getCase,
  GetCaseAction,
  getChainCase,
  resetCaseUpdate,
  updateCase,
} from "../../store/actions/cases";
import Modal from "../Modal";
import {
  LOCAL_CASES,
  LOCAL_INTERVENTION,
  LOCAL_OUTREACH,
} from "../../constants/urls";
import { ErrorText, MeasurementErrorText } from "../../constants/errorMessages";
import actionsTypes from "../../constants/actionsTypes";
import Loader from "../Loader";
import ChainInfo from "../Intervention/ChainInfo";
import spinnerIcon from "../../assets/images/ic-spinner-white.svg";

interface SingleCaseProps extends RouteComponentProps {
  isReadOnly?: boolean;
  currentCase?: Case;
  updateCase: typeof updateCase;
  sent?: Boolean;
  loading: Boolean;
  resetCaseUpdate: typeof resetCaseUpdate;
  dispatch: Dispatch;
  getCase: typeof getCase;
  caseLoading: boolean;
  getChainCase: typeof getChainCase;
  chain?: Chain[];
}

interface SingleCaseState {
  updatedCase?: Case;
  measurements: Measurement[];
  selectedMeasurements: Measurement[];
  measurementsValues: MeasurementSetValue[];
  showModal: boolean;
  closeDocType: CaseCause;
  redirectType: RedirectType;
  startRedirect: boolean;
  measurementsListValid: boolean;
  validate: boolean;
}

export enum RedirectType {
  singleCase,
  allCases,
  none,
}

class SingleCase extends React.Component<SingleCaseProps, SingleCaseState> {
  state: SingleCaseState = {
    updatedCase: this.props.currentCase,
    measurements: measurementsDefaultData,
    selectedMeasurements: [],
    measurementsValues: [],
    showModal: false,
    closeDocType: CaseCause.intervention,
    redirectType: RedirectType.none,
    startRedirect: false,
    measurementsListValid: true,
    validate: false,
  };

  static defaultProps = {
    isReadOnly: false,
    sent: false,
  };

  constructor(props: SingleCaseProps, state: SingleCaseState) {
    super(props, state);

    const { isReadOnly, dispatch } = this.props;
    // @ts-ignore
    const caseId = Number(this.props.match.params.id);
    this.props.getCase({ id: caseId });

    if (isReadOnly) {
      dispatch({
        type: actionsTypes.settings.setup,
        payload: {
          header: false,
          navigation: false,
          printHeader: true,
        },
      });
    }
  }

  componentDidUpdate(prevProps: SingleCaseProps) {
    if (
      this.props.currentCase &&
      this.props.currentCase !== prevProps.currentCase
    ) {
      // @ts-ignore
      this.props.getChainCase(this.props.currentCase.id);
      this.setState({
        updatedCase: this.props.currentCase,
      });
    }
    if (
      this.props.sent !== prevProps.sent &&
      !this.props.sent &&
      this.state.redirectType !== RedirectType.none
    ) {
      this.setState({ startRedirect: true });
    }

    if (
      this.props.isReadOnly !== prevProps.isReadOnly &&
      this.props.isReadOnly &&
      this.props.currentCase
    ) {
      const causeTitle =
        (this.props.currentCase.cause as string) === "intervention"
          ? "Intervention"
          : "Outreach";

      this.props.dispatch({
        type: actionsTypes.settings.setup,
        payload: {
          printName: `${this.props.currentCase.patient.firstName} ${this.props.currentCase.patient.lastName} ${causeTitle}`,
        },
      });
    }
  }

  render(): React.ReactNode {
    const {
      measurements,
      selectedMeasurements,
      showModal,
      startRedirect,
      closeDocType,
      updatedCase,
      measurementsListValid,
      validate,
    } = this.state;

    const { currentCase, sent, caseLoading, isReadOnly, chain } = this.props;

    if (caseLoading) return <Loader />;

    if (!currentCase) {
      return null;
    }

    if (sent) {
      this.props.resetCaseUpdate();
    }

    if (startRedirect && updatedCase) {
      if (this.state.redirectType === RedirectType.singleCase) {
        switch (closeDocType) {
          case CaseCause.intervention:
            return (
              <Redirect
                to={{
                  pathname: `${LOCAL_INTERVENTION}/${currentCase.patient.uuid}`,
                  state: { parentId: updatedCase.id },
                }}
              />
            );
          case CaseCause.outreach:
            return (
              <Redirect
                to={{
                  pathname: `${LOCAL_OUTREACH}/${currentCase.patient.uuid}`,
                  state: { parentId: updatedCase.id },
                }}
              />
            );
        }
      } else {
        return <Redirect to={LOCAL_CASES} />;
      }
    }

    return (
      <div>
        {isReadOnly && this.getPrintHeader()}
        <CaseInformation case={currentCase} />
        {chain && chain.length > 0 && (
          <>
            <p className="screen-wrapper-column__screen-label"> Case history</p>
            <ChainInfo chain={chain} />
          </>
        )}
        <ProvidersInformation case={currentCase} />
        <SoapNotes case={currentCase} />

        <ScreenWrapper leftBorderColor="#b6e0f8">
          <div className="screen-wrapper__section screen-wrapper__section--rotate">
            {this.renderMeasurementsStep3()}
            {currentCase.soapNotes &&
              Object.keys(currentCase.soapNotes).length > 0 && (
                <>
                  <SoapNotesColumn
                    case={currentCase}
                    notes={[
                      SoapNotesType.lastFoodFluids,
                      SoapNotesType.exerciseActivity,
                    ]}
                  />
                  <div className="screen-wrapper-column screen-wrapper-column--nospacing">
                    <p className="screen-wrapper-column__label">
                      Clinical exam:
                    </p>
                    <p className="screen-wrapper__section--rotate screen-wrapper-column__radios">
                      <label>
                        <input
                          type="radio"
                          checked={
                            currentCase.soapNotes[
                              SoapNotesType.clinicalExam
                            ] === ClinicalExam.Extraoral
                          }
                          disabled
                          name="clinical_exam"
                        />
                        {ClinicalExam.Extraoral}
                      </label>
                      <label>
                        <input
                          type="radio"
                          disabled
                          checked={
                            currentCase.soapNotes[
                              SoapNotesType.clinicalExam
                            ] === ClinicalExam.Intraoral
                          }
                          name="clinical_exam"
                        />
                        {ClinicalExam.Intraoral}
                      </label>
                    </p>
                  </div>
                </>
              )}
          </div>
        </ScreenWrapper>
        {currentCase.soapNotes &&
          Object.keys(currentCase.soapNotes).length > 0 && (
            <ScreenWrapper leftBorderColor="#b6e0f8">
              <div className="screen-wrapper__section screen-wrapper__section--rotate">
                <SoapNotesColumn
                  case={currentCase}
                  notes={[
                    SoapNotesType.assessment,
                    SoapNotesType.plan,
                    SoapNotesType.waitingPeriod,
                  ]}
                />
              </div>
            </ScreenWrapper>
          )}
        {!this.props.isReadOnly ? (
          <ScreenWrapper title="Case changes" leftBorderColor="#cbe0ad">
            {!measurementsListValid && (
              <label className="screen-wrapper__error__title">
                {MeasurementErrorText}
              </label>
            )}
            <MeasurementsList
              measurements={measurements}
              onMeasurementSelected={this.measurementSelected}
            />
            <MeasurementsValueList
              onChange={this.updateMeasurementsValues}
              validate={validate}
              measurements={selectedMeasurements}
            />
            <div className="screen-wrapper__section">
              <div className="screen-wrapper-column__button">
                <Button
                  name={"close_case"}
                  onClick={this.handleStayOpenClick}
                  value="Stay opened"
                />
                <div style={{ width: 10 }} />
                <button
                  name="send_review"
                  type="submit"
                  onClick={this.handleSaveButtonClick}
                >
                  {!this.props.loading ? (
                    "Save & Close"
                  ) : (
                    <img
                      src={spinnerIcon}
                      className="screen-wrapper-column__button__loader"
                      alt="loader"
                    />
                  )}
                </button>
              </div>
            </div>
            <Modal
              isDisabled={!showModal}
              title="Keep up the case"
              onClose={this.toggleModal}
            >
              {this.getDoctorSelectionModal()}
            </Modal>
          </ScreenWrapper>
        ) : (
          this.renderMeasurementsStep4()
        )}
      </div>
    );
  }

  measurementSelected = (measurements: Measurement[]): void => {
    this.setState({
      validate: false,
      selectedMeasurements: measurements,
    });
  };

  toggleModal = () => this.setState({ showModal: !this.state.showModal });

  getPrintHeader = (): JSX.Element | null => {
    const { currentCase } = this.props;
    if (currentCase) {
      let dateOptions = {
        day: "numeric",
        month: "short",
        hour: "numeric",
        minute: "numeric",
      };
      let createdAt = new Date(
          parseInt(currentCase.createdAt) * 1000
        ).toLocaleDateString("en-US", dateOptions),
        closedAt = currentCase.closedAt
          ? new Date(parseInt(currentCase.closedAt) * 1000).toLocaleDateString(
              "en-US",
              dateOptions
            )
          : "still opened";

      const causeTitle =
        (currentCase.cause as string) === "intervention"
          ? "Intervention"
          : "Outreach";

      return (
        <div className="case-print-details">
          <div className="case-print__left">
            <strong>{causeTitle}</strong>
            <p>{`${currentCase.patient.firstName} ${currentCase.patient.lastName} ${currentCase.patient.dob}`}</p>
          </div>
          <div className="case-print__right">
            <p>Created at: {createdAt}</p>
            <p>Closed at: {closedAt}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  getDoctorSelectionModal() {
    const { closeDocType } = this.state;

    return (
      <>
        <label className={"screen-wrapper-column__regular-label"}>
          Do you want to keep this case open?
        </label>
        <br />
        <label className={"screen-wrapper-column__regular-label"}>
          To keep this member's case open, you will need to create a new
          Intervention
        </label>
        <br />
        <label className={"screen-wrapper-column__regular-label"}>
          or Outreach case. All information will be displayed in case history.
        </label>
        <div className="screen-wrapper__section screen-wrapper__section--noborder">
          <p className=" screen-wrapper__section--rotate screen-wrapper-column__radios">
            <label>
              <input
                type="radio"
                value={"Intervention"}
                onChange={this.handleSelectCloseDocType}
                checked={closeDocType === CaseCause.intervention}
                name="close_doc_type"
              />
              Intervention
            </label>
            <label>
              <input
                type="radio"
                value={"Outreach"}
                onChange={this.handleSelectCloseDocType}
                checked={closeDocType === CaseCause.outreach}
                name="close_doc_type"
              />
              Outreach
            </label>
          </p>
        </div>
        <div className="screen-wrapper__actions screen-wrapper__actions--modal">
          <Button onClick={this.toggleModal} value="Cancel" />
          <button type="submit" onClick={this.confirmCase}>
            {!this.props.loading ? (
              "Confirm"
            ) : (
              <img
                src={spinnerIcon}
                className="screen-wrapper__actions__loader"
                alt="loader"
              />
            )}
          </button>
        </div>
      </>
    );
  }

  renderMeasurementsStep4 = () => {
    const { currentCase } = this.props;
    if (!currentCase) {
      return null;
    }
    const alertMeasurements = prepareMeasurement(currentCase, 4);
    return alertMeasurements.length ? (
      <ScreenWrapper title="Case changes" leftBorderColor="#cbe0ad">
        {alertMeasurements.map((item, index: number) => (
          <div
            key={index}
            className="screen-wrapper__section screen-wrapper__section--noborder screen-wrapper__section--nopadding"
          >
            <div className="screen-wrapper-column screen-wrapper-column--nobottomspacing">
              <label className="screen-wrapper-column__title">
                {item.type}
              </label>
              <p className="screen-wrapper-column__value">
                <div key={index} className="measurement-item">
                  <img
                    src={getMeasurementIconByOldName(item.type)}
                    alt="intervention"
                    className="link__image active"
                  />
                  <div className="screen-wrapper-column screen-wrapper-column--nobottomspacing">
                    <span>{item.column2}</span>
                    <span>{item.values}</span>
                  </div>
                </div>
              </p>
            </div>
          </div>
        ))}
      </ScreenWrapper>
    ) : null;
  };

  renderMeasurementsStep3 = () => {
    const { currentCase } = this.props;
    if (!currentCase) {
      return null;
    }

    const alertMeasurements = prepareMeasurement(currentCase, 3);
    return alertMeasurements.length ? (
      <div className="screen-wrapper__section screen-wrapper__section--noborder screen-wrapper__section--nopadding">
        {alertMeasurements.map((item: MeasurementStepsValue, index: number) => (
          <div
            key={index}
            className="screen-wrapper__section screen-wrapper__section--noborder screen-wrapper__section--nopadding"
          >
            <div className="screen-wrapper-column">
              <p className="screen-wrapper-column__value">
                <div
                  key={index}
                  className="measurement-item measurement-item-nomargin"
                >
                  <img
                    src={getMeasurementIconByOldName(item.type)}
                    alt="intervention"
                    className="link__image active"
                  />
                  <span>{item.values}</span>
                </div>
              </p>
            </div>
          </div>
        ))}
      </div>
    ) : null;
  };

  confirmCase = () => {
    this.updateCase(RedirectType.singleCase);
  };

  handleSelectCloseDocType = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({
      closeDocType: e.target.value as CaseCause,
    });
  };

  handleStayOpenClick = () => {
    if (this.validateScreen()) {
      this.toggleModal();
    } else {
      alert(ErrorText);
    }
  };

  handleSaveButtonClick = () => {
    if (this.validateScreen()) {
      // this.toggleModal();
      this.updateCase(RedirectType.allCases);
    } else {
      alert(ErrorText);
    }
  };

  updateCase = (redirectType: RedirectType) => {
    const { state } = this;
    const measurementsStep4 = {
      step: 4,
      data: groupMeasurementValues(state.measurementsValues),
      date: null,
    };

    if (state.updatedCase) {
      this.setState(
        {
          ...state,
          redirectType,
          updatedCase: {
            ...state.updatedCase,
            status: "close",
            measurements: [measurementsStep4],
          },
        },
        () => {
          if (this.state.updatedCase) {
            delete this.state.updatedCase.holdTo;
            delete this.state.updatedCase.closedAt;
            delete this.state.updatedCase.createdAt;
            this.props.updateCase(this.state.updatedCase);
          }
        }
      );
    }
  };

  updateMeasurementsValues = (val: MeasurementSetValue) => {
    this.setState({
      validate: false,
      measurementsValues: processMeasurementValue(
        val,
        this.state.measurementsValues
      ),
    });
  };

  resetValidation = () => {
    this.setState({ validate: false }, () => this.runValidation());
  };

  runValidation = () => {
    const { selectedMeasurements } = this.state;

    this.setState({
      validate: true,
      measurementsListValid: selectedMeasurements.length > 0,
    });
  };

  validateScreen = (): boolean => {
    this.resetValidation();

    const { measurementsValues, selectedMeasurements } = this.state;

    if (selectedMeasurements.length === 0) {
      return false;
    }

    const measurementsValuesCount = measurementsValues.filter(
      (item: MeasurementSetValue) => !isNaN(item.value)
    ).length;
    let selectedMeasurementsCount = selectedMeasurements.length;
    if (
      selectedMeasurements.find(
        (value: Measurement) =>
          value.type === measurementsOldNames.blood_pressure
      )
    ) {
      selectedMeasurementsCount = selectedMeasurementsCount + 2;
    }

    return (
      selectedMeasurements.length > 0 &&
      measurementsValuesCount === selectedMeasurementsCount
    );
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCase: (data: Case) => dispatch(updateCase(data)),
  resetCaseUpdate: () => dispatch(resetCaseUpdate()),
  dispatch: (data: any) => dispatch(data),
  getCase: (data: GetCaseAction) => dispatch(getCase(data)),
  getChainCase: (id: number) => dispatch(getChainCase(id)),
});

const mapStateToProps = ({
  cases: { sent, view, chain, loading },
}: AppState) => {
  const currentCase = view.data;
  let chainData: Chain[] = [];

  if (currentCase && chain.data) {
    chainData = chain.data.filter(
      (value: Chain) => value.id !== currentCase.id
    );
  }

  return {
    currentCase,
    sent,
    loading,
    caseLoading: view.loading,
    chain: chainData,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleCase)
);
