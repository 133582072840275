import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";
import { IMeasurement } from "../../models/basicModels";

export type { IMeasurement } from "../../models/basicModels";

export type PatientReportChartsType = {
  bloodPressure?: string;
  pulse?: string;
  glucose?: string;
  thermometer?: string;
  bodyScale?: string;
  pulseOximeter?: string;
};

export interface IPatientReports {
  list?: IMeasurement[];
  charts?: PatientReportChartsType;
  error?: any;
  patientId?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  loading?: boolean;
  showNoDataModal?: boolean;
}

const initialState: IPatientReports = {
  list: [],
  charts: {
    bloodPressure: "",
    glucose: "",
    thermometer: "",
    bodyScale: "",
    pulseOximeter: "",
  },
  startDate: null,
  endDate: null,
  error: null,
  loading: false,
  showNoDataModal: false,
};

export default function patientReport(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.patientReport.charts.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.patientReport.charts.success:
      return {
        ...state,
        charts: { ...state.charts, ...action.payload.charts },
        loading: false,
      };
    case actionTypes.patientReport.charts.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.patientReport.data.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.patientReport.data.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.patientReport.data.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.patientReport.modal.reset:
      return {
        ...state,
        showNoDataModal: false,
      };
    case actionTypes.patientReport.reset:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
