import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";
import { MEASUREMENT_TYPES } from "../../models/measuarementTypes";

export interface IHealthGoal {
  patient: string;
  measurementType?: MEASUREMENT_TYPES;
  borderlineMin: number;
  normalMinThreshold: number;
  borderlineHigh: number;
}

export interface IHealthGoals {
  data?: IHealthGoal[];
  error?: any;
  loading?: boolean;
  loadingTable?: boolean;
  count?: number;
}

const initialState: IHealthGoals = {
  data: [],
  error: null,
  loading: false,
  count: 0,
};

export default function healthGoals(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.healthGoals.get.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.healthGoals.get.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.healthGoals.get.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.healthGoals.update.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.healthGoals.update.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.healthGoals.update.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.healthGoals.reset:
      return initialState;
    default:
      return state;
  }
}
