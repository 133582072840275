import { useRef, useEffect, useCallback } from "react";

export function usePortal(selector: string) {
  const rootElemRef = useRef(document.createElement("div"));

  useEffect(
    function setupElement() {
      const parentElem = document.querySelector(selector);
      parentElem?.appendChild(rootElemRef.current);
      const div = rootElemRef?.current;
      return function removeElement() {
        div.remove();
      };
    },
    [selector]
  );

  return rootElemRef.current;
}

export function useCloseOnEsc(onEscape?: () => void) {
  return useCallback(
    (e: React.KeyboardEvent) => {
      if (e.keyCode === 27 && onEscape) {
        onEscape();
      }
    },
    [onEscape]
  );
}

export function useAutoFocus() {
  const ref = useRef<HTMLDivElement | null>(null);
  const isElem = !!ref.current;
  useEffect(() => {
    ref.current?.focus();
  }, [isElem]);
  return ref;
}
