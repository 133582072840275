import React from "react";
import classnames from "classnames";

import "./styles.scss";
import { SELECT_NONE } from "../../../constants/filters";
import { OptionProps } from "../../../models/basicModels";
export interface SelectProps {
  id?: string;
  options: Array<OptionProps>;
  value?: any;
  disabled?: boolean;
  size?: number;
  onChange?: (value: string, selectedIndex?: number) => void;
  onBlur?: (arg: any) => void;
  placeholder?: string;
  useNone?: boolean;
  className?: string;
}

export function Select({
  id,
  options,
  value,
  disabled,
  placeholder,
  useNone,
  className,
  onChange,
  onBlur,
}: SelectProps) {
  const classes = classnames("select", className);
  const handleOnChange = (value: string, selectedIndex?: number) => {
    onChange && onChange(value, selectedIndex);
  };
  return (
    <select
      id={id}
      disabled={disabled}
      name={id}
      value={value === null ? "" : value}
      onBlur={() => {
        onBlur && onBlur(true);
      }}
      onChange={(e) => {
        handleOnChange(e.target.value, e.target.selectedIndex);
      }}
      className={classes}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {options.map(({ name, value, ...rest }, key) => (
        <option key={`${value}${(rest && rest.id) || key}`} value={value}>
          {name}
        </option>
      ))}
      {useNone && (
        <option key={SELECT_NONE} value={SELECT_NONE}>
          {SELECT_NONE}
        </option>
      )}
    </select>
  );
}
Select.defaultProps = { options: [] };
