import actions from "../../constants/actionsTypes";
import { ValuesType } from "../../components/PrimaryCarePhysicianForm/PrimaryCarePhysicianForm.types";

export const addPrimaryCarePhysicianStart = (payload: ValuesType) => ({
  payload,
  type: actions.primaryCarePhysician.add.start,
});
export const addPrimaryCarePhysicianSuccess = () => ({
  type: actions.primaryCarePhysician.add.success,
});

export const addPrimaryCarePhysicianFailure = (error: any) => ({
  type: actions.primaryCarePhysician.add.fail,
  payload: error,
});

export const resetPrimaryCarePhysician = () => ({
  type: actions.primaryCarePhysician.reset,
});
