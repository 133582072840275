export enum DiabeticTriageType {
  medicationsCompliance = "medicationsCompliance",
  dietCompliance = "dietCompliance",
  injectionSites = "injectionSites",
  conditionFeet = "conditionFeet",
}

export const diabeticTriageTitle = (type: DiabeticTriageType): string => {
  switch (type) {
    case DiabeticTriageType.conditionFeet:
      return "Condition of Feet";
    case DiabeticTriageType.dietCompliance:
      return "Diet and Compliance";
    case DiabeticTriageType.injectionSites:
      return "Injection Sites (if on insulin)";
    case DiabeticTriageType.medicationsCompliance:
      return "Medications and Compliance";
  }
};

export const diabeticTriageOrder = (type: DiabeticTriageType): number => {
  switch (type) {
    case DiabeticTriageType.medicationsCompliance:
      return 1;
    case DiabeticTriageType.dietCompliance:
      return 2;
    case DiabeticTriageType.injectionSites:
      return 3;
    case DiabeticTriageType.conditionFeet:
      return 4;
  }
};

export enum AbnormalVitalType {
  SBP = "SBP",
  Temp = "Temp",
  BloodSugar = "Blood Sugar",
  DBP = "DBP",
  RR = "RR",
  Pulse = "Pulse",
  Oxygen = "Oxygen",
}

export const dAbnormalVitalTypeOrder = (type: AbnormalVitalType): number => {
  switch (type) {
    case AbnormalVitalType.SBP:
      return 1;
    case AbnormalVitalType.DBP:
      return 2;
    case AbnormalVitalType.Pulse:
      return 3;
    case AbnormalVitalType.Temp:
      return 4;
    case AbnormalVitalType.RR:
      return 5;
    case AbnormalVitalType.Oxygen:
      return 6;
    case AbnormalVitalType.BloodSugar:
      return 7;
  }
};

export const AbnormalVitalSignsDefaultValues: AbnormalVitalSignsValue[] = [
  {
    key: AbnormalVitalType.SBP,
    value: -1,
    order: 1,
  },
  {
    key: AbnormalVitalType.Temp,
    value: -1,
    order: 4,
  },
  {
    key: AbnormalVitalType.BloodSugar,
    value: -1,
    order: 1,
  },
  {
    key: AbnormalVitalType.DBP,
    value: -1,
    order: 1,
  },
  {
    key: AbnormalVitalType.RR,
    value: -1,
    order: 1,
  },
  {
    key: AbnormalVitalType.Pulse,
    value: -1,
    order: 1,
  },
  {
    key: AbnormalVitalType.Oxygen,
    value: -1,
    order: 1,
  },
];

export interface AbnormalVitalSignsValue {
  key: AbnormalVitalType;
  value: number;
  order: number;
}

export interface AbnormalVitalSignsModel {
  title: string;
  values: string[]; //{value1: string, value2: string, value3: string | undefined}
}

export const AbnormalVitalSigns: AbnormalVitalSignsModel[] = [
  {
    title: AbnormalVitalType.SBP,
    values: ["<90", "Normal", ">180"],
  },
  {
    title: AbnormalVitalType.Temp,
    values: ["<97", "Normal", ">99.1"],
  },
  {
    title: AbnormalVitalType.BloodSugar,
    values: ["<65", "Normal", ">350"],
  },
  {
    title: AbnormalVitalType.DBP,
    values: ["<50", "Normal", ">100"],
  },
  {
    title: AbnormalVitalType.RR,
    values: ["<12", "Normal", ">30"],
  },
  {
    title: AbnormalVitalType.Pulse,
    values: ["<50", "Normal", ">100"],
  },
  {
    title: AbnormalVitalType.Oxygen,
    values: ["<88%", "Other", ""],
  },
];

export enum PatientsSymptoms {
  ExcessiveThirst = "Excessive Thirst",
  ChestPain = "Chest pain",
  NumbnessTingling = "Numbness and tingling (hands and/or feet)",
  ExcessiveUrination = "Excessive urination",
  UnintentionalWeight = "Unintentional weight loss (10lbs or greater)",
  NonhealingWorseningWounds = "Non-healing or worsening wounds",
  ExcessiveSweating = "Excessive sweating",
  VisualDisturbances = "Visual disturbances (blurred vision)",
  ConfusionDisorientation = "Confusion/disorientation",
}
