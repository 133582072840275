import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetDevices } from "../store/actions/devices";

export default function useResetDevices() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetDevices());
    };
  }, [dispatch]);
}
