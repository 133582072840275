import { MEASUREMENT_TYPES } from "../models/measuarementTypes";
import measurementsOldNames from "../constants/measurementsOldNames";

export default function getMeasurementIconByMeasurementType(
  type: MEASUREMENT_TYPES
) {
  if (
    type === MEASUREMENT_TYPES.BLOOD_PRESSURE_DIASTOLIC ||
    type === MEASUREMENT_TYPES.BLOOD_PRESSURE_PULSE ||
    type === MEASUREMENT_TYPES.BLOOD_PRESSURE_SYSTOLIC ||
    type === MEASUREMENT_TYPES.SUMMARY_BP
  ) {
    return require("../assets/images/measurements/ic-blood-pressure-gauge.svg").default;
  }
  if (type === MEASUREMENT_TYPES.BLOOD_GLUCOSE_BG) {
    return require("../assets/images/measurements/ic-group-15.svg").default;
  }
  if (type === MEASUREMENT_TYPES.BODY_SCALE) {
    return require("../assets/images/measurements/ic-weight-scale.svg").default;
  }
  if (type === MEASUREMENT_TYPES.PULSE_OXIMETER) {
    return require("../assets/images/ic_oximeter.svg").default; //ic_oximeter.svg
  }
  if (type === MEASUREMENT_TYPES.THERMOMETER) {
    return require("../assets/images/measurements/ic-group-12.svg").default;
  }
}

export const getMeasurementIconByOldName = (type: measurementsOldNames) => {
  switch (type) {
    case measurementsOldNames.blood_pressure:
      return require("../assets/images/measurements/ic-blood-pressure-gauge.svg")
        .default;
    case measurementsOldNames.ecg:
      return require("../assets/images/measurements/ic-group-17.svg").default;
    case measurementsOldNames.glucose:
      return require("../assets/images/measurements/ic-group-15.svg").default;
    case measurementsOldNames.scale:
      return require("../assets/images/measurements/ic-weight-scale.svg")
        .default;
    case measurementsOldNames.temperature:
      return require("../assets/images/measurements/ic-group-12.svg").default;
  }
};
