
import { ISummaryReport, IReport } from "../store/reducers/summaryReport";



export default function sortSummaryReport(list: IReport[], sortBy: ISummaryReport["sortBy"]) {
  const newList = [...list];
  const isDescending = sortBy?.includes("-");
  const direction = sortBy?.replace("-", "") as ISummaryReport["sortBy"];
  if (!sortBy) {
    return list;
  }
  if (isDescending) {
    return newList.sort((a, b) => {
      //@ts-ignore
      if (direction && a[direction] < b[direction]) {
        return 1;
      }
      //@ts-ignore
      if (direction && a[direction] > b[direction]) {
        return -1;
      }
      return 0;
    });
  }
  return newList.sort((a, b) => {
    //@ts-ignore
    if (direction && a[direction] > b[direction]) {
      return 1;
    }
    //@ts-ignore
    if (direction && a[direction] < b[direction]) {
      return -1;
    }
    return 0;
  });
}