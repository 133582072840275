export function getDefaultStartDate() {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return date.toISOString();
}

export function getDefaultEndDate() {
  const date = new Date();
  date.setHours(23, 50, 59);
  return date.toISOString();
}
