import React, { memo } from "react";
import classnames from "classnames";
import IdleTimer from "react-idle-timer";

import { useHeaderContext } from "../../context/header";
import Header from "../Header";
import Content from "../Content";
import Navigation from "../Navigation";
import PrintHeader from "../Header/PrintHeader";
import Modal from "../Modal";
import TimerModal from "../TimerModal";
import Toaster from "../Toaster";
import { useIdleHandler, useModal } from "./App.hooks";
import { useAuth, useSettings } from "../../hooks";

import "./App.styles.scss";

function App() {
  const { header, printHeader, navigation } = useSettings();
  const { token } = useAuth();
  const { showHeaderBlock } = useHeaderContext();
  const isAuth = token?.length;
  const { setShowModal, showModal, toggleModal } = useModal();
  const onIdle = useIdleHandler(setShowModal);

  const appClasses = classnames("application  relative md:static", {
    "application--print": printHeader,
  });

const showHeader = (isAuth && header) || (showHeaderBlock && header);
 
   
  return (
    <>
      <IdleTimer element={document} onIdle={onIdle} timeout={1000 * 60 * 14} />
      <div className={appClasses}>
        {isAuth && navigation && <Navigation />}
        {printHeader && <PrintHeader />}
        <Content>{showHeader && <Header />}</Content>
      </div>
      <Modal
        isDisabled={!showModal}
        title="Session timeout"
        onClose={toggleModal}
      >
        {showModal && <TimerModal closeModal={toggleModal} />}
      </Modal>
      <Toaster />
    </>
  );
}

export default memo(App);
