import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";

export interface IForgotState {
  done: boolean;
  loading: boolean;
  error?: string;
}

const initialState: IForgotState = {
  done: false,
  loading: false,
  error: undefined,
};

export default function forgot(
  state: IForgotState = initialState,
  action: Action
) {
  switch (action.type) {
    case actionTypes.forgot.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.forgot.success:
      return {
        ...state,
        done: true,
        loading: false,
      };
    case actionTypes.forgot.fail:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
