import React from "react";
import { ChildData } from "react-router-navigation-prompt";

import Modal from "../Modal";
import { Button } from "../Form/Button";

interface IProps extends ChildData {
  title?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  message?: string;
  className?: string;
}

export default function ConfirmationModal({
  onCancel,
  onConfirm,
  isActive,
  title,
  cancelButtonText,
  confirmButtonText,
  message,
  className,
}: IProps) {
  return (
    <Modal
      onClose={onCancel}
      title={title || ""}
      isDisabled={!isActive}
      classNames={{ body: className || "" }}
    >
      <div className="">
        <p className="text-sm mt-1 leading-4">{message}</p>
        <div className="flex justify-end mt-5">
          <Button
            value={cancelButtonText}
            type="default"
            onClick={onCancel}
            className="mr-5"
          />
          <Button
            value={confirmButtonText}
            type="primary"
            onClick={onConfirm}
          />
        </div>
      </div>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  title: "",
  cancelButtonText: "",
  confirmButtonText: "",
  message:
    "",
};