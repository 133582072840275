import React, { memo } from "react";

import { Button } from "../Form/Button";
import { useSettings } from "../../hooks";
import { usePrintHandler } from "./Header.hooks";

import "./Header.styles.scss";

function PrintHeader() {
  const { printName } = useSettings();
  const onClick = usePrintHandler();
  return (
    <div className="header header--print">
      <div className="print-name">{printName}</div>
      <Button value="Download" type="primary" onClick={onClick} />
    </div>
  );
}

export default memo(PrintHeader);
