import React, { ReactNodeArray, useCallback, useEffect } from "react";

import { useHistory } from "react-router";
import moment from "moment";

import { useHeaderContext, ACTION_NAMES } from "../context/header";
import HeaderBlock from "../components/HeaderBlock/HeaderBlock.component";
import HeaderBlockContent from "../components/HeaderBlockContent";
import { usePatient } from "../hooks";

interface IProps {
  title: string;
  children: React.ReactNode | ReactNodeArray;
}

export default function WithBackButtonHeader({ title, children }: IProps) {
  const { setHeader } = useHeaderContext();
  const patient = usePatient();
  const history = useHistory();
  const onArrowClick = useCallback(() => {
    history.goBack();
  }, [history]);
  useEffect(() => {
    setHeader({
      type: ACTION_NAMES.SET_HEADER_BLOCK,
      payload: (
        <HeaderBlock onArrowClick={onArrowClick}>
          <HeaderBlockContent title={<span className="mr-2">{title}</span>}>
            <span className="mr-2">{`${patient?.firstName} ${patient?.lastName}`}</span>
            <span>
              {!!patient?.dob && moment(patient?.dob).format("MM-DD-YYYY")}
            </span>
          </HeaderBlockContent>
        </HeaderBlock>
      ),
    });
    return () =>
      setHeader({ type: ACTION_NAMES.SET_HEADER_BLOCK, payload: null });
  }, [setHeader, onArrowClick, patient, title]);
  return <>{children}</>;
}
