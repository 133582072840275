import React, { useEffect, useState, useCallback } from "react";

export function useModal() {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal, setShowModal]);
  return { showModal, toggleModal, setShowModal };
}

export function useManageModal(
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  isSuccess: boolean
) {
  useEffect(() => {
    if (isSuccess) {
      setShowModal(true);
    }
  }, [isSuccess, setShowModal]);
}
