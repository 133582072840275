function testWhite(x:string) {
  var white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
}
// https://stackoverflow.com/questions/14484787/wrap-text-in-javascript
export default  function wordWrap(str: string, maxWidth: number) {
  const newLineStr = "\n";
  let res = "";
  let found;
  while (str.length > maxWidth) {
    found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join("");
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join("");
      str = str.slice(maxWidth);
    }
  }

  return res + str;
}
