import * as yup from "yup";

import { FIELD_NAMES } from "./ResetPasswordModal.constants";

export default function validationSchema() {
  return yup.object().shape({
    [FIELD_NAMES.PASSWORD]: yup.string().required("Password is required"),
    [FIELD_NAMES.NEW_PASSWORD]: yup
      .string()
      .required()
      .min(8, "Password must be at least eight characters long")
      .matches(/[a-z]/, "Must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Must contain at least one uppercase letter")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Must contain at least one number and one special symbol"
      ),
    [FIELD_NAMES.REPEAT_PASSWORD]: yup
      .string()
      .required("repeat password is required")
      .oneOf([yup.ref(FIELD_NAMES.NEW_PASSWORD)], "passwords must match"),
  });
}
