import React, { cloneElement, ReactNode } from "react";

import "./styles.scss";
import Input, { InputProps } from "../Input";
import TextArea from "../TextArea";
import classnames from "classnames";

export interface Props extends InputProps {
  name: string;
  label?: string | ReactNode;
  multiline?: boolean;
  maxLength?: number;
  type: string;
  rows?: number;
  error?: any;
  notValid?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: string;
  touched?: any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  children?: React.ReactElement;
  insideLabel?: string;
  classNames?: {
    label?: string
    input?: string
  }
}

export function TextField({ insideLabel, multiline, ...rest }: Props) {
  const { id, label, rows, className, error, notValid, children } = rest;

  let Component = Input;

  if (multiline || rows) {
    Component = TextArea;
  }

  const containerClasses = classnames(
    "text__wrapper",
    "relative",
    className,
    error?.length && "text__wrapper__error"
  );
  const labelClasses = classnames("text__label", { disabled: rest.disabled }, rest.classNames?.label);

  const componentClasses = classnames(
    notValid || error ? "text__error_input" : "text__input",
    { disabled: rest.disabled },
    rest.classNames?.input
  );
  return (
    <div className={containerClasses}>
      {label && <label className={labelClasses}>{label}</label>}
      {!children && (
        <Component
          {...rest}
          className={componentClasses}
        />
      )}
      <div className="flex relative">
        {insideLabel && (
          <div className="text__inside-label absolute">{insideLabel}</div>
        )}
      </div>

      {children && cloneElement(children, { className: componentClasses })}
      {error && (
        <label htmlFor={id} className="text__error">
          {error}
        </label>
      )}
    </div>
  );
}
