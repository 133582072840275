import actions from "../../constants/actionsTypes";
import {
  PatientReportChartsType,
  IMeasurement,
  IPatientReports,
} from "../reducers/patientReport";

export interface IPatientReportPayload extends PatientReportChartsType {}

export const setPatientReportChartsStart = (
  payload: IPatientReportPayload
) => ({
  type: actions.patientReport.charts.start,
  payload: { charts: payload },
});

export const setPatientReportChartsSuccess = (
  payload: IPatientReportPayload
) => ({
  type: actions.patientReport.charts.success,
  payload: { charts: payload },
});

export const setPatientReportChartsFail = (error: string) => ({
  type: actions.patientReport.charts.fail,
  payload: error,
});

export const getPatientReportDataStart = (payload: {
  patientId: string;
  startDate?: Date | null;
  endDate?: Date | null;
}) => ({
  type: actions.patientReport.data.start,
  payload,
});

export const getPatientReportDataSuccess = (payload: IPatientReports) => ({
  type: actions.patientReport.data.success,
  payload,
});

export const getPatientReportDataFailure = (payload: IMeasurement[]) => ({
  type: actions.patientReport.data.fail,
  payload: { error: payload },
});

export const resetPatientReportModal = () => ({
  type: actions.patientReport.modal.reset,
});

export const resetPatientReport = () => ({
  type: actions.patientReport.reset,
});
