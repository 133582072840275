import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";
import { IDevice } from "../../models/basicModels";

export interface IPatientDevices {
  list: IDevice[];
  error?: any;
  loading?: boolean;
}

const initialState: IPatientDevices = {
  list: [],
  error: null,
  loading: false,
};

export default function Devices(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.patientDevices.get.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.patientDevices.get.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.patientDevices.get.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.patientDevices.add.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.patientDevices.add.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.patientDevices.add.fail:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionTypes.patientDevices.reset:
      return {
        ...initialState,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
}
