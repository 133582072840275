import { ITimerData, ITimer } from "../../models/basicModels";

interface IInitial extends Omit<ITimerData, "note"> {
  note: string | null;
}

export function builInitialValues(
  taskTimer: ITimer,
  isEditing: boolean
): IInitial {
  if (!isEditing) {
    return {
      startTime: taskTimer.startTimeHistory[0]?.toISOString() || null,
      note: taskTimer.data.note?.message || null,
      endTime: taskTimer.endTime,
      duration: taskTimer.duration || 0,
      taskType: "",
    };
  }
  return {
    ...taskTimer.data,
    note: taskTimer.data.note?.message || "",
    duration: taskTimer.data.duration || 0,
  };
}
