import React, { memo, ReactNodeArray } from "react";

import { useTaskTimer, useStartTimerHandler, usePushModal } from "../hooks";

import NavigationPrompt, {
  NavigationModalProps,
} from "../components/NavigationPrompt";

import { useManagetaskTimer, useShowHandler, useOnConfirm } from "./hoc.hooks";

interface IProps {
  children: React.ReactNode | ReactNodeArray;
  Modal: React.FunctionComponent<NavigationModalProps>;
  PushModal?: React.FunctionComponent<NavigationModalProps>;
  allowRoutes: string[];
  modalProps: {
    title: string;
    message: string;
    cancelButtonText: string;
    confirmButtonText: string;
    className?: string;
  };
  pushModalProps?: {
    title: string;
    message: string;
    cancelButtonText: string;
    confirmButtonText: string;
    className?: string;
  };
}

function WithNavigationTaskTimerPrompt({
  children,
  Modal,
  modalProps,
  allowRoutes,
  PushModal,
  pushModalProps,
}: IProps) {
  const { startTimeHistory } = useTaskTimer();
  const show = startTimeHistory.length;
  const showCb = useShowHandler(allowRoutes, !!show);
  useManagetaskTimer();
  const { pushModal, togglePushIsOpen } = usePushModal(!!PushModal);
  const onStartTimer = useStartTimerHandler(togglePushIsOpen);
  const onConfirm = useOnConfirm()
  return (
    <>
      <NavigationPrompt when={showCb}>
        {({ ...rest }) => (
          <Modal
            {...rest}
            {...modalProps}
            className={modalProps.className}
            onConfirm={onConfirm(rest.onCancel)}
          />
        )}
      </NavigationPrompt>
      {PushModal && !show && (
        <PushModal
          isActive={!!pushModal}
          className={modalProps.className}
          onConfirm={onStartTimer}
          onCancel={togglePushIsOpen}
          {...pushModalProps}
        />
      )}
      {children}
    </>
  );
}

WithNavigationTaskTimerPrompt.defaultProps = {
  isPushModal: false,
};

export default memo(WithNavigationTaskTimerPrompt);
