import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";
import TIMER_STATES from "../../constants/timerStates";
import { ITimer } from "../../models/basicModels";
export type { ITimer, ITimerData } from "../../models/basicModels";

const initialState: ITimer = {
  startTimeHistory: [],
  showTimerPanel: false,
  showForm: false,
  patient: null,
  state: TIMER_STATES.INITIAL,
  isEditing: false,
  endTime: null,
  duration: null,
  data: {
    startTime: null,
    endTime: null,
    patient: null,
    duration: 0,
    taskType: null,
    note: null,
    createdDate: null,
  },

  loading: false,
  error: null,
};

export default function taskTimer(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.taskTimer.timer.start:
      return {
        ...state,
        ...action.payload,
        data: { ...state.data, ...action.payload.data },
      };
    case actionTypes.taskTimer.timer.stop:
      return {
        ...state,
        ...action.payload,
        data: { ...state.data, ...action.payload.data },
      };
    case actionTypes.taskTimer.timer.pause:
      return {
        ...state,
        ...action.payload,
        data: { ...state.data, ...action.payload.data },
      };
    case actionTypes.taskTimer.timer.reset:
      return {
        ...initialState,
        data: state.data,
      };
    case actionTypes.taskTimer.timer.resetData:
      return {
        ...state,
        data: {
          startTime: state.data.startTime,
          endTime: state.data.endTime,
          duration: state.data.duration,
          note: "",
          taskType: null,
        },
      };
    case actionTypes.taskTimer.add.start:
      return {
        ...state,
        ...action.payload,
        loading: true,
        data: { ...state.data, ...action.payload.data },
      };
    case actionTypes.taskTimer.add.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
        data: { ...state.data, ...action.payload.data },
      };
    case actionTypes.taskTimer.add.fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.taskTimer.update.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.taskTimer.update.success:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.taskTimer.update.fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.taskTimer.panel.show:
      return {
        ...state,
        showTimerPanel: true,
      };
    case actionTypes.taskTimer.panel.hide:
      return {
        ...state,
        showTimerPanel: false,
      };
    case actionTypes.taskTimer.form.show:
      return {
        ...state,
        ...action.payload,
        showForm: true,
      };
    case actionTypes.taskTimer.form.hide:
      return {
        ...state,
        showForm: false,
      };
    case actionTypes.taskTimer.get.start:
      return {
        ...state,
        ...action.payload,
        loading: true,
        data: { ...state.data, ...action.payload.data },
      };
    case actionTypes.taskTimer.get.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
        data: { ...state.data, ...action.payload.data },
      };
    case actionTypes.taskTimer.get.fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.taskTimer.form.set:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.taskTimer.resetStarted:
      return {
        ...initialState,
        showTimerPanel: state.showTimerPanel,
        data: {
          ...state.data,
        },
      };
    case actionTypes.taskTimer.reset:
      return initialState;
    default:
      return state;
  }
}
