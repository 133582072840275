export function splitArray(originalArray: any[]) {
  if (!originalArray) {
    return [[], []];
  }

  const tempArray = [...originalArray];
  let halfWayThough = Math.ceil(tempArray.length / 2);
  let arrayFirstHalf = tempArray.slice(0, halfWayThough);
  let arraySecondHalf = tempArray.slice(halfWayThough, tempArray.length);

  return [arrayFirstHalf, arraySecondHalf];
}
