import axios from "axios";
import { put, call, delay } from "redux-saga/effects";
import { createSelector } from "reselect";
import snakeCaseKeys from "snakecase-keys";

import { successNotify, errorNotify } from "../../components/Toaster/toster";
import { recognizeErrorMessage } from "../../utils/errors";
import actions from "../../constants/actionsTypes";
import { setAuthorizationHeader } from "../../utils/axios";
import { AppState } from "../reducers";
import { Action } from "../reducers";
import {
  API_FORGOT,
  API_CHECK_TOKEN,
  API_RESET,
  API_RESET_FORGOTTEN_TOKEN,
} from "../../constants/urls";

import {
  setResetTokenFail,
  setResetTokenSuccess,
  resetForgottenPasswordStart,
  resetForgottenPasswordSuccess,
  resetForgottenPasswordFail,
  setResetTokenStart,
} from "../actions/resetPassword";

const LOCALSTORAGE_TOKEN = "success_token";

export const resetPasswordSelector = createSelector(
  ({ resetPassword }: AppState) => resetPassword,
  (resetPassword) => resetPassword
);

export function* resetPasswordSaga(action: Action) {
  const {
    password,
    rePassword,
    newPassword,
    closeModal,
    resetForm,
  } = action.payload;
  const dataPayload = snakeCaseKeys({
    password,
    newPassword,
    rePassword,
  });
  try {
    const { data } = yield axios.post(API_RESET, dataPayload);
    if (!data.error) {
      yield call(setAuthorizationHeader, data.result.token);
      yield put({
        type: actions.changePassword.resetPassword.success,
        payload: {
          ...data,
        },
      });
      yield localStorage.setItem(LOCALSTORAGE_TOKEN, data.result.token);
      closeModal();
      resetForm({});
      successNotify("Success!");
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    yield put({
      type: actions.changePassword.resetPassword.fail,
      payload: recognizeErrorMessage(error),
    });
    errorNotify(recognizeErrorMessage(error));
  }
  yield delay(5000);
  yield put({
    type: actions.changePassword.resetPassword.reset_error,
  });
}

export function* forgotPasswordSaga(action: Action) {
  try {
    const { data } = yield axios.post(API_FORGOT, action.payload);
    if (!data.error) {
      yield put({
        type: actions.forgot.success,
        payload: {
          ...data,
        },
      });
      successNotify("Success!");
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    yield put({
      type: actions.forgot.fail,
      payload: recognizeErrorMessage(error),
    });
    errorNotify(recognizeErrorMessage(error));
  }
}

export function* resetForgotenPasswordSaga({
  payload: { password, token },
}: ReturnType<typeof resetForgottenPasswordStart>) {
  try {
    yield axios.post(`${API_RESET_FORGOTTEN_TOKEN}${token}`, {
      token: token,
      password: password,
    });
    yield put(resetForgottenPasswordSuccess({ isReseted: true }));
    successNotify("Password changed success");
  } catch (error) {
    yield put(resetForgottenPasswordFail(error));
    errorNotify(error.message);
  }
}

export function* setResetTokenSaga({
  payload,
}: ReturnType<typeof setResetTokenStart>) {
  try {
    const { data } = yield axios.get(`${API_CHECK_TOKEN}${payload}`);
    if (data?.error) {
      return yield put(
        setResetTokenSuccess({ isTokenVerified: false, tokenExpired: true })
      );
    }
    yield put(
      setResetTokenSuccess({ isTokenVerified: true, tokenExpired: false })
    );
  } catch (error) {
    yield put(setResetTokenFail(error));
    errorNotify(error.message);
  }
}
