import { RouterProps } from "react-router";

import actions from "../../constants/actionsTypes";
import { IPatientPayload } from ".././../components/PatientForm/PatientForm.types";
import { PatientStore } from "../../store/reducers/patient";
export interface PatientAction {
  uuid: string;
}

export interface IAddPatientPayload extends IPatientPayload, RouterProps {}
export interface IUpdatePatientPayload extends IPatientPayload {}

export const getPatient = (payload: PatientAction) => ({
  type: actions.patient.get,
  payload,
});

export const getPatientSuccess = (payload: { data: IPatientPayload }) => ({
  type: actions.patient.success,
  payload,
});

export const addPatientStart = (payload: IAddPatientPayload) => ({
  type: actions.patient.add.start,
  payload,
});
export const addPatientSuccess = () => ({
  type: actions.patient.add.success,
});

export const addPatientFail = (payload: any) => ({
  type: actions.patient.add.fail,
  payload,
});

export const resetPatient = () => ({ type: actions.patient.reset });

export const updatePatientStart = (payload: IUpdatePatientPayload) => ({
  type: actions.patient.update.start,
  payload,
});

export const updatePatientSuccess = () => ({
  type: actions.patient.update.success,
});

export const updatePatientFail = (payload: any) => ({
  type: actions.patient.update.fail,
  payload,
});
export const getCityStateByZipStart = (payload: {
  [key: string]: string | number;
}) => ({
  type: actions.patient.getCityState.start,
  payload,
});
export const getCityStateByZipSuccess = (
  payload: PatientStore["cityStateByZip"]
) => ({
  type: actions.patient.getCityState.success,
  payload,
});
export const getCityStateByZipFail = (payload: any) => ({
  type: actions.patient.getCityState.fail,
  payload,
});
