export enum FIELD_NAMES {
  PASSWORD = "password",
  NEW_PASSWORD = "newPassword",
  REPEAT_PASSWORD = "rePassword",
}

export const INITIAL_VALUES = {
  [FIELD_NAMES.PASSWORD]: "",
  [FIELD_NAMES.NEW_PASSWORD]: "",
  [FIELD_NAMES.REPEAT_PASSWORD]: "",
};
