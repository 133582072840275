import { useCallback, useMemo, useEffect, MutableRefObject } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormikHelpers } from "formik";

import { ValuesType } from "./TaskTimerForm.types";
import {
  addTaskTimerStart,
  hideTaskTimerForm,
  showTaskTimer,
  updateTaskTimerStart,
  resetTaskTimerData,
  resetTaskTimer,
  resetStartedTaskTimer
} from "../../store/actions/taskTimer";
import { getTasksTimersStart } from "../../store/actions/tasksTimers";
import { useTaskTimer } from "../../hooks";
import { usePatient } from "../../hooks";
import { builInitialValues } from "./TaskTimerForm.utils";
import {
  getDefaultStartDate,
  getDefaultEndDate,
} from "../../utils/getDefaultTasksTimersDateParam";
import { taskTimerSelector } from "../../store/sagas/taskTimer";

export function useSubmitHandler() {
  const dispatch = useDispatch();
  const { uuid } = usePatient();
  const taskTimer = useTaskTimer();
  return useCallback(
    (form: ValuesType, helpers: FormikHelpers<ValuesType>) => {
      const onSuccess = (resetCb: () => void) => {
        dispatch(resetCb());
        dispatch(showTaskTimer());
        dispatch(hideTaskTimerForm());
        dispatch(
          getTasksTimersStart({
            startDate: getDefaultStartDate(),
            endDate: getDefaultEndDate(),
          })
        );
        helpers.resetForm();
      };
      if (taskTimer.isEditing) {
        // it is update
        dispatch(
          updateTaskTimerStart({
            ...form,
            patient: uuid,
            onSuccess: () => {
              onSuccess(resetTaskTimerData);
            },
            note: { ...taskTimer.data.note, message: form.note || "" },
          })
        );
      } else {
        dispatch(
          addTaskTimerStart({
            ...form,
            patient: uuid,
            onSuccess: () => {
              onSuccess(resetTaskTimer);
            },
          })
        );
      }
    },
    [dispatch, uuid, taskTimer.data.note, taskTimer.isEditing]
  );
}

export function useInitialValues(isEditing: boolean) {
  const taskTimer = useTaskTimer();

  return builInitialValues(taskTimer, isEditing);
}

export function useYesterdayDate() {
  return useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }, []);
}

export function useCloseModal() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(hideTaskTimerForm());
  }, [dispatch]);
}

export function useErrors(
  formikRef: MutableRefObject<FormikHelpers<ValuesType> | undefined>
) {
  const errors: { [key: string]: string[] } = useSelector(taskTimerSelector)
    ?.error;
  const transformedErrors = useMemo(() => {
    if (errors) {
      return Object.entries(errors).reduce((result, [key, value]) => {
        result[key] = Array.isArray(value) ? value.join(", ") : value;
        return result;
      }, {} as { [key: string]: string });
    }
    return null;
  }, [errors]);
  useEffect(() => {
    if (transformedErrors) {
      formikRef.current?.setErrors(transformedErrors); //this use because of submitting count, if it is higher than 2, then initial Errors don't update error object
    }
  }, [transformedErrors, formikRef]);
  return transformedErrors;
}

export function useCloseResetHandler() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(resetStartedTaskTimer());
  }, [dispatch]);
}
