import { combineReducers, Reducer } from "redux";

import auth, { AuthState } from "./auth";
import actions from "../../constants/actionsTypes";
import patients, { PatientsState } from "./patients";
import cases, { CasesStorageState } from "./cases";
import forgot, { IForgotState } from "./forgot";
import resetPassword, { ResetPasswordState } from "./resetPassword";
import settings, { SettingsStorageState } from "./settigns";
import patient, { PatientStore } from "./patient";
import searchPanelFilters, {
  SearchPanelStorageState,
} from "./searchPanelFilters";
import clients, { IClients } from "./clients";
import physicians, { IPhysicians } from "./physicians";
import devices, { IDevices } from "./devices";
import patientDevices, { IPatientDevices } from "./patientDevices";
import summaryReport, { ISummaryReport } from "./summaryReport";
import patientReport, { IPatientReports } from "./patientReport";
import primaryCarePhysician, {
  IPrimaryCarePhysician,
} from "./primaryCarePhysician";
import notifications, { INotifications } from "./notifications";
import notes, { INotes } from "./notes";
import healthGoals, { IHealthGoals } from "./healthGoals";
import pcpRate, { IPcpRate } from "./pcpRate";
import vitalAlerts, { IVitalAlerts } from "./vitalAlerts";
import taskTimer, { ITimer } from "./taskTimer";
import tasksTimers, { ITimers } from "./tasksTimers";

export interface Action {
  type: string;
  payload?: any;
}

export interface AppState {
  auth: AuthState;
  forgot: IForgotState;
  resetPassword: ResetPasswordState;
  patients: PatientsState;
  cases: CasesStorageState;
  settings: SettingsStorageState;
  patient: PatientStore;
  searchPanelFilters: SearchPanelStorageState;
  clients: IClients;
  physicians: IPhysicians;
  devices: IDevices;
  patientDevices: IPatientDevices;
  summaryReport: ISummaryReport;
  patientReport: IPatientReports;
  primaryCarePhysician: IPrimaryCarePhysician;
  notifications: INotifications;
  healthGoals: IHealthGoals;
  notes: INotes;
  pcpRate: IPcpRate;
  vitalAlerts: IVitalAlerts;
  taskTimer: ITimer;
  tasksTimers: ITimers
}

const appReducer: Reducer<AppState, any> = combineReducers<AppState>({
  auth,
  forgot,
  resetPassword,
  patients,
  cases,
  patient,
  settings,
  searchPanelFilters,
  clients,
  physicians,
  devices,
  patientDevices,
  summaryReport,
  patientReport,
  primaryCarePhysician,
  notifications,
  healthGoals,
  notes,
  pcpRate,
  vitalAlerts,
  taskTimer,
  tasksTimers,
});

const rootReducer: Reducer<AppState, any> = (state, action) => {
  if (action.type === actions.logout.start) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
