import React from "react";
import "./styles.scss";
import MeasurementsItem from "./Item";
import { Measurement } from "../../models/basicModels";

interface Props {
  measurements: Measurement[];
  onMeasurementSelected?: (measurements: Measurement[]) => void;
  disabled: boolean;
}

interface State {
  selectedMeasurements: Measurement[];
}

class MeasurementsList extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
  };

  state = {
    selectedMeasurements: [],
  };

  render(): React.ReactNode {
    const { measurements, disabled } = this.props;

    return (
      <div style={{ opacity: disabled ? 0.6 : 1 }} className="measurement_list">
        {measurements.map((item: Measurement, index: number) => (
          <MeasurementsItem
            key={index}
            measurement={item}
            disabled={disabled}
            selected={this.isMeasurementSelected(item)}
            onChange={this.handleSelectMeasurements}
          />
        ))}
      </div>
    );
  }

  handleSelectMeasurements = (item: Measurement): void => {
    const addedMeasurements: Measurement[] = this.state.selectedMeasurements.slice();
    const foundIndex: number = addedMeasurements.findIndex(
      (measure) => measure.type === item.type
    );
    if (foundIndex >= 0) {
      addedMeasurements.splice(foundIndex, 1);
    } else {
      addedMeasurements.push(item);
    }

    const sortedMeasurements: Measurement[] = addedMeasurements.sort(function (
      a,
      b
    ) {
      return a.orderNumber - b.orderNumber;
    });

    this.setState({
      selectedMeasurements: sortedMeasurements,
    });

    const { onMeasurementSelected } = this.props;

    onMeasurementSelected && onMeasurementSelected(sortedMeasurements);
  };

  isMeasurementSelected = (measurement: Measurement): boolean => {
    const { selectedMeasurements } = this.state;
    return (
      selectedMeasurements.findIndex(
        (item: Measurement) => item.type === measurement.type
      ) >= 0
    );
  };
}

export default MeasurementsList;
