import React from "react";
import { Page, View, Document, Image, Text, Font } from "@react-pdf/renderer";
import moment from "moment";

import { ISummaryReport } from "../../../store/reducers/summaryReport";
import ReportHeader from "../../../../src/assets/images/reports/header.jpg";
//@ts-ignore
import openSansReqular from "../../../assets/fonts/Open_Sans/OpenSans-Regular.ttf";
//@ts-ignore
import openSansBold from "../../../assets/fonts/Open_Sans/OpenSans-Bold.ttf";
//@ts-ignore
import openSansSemiBold from "../../../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf";
import calculatePts from "../../../utils/calculatePts";
import styles from "./pdf.styles";
import TableBlock from "./TableBlock.component";
import { IReportItem } from "../../../models/basicModels";

Font.register({
  family: "Open Sans",
  fonts: [
    { src: openSansReqular },
    { src: openSansBold, fontWeight: "bold" },
    { src: openSansSemiBold, fontWeight: "semibold" },
  ],
});

const FORMAT = "MM/DD/YYYY";

interface IProps extends Partial<Omit<ISummaryReport, "list">> {
  list: IReportItem[];
  client: string;
}

function SummaryReportPDF({
  client,
  createDateGte,
  createDateLte,
  list,
}: IProps) {
  return (
    <Document>
      <Page size="A4" wrap>
        <Image src={ReportHeader} fixed />
        <View style={styles.content}>
          <Text style={styles.title}>Summary Report</Text>
          <View style={styles.descriptionBlock}>
            <Text style={styles.subtitle}>Client:</Text>
            <Text
              style={[
                styles.text,
                {
                  marginLeft: calculatePts(12),
                },
              ]}
            >
              {client}
            </Text>
            <Text
              style={[
                styles.subtitle,
                {
                  marginLeft: calculatePts(67),
                },
              ]}
            >
              Period:
            </Text>
            <Text
              style={[
                styles.text,
                {
                  marginLeft: calculatePts(12),
                },
              ]}
            >
              {moment(createDateGte).format(FORMAT)} -{" "}
              {moment(createDateLte).format(FORMAT)}
            </Text>
          </View>
          <TableBlock list={list || []} />
        </View>
      </Page>
    </Document>
  );
}

export default SummaryReportPDF;
