import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import * as FileSaver from "file-saver";

import ReportPdf from "../../components/SumaryReport/PDF/PDF.component";
import { FILE_FORMATS } from "../../components/SumaryReport/SummaryReport.constants";
import { IReport } from "../reducers/summaryReport";
import exportToCsv from "../../utils/exportToCsv";
import { IReportItem } from "../../models/basicModels";

import { IClient } from "../reducers/clients";

export function getClientNamebyId(clients: IClient[], id: number) {
  return clients.find((client) => Number(client.id) === id)?.name;
}

export function getReportName(
  clientName: string,
  from: Date,
  to: Date,
  ext?: string
) {
  return `report:${clientName && clientName.replace(/\s/g, "")}_${moment(
    from
  ).format("MM/DD/YYYY")}_${moment(to).format("MM/DD/YYYY")}${ext || ""}`;
}

interface IFileHelper {
  clients: IClient[];
  clientId: string;
  format: string;
  reportList: IReportItem[];
  createDateGte: Date;
  createDateLte: Date;
}
export function* downloadreportByFormatType({
  clients,
  clientId,
  format,
  reportList,
  createDateGte,
  createDateLte,
}: IFileHelper) {
  const clientName = getClientNamebyId(clients, Number(clientId));
  const blob = yield pdf(
    ReportPdf({
      client: clientName || "",
      createDateGte,
      createDateLte,
      list: reportList,
    })
  ).toBlob();
  if (format === FILE_FORMATS.PDF) {
    FileSaver.saveAs(
      blob,
      getReportName(clientName || "", createDateGte, createDateLte, ".pdf")
    );
  }
  if (format === FILE_FORMATS.CSV) {
    exportToCsv(
      reportList.map(({ company, ...rest }) => {
        return { ...rest, client: company };
      }),
      getReportName(clientName || "", createDateGte, createDateLte)
    );
  }
}

export function parseFromString(svgString: string) {
  let parser = new DOMParser();
  return parser.parseFromString(svgString || "", "image/svg+xml")
    .documentElement;
}

interface IRetrivesReport extends IReport {
  pulseOxymeter?: number;
}
export function renamePulseOxymeterItem(list: IRetrivesReport[]): IReport[] {
  return list.map((item) => ({
    ...item,
    pulseOximeter: item.pulseOxymeter || 0,
  }));
}
export function replaceNulls(list: IReport[]) {
  return list.map((item) => ({
    ...item,
    timer: item.timer || 0,
    total: item.total || 0,
    daysWithReading: item.daysWithReading || 0,
    bloodPressure: item.bloodPressure || 0,
    glucose: item.glucose || 0,
    pulseOx: item.pulseOx || 0,
    temp: item.temp | 0,
    weight: item.weight | 0,
  }));
}
