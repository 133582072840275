import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { AppState } from "../../../store/reducers";
import { Case } from "../../../models/basicModels";
import prepareMeasurement from "../../../utils/prepareMeasurement";

import { getMeasurementIconByOldName } from "../../../utils/getMeasurementIcon";
import callResults from "../../../constants/callResults";

interface Props {
  case: Case;
  measurementHeader: string[];
  step: number;
}

const dateFormatter: string = "YYYY-MM-DD hh:mm A";

class MeasurementsInformation extends React.Component<Props> {
  render(): React.ReactNode {
    const { step, measurementHeader } = this.props;

    if (!this.props.case) {
      //} || prepareMeasurement(this.props.case, step).length === 0) {
      return null;
    }

    return (
      <>
        {this.renderMeasurementHeader(measurementHeader)}
        {this.renderMeasurements(step)}
      </>
    );
  }

  renderMeasurementHeader = (columns: string[]) => {
    return (
      <div className="screen-wrapper__section screen-wrapper__section--noborder">
        {columns.map((item: string, index: number) => (
          <div
            key={index}
            className="screen-wrapper-column screen-wrapper-column--nobottomspacing"
          >
            <p className="screen-wrapper-column__title">{item}</p>
          </div>
        ))}
      </div>
    );
  };

  renderMeasurements = (step: number) => {
    const alertMeasurements = prepareMeasurement(this.props.case, step);
    const callResultExist = this.props.measurementHeader.findIndex(
      (value) => value === "Call result"
    );

    let callResult: string | undefined = undefined;
    let callDate: string = "";
    if (callResultExist >= 0) {
      const existCallResult =
        this.props.case.callResults.length > 0
          ? this.props.case.callResults[this.props.case.callResults.length - 1]
          : undefined;
      if (existCallResult) {
        const callResultIndex: number = callResults.findIndex(
          (item) => item.value === existCallResult.result
        );
        if (callResultIndex >= 0) {
          callResult = callResults[callResultIndex].name as string;
        }
        callDate = moment(existCallResult.date).format(dateFormatter);
      }
    }

    if (alertMeasurements.length > 0) {
      return alertMeasurements.map((item, index: number) => (
        <div
          key={index}
          className="screen-wrapper__section screen-wrapper__section--noborder screen-wrapper__section--nopadding"
        >
          <div className="screen-wrapper-column screen-wrapper-column--nobottomspacing">
            <p className="screen-wrapper-column__value">{item.column1}</p>
          </div>
          <div className="screen-wrapper-column screen-wrapper-column--nobottomspacing">
            <p className="screen-wrapper-column__value">
              {index === 0 && callResult ? callResult : item.column2}
            </p>
          </div>
          <div className="screen-wrapper-column">
            <p className="screen-wrapper-column__value">
              <div key={index} className="measurement-item">
                <img
                  src={getMeasurementIconByOldName(item.type)}
                  alt="intervention"
                  className="link__image active"
                />
                <span>{item.values}</span>
              </div>
            </p>
          </div>
        </div>
      ));
    } else {
      return (
        <div className="screen-wrapper__section screen-wrapper__section--noborder screen-wrapper__section--nopadding">
          <div className="screen-wrapper-column screen-wrapper-column--nobottomspacing">
            <p className="screen-wrapper-column__value">{callDate}</p>
          </div>
          <div className="screen-wrapper-column screen-wrapper-column--nobottomspacing">
            <p className="screen-wrapper-column__value">{callResult}</p>
          </div>
          <div className="screen-wrapper-column">
            <p className="screen-wrapper-column__value" />
          </div>
        </div>
      );
    }
  };
}

const mapStateToProps = (st: AppState) => {
  return st;
};
export default connect(mapStateToProps)(MeasurementsInformation);
