import React from "react";
// Redux
import { connect } from "react-redux";
import { AppState } from "../../../store/reducers";
// Components
import { ScreenWrapper } from "../../ScreenWrapper";
// Icons
import { Case } from "../../../models/basicModels";
import moment from "moment";

interface Props {
  case?: Case;
}

const dateFormatter: string = "YYYY-MM-DD hh:mm A";

class ProvidersInformation extends React.Component<Props> {
  render(): React.ReactNode {
    if (!this.props.case || !this.props.case.doctorId) {
      return null;
    }

    const { measurements } = this.props.case;
    const createdDate = measurements.find((val) => val.step === 3);
    const reviewDate = moment(
      createdDate ? createdDate.createdAt : Date()
    ).format(dateFormatter);
    const doctorName = `${this.props.case.doctorFirstName} ${this.props.case.doctorLastName}`;

    return (
      <ScreenWrapper
        title="Provider’s information"
        leftBorderColor="#f8cf9e"
        className="case__soap-notes"
      >
        <div className="screen-wrapper__section">
          <div className="screen-wrapper-column screen-wrapper-column--nospacing">
            <p className="screen-wrapper-column__title">Provider’s name</p>
            <p className="screen-wrapper-column__value">{doctorName}</p>
          </div>
          <div className="screen-wrapper-column screen-wrapper-column--nospacing">
            <p className="screen-wrapper-column__title">Review date</p>
            <p className="screen-wrapper-column__value">{reviewDate}</p>
          </div>
        </div>
      </ScreenWrapper>
    );
  }
}

const mapStateToProps = (st: AppState) => {
  return st;
};
export default connect(mapStateToProps)(ProvidersInformation);
