import React from "react";
import { View, Text, Font, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

//import { IReport } from "../../../store/reducers/summaryReport";
//@ts-ignore
import openSansReqular from "../../../assets/fonts/Open_Sans/OpenSans-Regular.ttf";
//@ts-ignore
import openSansBold from "../../../assets/fonts/Open_Sans/OpenSans-Bold.ttf";
//@ts-ignore
import openSansSemiBold from "../../../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf";
import calculatePts from "../../../utils/calculatePts";
import Table from "../../PDFTable/Table.component";
import Row from "../../PDFTable/Row.component";
import Col from "../../PDFTable/Col.component";
import Cell from "../../PDFTable/Cell.component";
import TableHeader from "../../PDFTable/TableHeader";
import styles from "./pdf.styles";
import TableFooter from "../../PDFTable/PDFFooter.component";
import wordWrap from "../../../utils/wordWrap";
//import showMinutesAndSeconds from "../../../utils/showHoursMinutesAndSeconds";
import { IReportItem } from "../../../models/basicModels";

const dataBlockstyles = StyleSheet.create({
  content: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

Font.register({
  family: "Open Sans",
  fonts: [
    { src: openSansReqular },
    { src: openSansBold, fontWeight: "bold" },
    { src: openSansSemiBold, fontWeight: "semibold" },
  ],
});

interface IProps {
  list: IReportItem[];
}

function SummaryReport({ list }: IProps) {
  return (
    <Table>
      <View style={styles.tableContent}>
        {!!list.length && (
          <View fixed style={{ marginTop: calculatePts(80) }}>
            <TableHeader>
              <Col>
                <Cell style={styles.headerCell}>First Name</Cell>
              </Col>
              <Col>
                <Cell style={styles.headerCell}>Last Name</Cell>
              </Col>
              <Col>
                <Cell style={styles.headerCell}>Date of Birth</Cell>
              </Col>
              <Col>
                <Cell style={styles.headerCell}>Days with readings</Cell>
              </Col>
              <Col>
                <Cell style={styles.headerCell}>Total readings</Cell>
              </Col>
              <Col>
                <Cell style={styles.headerCell}>Time tracked</Cell>
              </Col>
            </TableHeader>
          </View>
        )}
        {list.map((item, key) => {
          const rowStyle = {
            backgroundColor: Boolean(key % 2) ? "#F3F3F3" : "white",
          };
          return (
            <Row style={rowStyle} key={item.uuid}>
              <Col>
                <Cell
                  style={{
                    ...styles.cell,
                    marginLeft: 3,
                    fontSize: calculatePts(22),
                  }}
                >
                  {wordWrap(item.firstName, 14)}
                </Cell>
              </Col>
              <Col>
                <Cell
                  style={{
                    ...styles.cell,
                    marginLeft: 3,
                    fontSize: calculatePts(22),
                  }}
                >
                  {wordWrap(item.lastName, 14)}
                </Cell>
              </Col>
              <Col>
                <Cell
                  style={{
                    ...styles.cell,
                    marginLeft: 3,
                    fontSize: calculatePts(22),
                  }}
                >
                  {moment(item.dob).format("MMM.D,YYYY")}
                </Cell>
              </Col>
              <Col>
                <Cell>{item.daysWithReading || 0}</Cell>
              </Col>
              <Col>
                <Cell>{item.total}</Cell>
              </Col>
              <Col>
                <Cell>{item.timer}</Cell>
              </Col>
            </Row>
          );
        })}
        <View style={dataBlockstyles.content}>
          {!list.length && (
            <Text style={styles.subtitle}>There are no Data</Text>
          )}
        </View>
        <TableFooter />
      </View>
    </Table>
  );
}

export default SummaryReport;
