import React, { createContext, useContext, useReducer } from "react";

type HeaderType = { header: React.ReactNode; showHeaderBlock: boolean };

export enum ACTION_NAMES {
  SET_HEADER_BLOCK = "SET_HEADER_BLOCK",
  SHOW_HEADER = "SHOW_HEADER",
}

export function HeaderReducer(
  state: HeaderType,
  action: { type: ACTION_NAMES; payload: React.ReactNode | boolean }
) {
  switch (action.type) {
    case ACTION_NAMES.SET_HEADER_BLOCK:
      return { ...state, header: action.payload };
    case ACTION_NAMES.SHOW_HEADER:
      return { ...state, showHeaderBlock: Boolean(action.payload) };
    default:
      throw new Error();
  }
}

export function useHeaderState() {
  return useReducer<typeof HeaderReducer>(HeaderReducer, {
    header: null,
    showHeaderBlock: false,
  });
}

interface IHeader {
  header: React.ReactNode;
  setHeader: React.Dispatch<{
    type: ACTION_NAMES;
    payload: React.ReactNode;
  }>;
  showHeaderBlock: boolean;
}

export const HeaderContext = createContext<IHeader>({
  header: null,
  setHeader: () => {},
  showHeaderBlock: false,
});

function useHeader(): IHeader {
  const [{ header, showHeaderBlock }, setHeader] = useHeaderState();

  return { header, setHeader, showHeaderBlock };
}

export function HeaderProvider({ children }: { children: React.ReactNode }) {
  const headerContex = useHeader();
  return (
    <HeaderContext.Provider value={headerContex}>
      {children}
    </HeaderContext.Provider>
  );
}
export function useHeaderContext() {
  return useContext<IHeader>(HeaderContext);
}
