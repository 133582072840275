import React from "react";
import { Route, RouteProps } from "react-router";

interface Props extends RouteProps {
  component: any;
}

const CommonRoute = ({ component: Component, ...rest }: Props) => (
  <Route {...rest} render={({ ...props }) => <Component {...props} />} />
);

export default CommonRoute;
