import React from "react";
import classnames from "classnames";

import { useAuth, useLogout } from "../../hooks";
import { useModal } from "./ProfileModal.hooks";
import Modal from "../Modal";
import ResetPassword from "../ResetPasswordModal";

import "./modal.scss";

interface IProps {
  isDisabled: boolean;
  onClose?: () => void;
}

export default function ProfileModal({ isDisabled, onClose }: IProps) {
  const auth = useAuth();
  const { toggleModalHandler, showModal } = useModal();
  const handleLogout = useLogout();
  const modalClassName = classnames("profile-modal", {
    "profile-modal--disabled": isDisabled,
  });
  return (
    <>
      <div className={modalClassName}>
        <div className="profile-modal__overlay" onClick={onClose}>
          &nbsp;
        </div>
        <div className="profile-modal__body">
          <div className="profile-modal__header">
            {auth.avatar && (
              <img
                src={auth.avatar}
                alt="intervention"
                className="profile-modal__image"
              />
            )}
            <div className="profile-modal__header__container">
              <label className="profile-modal__title-name">{`${auth.firstName} ${auth.lastName}`}</label>
              <label className="profile-modal__title-email">{`${auth.email}`}</label>
            </div>
          </div>
          <div className="profile-modal__wrapper">
            <div className="profile-modal__wrapper__button">
              <div onClick={toggleModalHandler}>
                <label className="profile-modal__title-name">
                  Change password
                </label>
              </div>
              <div onClick={handleLogout}>
                <label className="profile-modal__title-name">Sign out</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isDisabled={!showModal}
        title="Change the password"
        onClose={toggleModalHandler}
      >
        <ResetPassword closeModal={toggleModalHandler} />
      </Modal>
    </>
  );
}
