import actions from "../../constants/actionsTypes";
import { INotifications } from "../reducers/notifications";

export const getNotificationsStart = (params: { [key: string]: string }) => ({
  type: actions.notifications.get.start,
  payload: params,
});

export const getNotificationsSuccess = (payload: INotifications) => ({
  type: actions.notifications.get.success,
  payload,
});

export const getNotificationsFailure = (error: any) => ({
  type: actions.notifications.get.fail,
  payload: error,
});

export const resetNotifications = () => ({
  type: actions.notifications.reset,
});
