import React from "react";
import ReactPDF, { Text, StyleSheet } from "@react-pdf/renderer";
import calculatePts from "../../utils/calculatePts";

const styles = StyleSheet.create({
  cel: {
    margin: "auto",
    marginTop: 8,
    fontFamily: "Open Sans",
    fontSize: calculatePts(24),
    lineHeight: 2,
    fontWeight: "semibold",
    display: "flex",
    flexWrap: "wrap",
    //@ts-ignore
  },
});

interface IProps {
  children: string | number;
  style?: ReactPDF.Style;
  cut?: boolean;
}
function PDFCell({ children, style, cut }: IProps) {
  return (
    <Text style={[styles.cel, style || {}]}>
      {cut && String(children).substring(0, 11)}
      {!cut && children}
    </Text>
  );
}

export default PDFCell;
