import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";
import { MEASUREMENT_TYPES } from "../../models/measuarementTypes";
import { JobType } from "../../store/reducers/auth";

export interface IVitalAlert {
  id: number;
  patientId: string;
  measurementType: MEASUREMENT_TYPES;
  measurementResult: string;
  borderlineValue: number;
  status: string;
  clearedBy: null | string;
  clearedDate: string;
  creationDate: string;
  jobTitleCleared: JobType;
}

export interface IVitalAlerts {
  data?: IVitalAlert[];
  error?: any;
  loading?: boolean;
  loadingTable?: boolean;
  count?: number | null;
}

const initialState: IVitalAlerts = {
  data: [],
  error: null,
  loading: false,
  count: null,
};

export default function vitalAlerts(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.vitalAlerts.get.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.vitalAlerts.get.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.vitalAlerts.get.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.vitalAlerts.update.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.vitalAlerts.update.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.vitalAlerts.update.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.vitalAlerts.reset:
      return initialState;
    default:
      return state;
  }
}
