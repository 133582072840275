import { StyleSheet } from "@react-pdf/renderer";
import calculatePts from "../../../utils/calculatePts";

export default StyleSheet.create({
  content: {
    marginTop: calculatePts(70),
    marginRight: calculatePts(100),
    marginLeft: calculatePts(100),
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  title: {
    fontFamily: "Open Sans",
    lineHeight: 2,
    fontWeight: "bold",
    fontSize: calculatePts(40),
  },
  descriptionBlock: {
    display: "flex",
    flexDirection: "row",
    marginTop: calculatePts(41),
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontSize: calculatePts(24),
    lineHeight: 2,
    opacity: 0.8,
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: calculatePts(24),
    lineHeight: 2,
    fontWeight: "semibold",
  },
  cell: {
    textAlign: "left",
    margin: 0,
  },
  paginationText: {
    fontFamily: "Open Sans",
    fontSize: calculatePts(24),
    lineHeight: 2,
    opacity: 0.8,
  },
  paginationBlock: {
    display: "flex",
    flexDirection: "row-reverse",
    marginVertical: "1rem",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingVertical: calculatePts(41),
    marginTop: calculatePts(10),
    borderTop: calculatePts(1),
    borderColor: "#E5E5E5",
    justifyContent: "space-between",
  },
  snippet: {
    display: "flex",
    flexDirection: "row",
  },
  meassage: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  tableContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-start",
  },
  headerCell: {
    textAlign: "left",
    margin: 0,
    marginLeft: 7,
    color: "#356199",
    lineHeight: 2,
  },
});
