import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import "./styles.scss";

interface Props extends RouteComponentProps {
  image: string;
  to: string;
  text: string;
  onClick: () => void;
}

const NavigationItem = ({
  to,
  image,
  text,
  location: { pathname },
  onClick,
}: Props) => (
  <Link
    to={to}
    className={`navigation__link ${to === pathname && "active"}`}
    onClick={onClick}
  >
    {text}
  </Link>
);

export default withRouter(NavigationItem);
