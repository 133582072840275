import React from "react";
import { Form, FormikProps } from "formik";
import FormikDurationInputField from "../Form/FormikDurationInputField";

import { Button } from "../Form";
import { ValuesType } from "./TaskTimerForm.types";
import FormikInputField from "../Form/FormikInputField";
import { FIELD_NAMES } from "../../constants/taskTimer";
import FormikDatePickerField from "../Form/FormikDatePickerField";
import FormikSelectField from "../Form/FormikSelectField";
import { TASK_TYPE_OPTIONS } from "./TaskTimerForm.constants";
import { usePatient, useAuth } from "../../hooks";
import Loader from "../Loader";

import "./TaskTimerForm.styles.scss";
import { ITimer } from "../../store/reducers/taskTimer";

interface IProps extends FormikProps<ValuesType> {
  onCancel: () => void;
  isEditing: boolean;
  profile: ITimer["data"]["profile"];
  loading: boolean;
  onCloseReset: () => void;
}

export default function TaskTimerForm({
  onCancel,
  isEditing,
  profile,
  loading,
  onCloseReset
}: IProps) {
  const { firstName, lastName } = usePatient();
  const auth = useAuth();
  const authorID = isEditing
    ? `${profile?.name} ${profile?.lastName}`
    : `${auth.firstName} ${auth.lastName}`;
  return (
    <Form className="md:px-auto task-timer-form">
      {loading && <Loader />}
      <div className="flex mt-1">
        <div className="flex flex-col task-timer-form__description-item mr-8">
          <span className="font-semibold task-timer-form__description-item__label">
            Patient name
          </span>
          <span className="task-timer-form__description-item__text">{`${firstName} ${lastName}`}</span>
        </div>
        <div className="flex flex-col task-timer-form__description-item">
          <span className="font-semibold task-timer-form__description-item__label">
            Author ID
          </span>
          <span className="task-timer-form__description-item__text">
            {authorID}
          </span>
        </div>
      </div>
      <div className="grid  grid-cols-1 md:grid-cols-3  gap-y-2 md:gap-x-5 mt-5">
        <FormikDurationInputField
          name={FIELD_NAMES.DURATION}
          label="Time overal"
          type="text"
        />
        <FormikDatePickerField
          name={FIELD_NAMES.START_TIME}
          title="Tracking started at:"
          format="HH:mm:ss A"
          showCalendarIcon={false}
          datePickerProps={{ disabled: true }}
        />
        <FormikDatePickerField
          name={FIELD_NAMES.END_TIME}
          title="Tracking finished at:"
          format="HH:mm:ss A"
          showCalendarIcon={false}
          datePickerProps={{ disabled: true }}
        />
        <div className="md:col-span-3">
          <FormikSelectField
            name={FIELD_NAMES.TASK_TYPE}
            options={TASK_TYPE_OPTIONS}
            className="mb-0 flex-row "
            placeholder="No Answer"
            label="Task type"
          />
        </div>
      </div>
      <FormikInputField
        name={FIELD_NAMES.NOTE}
        type="text"
        multiline
        label="Note"
        className="mt-5"
      />
      <div className="flex justify-end mt-4">
        <Button
          className="mr-4"
          buttonType="button"
          type="default"
          value="Cancel"
          onClick={onCancel}
        />
        <Button
          className="mr-4"
          buttonType="button"
          type="default"
          value="Close and reset"
          onClick={onCloseReset}
        />
        <Button
          className=""
          buttonType="submit"
          disabled={loading}
          type="primary"
          value="Submit"
        />
      </div>
    </Form>
  );
}
