import React, { ReactNode } from "react";

import "./styles.scss";
import "./screen-wrapper.scss";

interface Props {
  children?: ReactNode;
  title?: string;
  className?: string;
  leftBorderColor?: string;
}

export const ScreenWrapper = ({
  children,
  title,
  className,
  leftBorderColor: backgroundColor,
}: Props) => {
  let additionalClassName = "screen-wrapper";
  if (className) {
    additionalClassName += " " + className;
  }

  return (
    <div className={additionalClassName}>
      <div
        style={{ backgroundColor }}
        className={"screen-wrapper__left-line"}
      />
      <div className={"screen-wrapper__screen"}>
        {title && (
          <div
            className="screen-wrapper__screen__title"
            style={{ borderBottomColor: backgroundColor }}
          >
            <span className="screen-wrapper__screen__title__label">
              {title.toUpperCase()}
            </span>
          </div>
        )}

        <div>{children}</div>
      </div>
    </div>
  );
};
