import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";

export interface IClient {
  name: string | null;
  id: number
}

export interface IClients {
  list?: IClient[];
  error?: any;
  loading?: boolean;
}

const initialState: IClients = {
  list: [],
  error: null,
  loading: false,
};

export default function Clients(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.clients.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.clients.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.clients.fail:
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
