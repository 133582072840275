import React, { useCallback } from "react";

import { LOCAL_DASHBOARD } from "../../constants/urls";
import { useHistory } from "react-router";

export function useBackPressHandler() {
  const history = useHistory();
  return useCallback(() => {
    history.replace(LOCAL_DASHBOARD);
  }, [history]);
}

export function useToggleNavigationHandler(
  navigationRef: React.RefObject<HTMLDivElement>
) {
  return useCallback(() => {
    navigationRef.current &&
      (navigationRef.current.classList.contains("open")
        ? navigationRef.current.classList.remove("open")
        : navigationRef.current.classList.add("open"));
  }, [navigationRef]);
}
