import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";

export interface INotification {
  patient: string;
  createdDate: string;
  messageBody: string;
  messageTitle: string;
}

export interface INotifications {
  data?: INotification[];
  error?: any;
  loading?: boolean;
  loadingTable?: boolean;
  count?: number | null;
}

const initialState: INotifications = {
  data: [],
  error: null,
  loading: false,
  count: null,
};

export default function notifications(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.notifications.get.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.notifications.get.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.notifications.get.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.notifications.reset:
      return initialState;
    default:
      return state;
  }
}
