import { put, select, call } from "redux-saga/effects";

import { createSelector } from "reselect";
import snakeCaseKeys from "snakecase-keys";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import { camelToSnake } from "../../utils/stringUtils";

import {
  getVitalAlertsStart,
  getVitalAlertsSuccess,
  getVitalAlertsFailure,
  updateVitalAlertsStart,
  updateVitalAlertsSuccess,
  updateVitalAlertsFailure,
} from "../actions/vitalAlerts";
import { AppState } from "../reducers";
import { API_VITAL_ALERTS } from "../../constants/urls";
import { PatientData } from "../reducers/patient";
import { patientSelector } from "../sagas/patient";
import { IVitalAlert, IVitalAlerts } from "../reducers/vitalAlerts";
import { recognizeErrorMessage } from "../../utils/errors";
import { errorNotify, successNotify } from "../../components/Toaster/toster";

export const vitalAlertsSelector = createSelector(
  ({ vitalAlerts }: AppState) => vitalAlerts,
  (vitalAlerts) => vitalAlerts
);

export function* getVitalAlertsSaga({
  payload,
}: ReturnType<typeof getVitalAlertsStart>) {
  try {
    const patient: PatientData = yield select(patientSelector);
    if (!patient?.uuid) {
      throw new Error("There is no patient!");
    }
    const params = snakeCaseKeys({
      ...payload,
      ordering: payload.ordering ? camelToSnake(payload.ordering) : "",
      patient: payload.patientId ? payload.patientId : patient.uuid,
    });

    const { data } = yield axios.get(API_VITAL_ALERTS, { params });
    yield put(
      getVitalAlertsSuccess({
        data: camelCaseKeys(data.results),
        count: data.count,
      })
    );
  } catch (error) {
    yield put(getVitalAlertsFailure(error));
  }
}

export function* updateVitalAlertsSaga({
  payload: { id, status, onUpdateData },
}: ReturnType<typeof updateVitalAlertsStart>) {
  try {
    const patient: PatientData = yield select(patientSelector);
    const vitalAlerts: IVitalAlerts = yield select(vitalAlertsSelector);
    const { data } = yield axios.put(
      `${API_VITAL_ALERTS}${id}/`,
      snakeCaseKeys({ patientId: patient.uuid, status })
    );
    const updatedAlert = data as IVitalAlert;
    const newList = [...(vitalAlerts.data || [])].map((item) => {
      if (item.id === updatedAlert.id) {
        return updatedAlert;
      }
      return item;
    });
    yield put(updateVitalAlertsSuccess({ data: newList }));
    yield call(onUpdateData);
    yield call(successNotify, "Status has been changed!");
  } catch (error) {
    yield put(updateVitalAlertsFailure(error));
    errorNotify(recognizeErrorMessage(error));
  }
}
