import actions from "../../constants/actionsTypes";
import { Action } from "./index";
import { IPatient } from "./patients";

type ProtoExntends<T, U> = U &
  {
    [P in Exclude<keyof T, keyof U>]: T[P];
  };

type EnhancendPatient = {
  address: string;
  city: string;
  zip: string;
  state: string;
};

export type PatientData = ProtoExntends<IPatient, EnhancendPatient>;
export interface PatientStore {
  data: PatientData;
  loading: boolean;
  error: any;
  cityStateByZip?: {
    zipcode: number;
    primaryCity: string;
    state: string;
  } | null;
}

const initialState: PatientStore = {
  data: {
    address: "",
    city: "",
    state: "",
    zip: "",
    dob: "",
    firstName: "",
    lastName: "",
    company: "",
    sex: "",
    primaryPhone: "",
    secondaryPhone: "",
    healthCondition: "",
    physician: null,
    doctors: [],
    uuid: "",
    untrained: false,
    firstCase: NaN,
    ccm: false,
    rpm: false,
    cde: false,
    mht: false,
    mh: false,
    authorizedPatientRepresentative: "",
    relationship: "",
    consentDate: "",
    lastFaceToFace: "",
    demographicInformationProvided: false,
    insuranceInformationProvided: false,
    lastMeasurement: "",
    isAlert: false,

    primaryInsuranceCompanyName: "",
    primaryInsurancePolicyHolder: "",
    primaryInsuranceGroupNumber: "",
    primaryInsuranceMemberId: "",
    insuranceImage: "",
    backsiteInsuranceImage: "",
    demographicSheetImage: "",
    primaryInsuranceDeviceCoverageVerified: false,
    primaryInsuranceOutOfNetworkCoverage: false,

    secondaryInsuranceCompanyName: "",
    secondaryInsurancePolicyHolder: "",
    secondaryInsuranceGroupNumber: "",
    secondaryInsuranceMemberId: "",
    secondaryInsuranceDeviceCoverageVerified: false,
    secondaryInsuranceOutOfNetworkCoverage: false,

    email: "",
    hhAlternatePhoneNumber: "",
    hhEmail: "",
    hhName: "",
    hhPhoneNumber: "",
    hhPrimaryContact: "",
    hhScheduleFriday: "",
    hhScheduleMonday: "",
    hhScheduleSaturday: "",
    hhScheduleSunday: "",
    hhScheduleThursday: "",
    hhScheduleTuesday: "",
    hhScheduleWednesday: "",
    healthcareCareGiverName: "",
    status: null,
  },
  loading: false,
  error: null,
  cityStateByZip: null,
};

export default function patient(
  state = initialState,
  { payload, type }: Action
) {
  switch (type) {
    case actions.patient.add.start:
      return {
        ...state,
        loading: true,
      };
    case actions.patient.success:
      return {
        ...state,
        ...payload,
        error: null,
        loading: false,
      };
    case actions.patient.update.start:
      return {
        ...state,
        loading: true,
      };
    case actions.patient.update.success:
      return {
        ...state,
        ...payload,
        error: null,
        loading: false,
      };
    case actions.patient.update.fail:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case actions.patient.reset:
      return {
        ...initialState,
        error: null,
        loading: false,
      };
    case actions.patient.add.fail:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case actions.patient.getCityState.success:
      return {
        ...state,
        cityStateByZip: payload,
      };
    default:
      return state;
  }
}
