import axios from "axios";
import { put, call } from "redux-saga/effects";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";
import { createSelector } from "reselect";

import { AppState } from "../reducers";
import { API_PRIMARY_CARE_PHYSICIAN } from "../../constants/urls";
import { recognizeErrorMessage } from "../../utils/errors";
import { errorNotify, successNotify } from "../../components/Toaster/toster";
import {
  addPrimaryCarePhysicianStart,
  addPrimaryCarePhysicianSuccess,
  addPrimaryCarePhysicianFailure,
} from "../actions/primaryCarePhysician";

export const primaryCarePhysicianSelector = createSelector(
  ({ primaryCarePhysician }: AppState) => primaryCarePhysician,
  (primaryCarePhysician) => primaryCarePhysician
);

export function* addPrimaryCarePhysicianSaga({
  payload,
}: ReturnType<typeof addPrimaryCarePhysicianStart>) {
  try {
    const { data, status } = yield axios.post(
      `${API_PRIMARY_CARE_PHYSICIAN}`,
      snakeCaseKeys(payload),
      {
        validateStatus: (status) => {
          return (
            Number(status) === 200 ||
            Number(status) === 201 ||
            Number(status) === 400
          );
        },
      }
    );
    if (status === 400) {
      yield put(addPrimaryCarePhysicianFailure(camelCaseKeys(data)));
      throw new Error("Invalid data, please check your form");
    }
    yield put(addPrimaryCarePhysicianSuccess());
    yield call(
      successNotify,
      "Thank you for selecting CareHalo. Your registration has been submitted, and a team member will contact you via email shortly."
    );
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    errorNotify(errorMsg);
  }
}
