import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { logout } from "../store/actions/auth";

export default function useLogout() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
}
