import React, { ReactNodeArray } from "react";
import classnames from "classnames";

import "./HeaderBlockContent.styles.scss";

interface IProps {
  title: string | React.ReactNode | ReactNodeArray;
  children?: React.ReactNode | ReactNodeArray;
  classNames?: {
    title?: string;
    description?: string;
  };
  className?: string;
}

export default function HeaderBlockContent({
  title,
  children,
  className,
  classNames,
}: IProps) {
  const classes = classnames("flex flex-col", className);
  const titleClasses = classnames(
    "font-semibold text-lg flex",
    classNames?.title
  );
  const descriptionClasses = classnames(
    "text-sm flex mr-2 normal-case mt-2 header-block__description tracking-wide",
    classNames?.description
  );
  return (
    <div className={classes}>
      <div className={titleClasses}>{title}</div>
      {children && <div className={descriptionClasses}>{children}</div>}
    </div>
  );
}
