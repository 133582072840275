import { put } from "redux-saga/effects";

import { createSelector } from "reselect";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";

import { getPcpRateSuccess, getPcpRateFailure } from "../actions/pcpRate";
import { AppState } from "../reducers";
import { API_PCP_RATE } from "../../constants/urls";
import { recognizeErrorMessage } from "../../utils/errors";
import { errorNotify } from "../../components/Toaster/toster";
import { IPcpRate } from "../reducers/pcpRate";

export const pcpRateSelector = createSelector(
  ({ pcpRate }: AppState) => pcpRate,
  (pcpRate) => pcpRate
);

export function* getPcpRateSaga() {
  try {
    const { data } = yield axios.get(API_PCP_RATE);
    const transformedData = camelCaseKeys<IPcpRate>({ data } as IPcpRate, {
      deep: true,
    });
    yield put(getPcpRateSuccess(transformedData));
  } catch (error) {
    yield put(getPcpRateFailure(error));
    errorNotify(recognizeErrorMessage(error));
  }
}
