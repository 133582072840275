import React from "react";
// Redux
import { connect } from "react-redux";
import { AppState } from "../../../store/reducers";
// Components
import { ScreenWrapper } from "../../ScreenWrapper";
// Icons
import { Case } from "../../../models/basicModels";
import prepareMeasurement from "../../../utils/prepareMeasurement";

import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import { getMeasurementIconByOldName } from "../../../utils/getMeasurementIcon";
import {
  AbnormalVitalSigns,
  AbnormalVitalSignsModel,
  AbnormalVitalSignsValue,
  dAbnormalVitalTypeOrder,
  diabeticTriageOrder,
  diabeticTriageTitle,
  DiabeticTriageType,
  PatientsSymptoms,
} from "../../../utils/diabetic";
import { Checkbox } from "../../Form/Checkbox";
import { splitArray } from "../../../utils/arrayUtils";
import MeasurementsInformation from "./MeasurementsInformation";

interface Props {
  case: Case;
}

class CaseInformation extends React.Component<Props> {
  render(): React.ReactNode {
    if (!this.props.case || !this.props.case.patient) {
      return null;
    }

    const {
      patient: {
        sex = "",
        firstName = "",
        lastName = "",
        address,
        physician,
        primaryPhone,
        secondaryPhone,
        healthCondition,
        dob,
      },
      diabeticTriage,
    } = this.props.case;
    const patientSex: string = capitalizeFirstLetter(sex);
    const patientName: string = `${firstName} ${lastName} | ${patientSex}`;
    const street: string | null = address && address.address;
    const stateCityZip: string = `${address && address.city}, ${
      address && address.state
    } ${address && address.zip}`;

    const abnormalVitalSigns = diabeticTriage.abnormalVitalSigns
      ? diabeticTriage.abnormalVitalSigns.map(
          (value: AbnormalVitalSignsValue) => {
            const sign = AbnormalVitalSigns.filter(
              (value1: AbnormalVitalSignsModel) => value1.title === value.key
            )[0];
            return {
              type: value.key,
              value: sign.values[value.value],
              order: dAbnormalVitalTypeOrder(value.key),
            };
          }
        )
      : [];

    const sortedAbnormalVitalSigns = abnormalVitalSigns.sort(function (a, b) {
      return a.order - b.order;
    });

    let descriptions: {
      type: DiabeticTriageType;
      value: string;
      order: number;
    }[] = [];
    if (diabeticTriage.descriptions) {
      Object.entries(diabeticTriage.descriptions).forEach(([key, value]) => {
        // @ts-ignore
        descriptions.push({
          // @ts-ignore
          type: key,
          // @ts-ignore
          value: value.toString(),
          // @ts-ignore
          order: diabeticTriageOrder(key),
        });
      });
    }

    const sortedDescription = descriptions.sort(function (a, b) {
      return a.order - b.order;
    });

    const patientSymptoms =
      diabeticTriage.patientSymptoms &&
      diabeticTriage.patientSymptoms.length > 0
        ? splitArray(diabeticTriage.patientSymptoms)
        : [];
    const physicianName = physician
      ? `${physician.firstName} ${physician.lastName}`
      : "";
    const formattedHeltCondition = healthCondition.replace(/;/gi, ", ");
    return (
      <ScreenWrapper title="Case information" leftBorderColor="#b6e0f8">
        <div className="screen-wrapper__section">
          <div className="screen-wrapper-column screen-wrapper-column--nospacing">
            <p className="screen-wrapper-column__title">Name</p>
            <p className="screen-wrapper-column__value">{patientName}</p>
            <p className="screen-wrapper-column__title">Phone number</p>
            <p className="screen-wrapper-column__value">
              {primaryPhone}
              <br />
              {secondaryPhone}
            </p>
          </div>
          <div className="screen-wrapper-column screen-wrapper-column--nospacing">
            <p className="screen-wrapper-column__title">DOB</p>
            <p className="screen-wrapper-column__value">{dob}</p>
            <p className="screen-wrapper-column__title">Health condition</p>
            <p className="screen-wrapper-column__value">
              {formattedHeltCondition}
            </p>
          </div>
          <div className="screen-wrapper-column screen-wrapper-column--nospacing">
            <p className="screen-wrapper-column__title">Address</p>
            <p className="screen-wrapper-column__value">
              {street}
              <br />
              {stateCityZip}
            </p>
          </div>
          <div className="screen-wrapper-column screen-wrapper-column--nospacing">
            <p className="screen-wrapper-column__title">PCP</p>
            <p className="screen-wrapper-column__value">
              {physicianName}
              <br />
            </p>
            <p className="screen-wrapper-column__value">{`${
              physician ? physician.company : ""
            }`}</p>
          </div>
        </div>
        <MeasurementsInformation
          case={this.props.case}
          measurementHeader={[
            "Alert indicator",
            "Alert date and time",
            "Measurements",
          ]}
          step={1}
        />
        <div className="screen-wrapper__section" />
        <MeasurementsInformation
          case={this.props.case}
          measurementHeader={[
            "Call date and time",
            "Call result",
            "New measurements",
          ]}
          step={2}
        />
        {sortedDescription.length > 0 && (
          <>
            <div className="screen-wrapper__section" />
            <div className="screen-wrapper__section screen-wrapper__section--rotate">
              {sortedDescription.map((value, index) => {
                return (
                  value && (
                    <div key={index} className="screen-wrapper-column">
                      <p className="screen-wrapper-column__title">
                        {diabeticTriageTitle(value.type)}
                      </p>
                      <p className="screen-wrapper-column__value">
                        {value.value}
                      </p>
                    </div>
                  )
                );
              })}
            </div>
          </>
        )}
        {sortedAbnormalVitalSigns.length > 0 && (
          <>
            <div className="screen-wrapper__section screen-wrapper__section--noborder">
              <div className="screen-wrapper-column screen-wrapper-column--nospacing">
                <p className="screen-wrapper-column__title">
                  Abnormal Vital Signs
                </p>
              </div>
            </div>
            <div
              style={{ maxWidth: 640 }}
              className="screen-wrapper__section screen-wrapper__section--noborder screen-wrapper__section--nopadding"
            >
              {sortedAbnormalVitalSigns &&
                abnormalVitalSigns.map((value, index) => {
                  return (
                    value && (
                      <div key={index} className="screen-wrapper-column">
                        <p className="screen-wrapper-column__title">
                          {value.type}
                        </p>
                        <p className="screen-wrapper-column__value">
                          {value.value}
                        </p>
                      </div>
                    )
                  );
                })}
            </div>
          </>
        )}
        {patientSymptoms.length > 0 && (
          <>
            <div className="screen-wrapper__section screen-wrapper__section--noborder">
              <div className="screen-wrapper-column screen-wrapper-column--nospacing">
                <p className="screen-wrapper-column__title">
                  Mark the patient’s symptoms
                </p>
              </div>
            </div>
            <div className="screen-wrapper__section screen-wrapper__section--noborder screen-wrapper__section--nopadding">
              {this.renderPatientSymptomsRow(patientSymptoms[0])}
              {this.renderPatientSymptomsRow(patientSymptoms[1])}
            </div>
          </>
        )}
        <div className="screen-wrapper__section" />
        <div className="screen-wrapper__section screen-wrapper__section--rotate">
          <div className="screen-wrapper-column">
            <p className="screen-wrapper-column__title">Case summary</p>
            <p className="screen-wrapper-column__value">
              {this.props.case.summary}
            </p>
          </div>
          {this.props.case.escalation.length > 0 && (
            <div className="screen-wrapper-column">
              <p className="screen-wrapper-column__title">Escalation</p>
              <p className="screen-wrapper-column__value">
                {this.props.case.escalation}
              </p>
            </div>
          )}
        </div>
      </ScreenWrapper>
    );
  }

  renderPatientSymptomsRow = (patientSymptomsRow: PatientsSymptoms[]) => {
    return (
      <div
        style={{ flexGrow: 1 }}
        className="screen-wrapper__section screen-wrapper__section--noborder screen-wrapper__section--nopadding screen-wrapper__section--rotate"
      >
        {patientSymptomsRow.map((value, index) => {
          return (
            value && (
              <div
                key={index}
                className="screen-wrapper-column screen-wrapper-column--nobottomspacing"
              >
                <p className="screen-wrapper-column__value">
                  <Checkbox
                    value={value}
                    key={index}
                    name="checkbox"
                    disabled={true}
                    checked={true}
                    label={value}
                  />
                </p>
              </div>
            )
          );
        })}
      </div>
    );
  };

  renderMeasurementHeader = (columns: string[]) => {
    return (
      <div className="screen-wrapper__section screen-wrapper__section--noborder">
        {columns.map((item: string, index: number) => (
          <div
            key={index}
            className="screen-wrapper-column screen-wrapper-column--nobottomspacing"
          >
            <p className="screen-wrapper-column__title">{item}</p>
          </div>
        ))}
      </div>
    );
  };

  renderMeasurements = (step: number) => {
    const alertMeasurements = prepareMeasurement(this.props.case, step);
    return alertMeasurements.map((item, index: number) => (
      <div
        key={index}
        className="screen-wrapper__section screen-wrapper__section--noborder screen-wrapper__section--nopadding"
      >
        <div className="screen-wrapper-column screen-wrapper-column--nobottomspacing">
          <p className="screen-wrapper-column__value">{item.column1}</p>
        </div>
        <div className="screen-wrapper-column screen-wrapper-column--nobottomspacing">
          <p className="screen-wrapper-column__value">{item.column2}</p>
        </div>
        <div className="screen-wrapper-column">
          <p className="screen-wrapper-column__value">
            <div key={index} className="measurement-item">
              <img
                src={getMeasurementIconByOldName(item.type)}
                alt="intervention"
                className="link__image active"
              />
              <span>{item.values}</span>
            </div>
          </p>
        </div>
      </div>
    ));
  };
}

const mapStateToProps = (st: AppState) => {
  return st;
};
export default connect(mapStateToProps)(CaseInformation);
