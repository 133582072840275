import { MeasurementSetValue } from "../models/basicModels";

export default function processMeasurementValue(
  val: MeasurementSetValue,
  collection: MeasurementSetValue[]
) {
  if (collection) {
    let matches = collection.filter(
      (v: MeasurementSetValue) =>
        v.measurement === val.measurement && v.key === val.key
    );

    if (matches.length < 1) {
      return [...collection, val];
    }

    if (isNaN(val.value)) {
      return collection.filter((item) => item !== matches[0]);
    } else {
      return [...collection.filter((item) => item !== matches[0]), val];
    }
  } else {
    return [val];
  }
}
