import React from "react";

import { TextAreaProps } from "../TextArea";

export interface InputProps extends TextAreaProps {
  type?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  min?: number;
}

const Input = ({
  type = "text",
  placeholder,
  meta: { active = true } = {},
  error,
  ...rest
}: InputProps) => (
  <input
    type={type}
    placeholder={active ? placeholder : undefined}
    {...rest}
    value={rest.value === null ? "" : rest.value}
  />
);

export default Input;
