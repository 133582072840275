import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

import { LOCAL_DASHBOARD } from "../../constants/urls";

interface Props extends RouteProps {
  isAuth: boolean;
  component: any;
}

const GuestRoute = ({ component: Component, isAuth, ...rest }: Props) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth ? <Redirect to={LOCAL_DASHBOARD} /> : <Component {...props} />
    }
  />
);

export default GuestRoute;
