import React from "react";

import NavigationItem from "./NavigationItem";
import tableGrid from "../../assets/images/ic-table-grid.svg";
import { LOCAL_DOC_CASES } from "../../constants/urls";

import "./styles.scss";

interface IProps {
  onClick: () => void;
}

export default function DocItemsComponent({ onClick }: IProps) {
  return (
    <NavigationItem
      image={tableGrid}
      text="Doc cases"
      to={LOCAL_DOC_CASES}
      onClick={onClick}
    />
  );
}
