export enum ClinicalExam {
  Extraoral = "Extraoral",
  Intraoral = "Intraoral",
}

export type SoapNoteModel = string | ClinicalExam | undefined;

export type SoapNotesValuesType = { [key in SoapNotesType]: SoapNoteModel };

export enum SoapNotesType {
  chiefComplaint = "chiefComplaint",
  location = "location",
  severity = "severity",
  quality = "quality",
  duration = "duration",
  timing = "timing",
  context = "context",
  modifyingFactors = "modifyingFactors",
  medicalHistory = "medicalHistory",
  medications = "medications",
  lastFoodFluids = "lastFoodFluids",
  exerciseActivity = "exerciseActivity",
  clinicalExam = "clinicalExam",
  assessment = "assessment",
  plan = "plan",
  waitingPeriod = "waitingPeriod",
}
