import React from "react";
import "../styles.scss";
import {
  Measurement,
  MeasurementSetValue,
  MeasurementValue,
} from "../../../models/basicModels";
import { TextField } from "../../Form/TextField";
import DatePicker from "../../Form/DatePicker";

const DEFAULT_VALUE: string = "00.00";

interface Props {
  measurement: Measurement;
  values: any;
  onChange: (measurementValue: MeasurementSetValue) => void;
  hideDataPicker: boolean;
  className: string;
  validate?: boolean;
  disabled: boolean;
}

class MeasurementsValue extends React.Component<Props> {
  static defaultProps = {
    values: {},
    hideDataPicker: false,
    className: "",
    validate: false,
    disabled: false,
  };

  state = {
    date: undefined,
  };

  render(): React.ReactNode {
    const {
      measurement,
      values,
      hideDataPicker,
      className,
      validate,
      disabled,
    } = this.props;

    const classNames = "measurement_values__item " + className;

    return (
      <div className={classNames}>
        <img
          className="measurement_values__item__icon"
          src={measurement.icon}
          alt="measurement-icon"
        />
        {!hideDataPicker && (
          <div className="measurement_values__item__data_picker">
            <DatePicker
              title={`${measurement.type} alert date and time`}
              onChange={this.handleDateChange}
            />
          </div>
        )}
        <div className="measurement_values__item__list">
          {measurement.values.map((item: MeasurementValue, index: number) => (
            <div
              key={index}
              className="measurement_values__item__list__container"
            >
              <label
                htmlFor={item.name}
                className="measurement_values__item__list__container__label"
              >
                {item.name}
              </label>
              <div className="measurement_values__item__list__container__value">
                <TextField
                  key={`${item.name}-${index}`}
                  id={`${item.name}-${index}`}
                  name={item.name}
                  type="text"
                  disabled={disabled}
                  value={
                    isNaN(values[item.name]) ? undefined : values[item.name]
                  }
                  notValid={
                    validate &&
                    (isNaN(values[item.name]) ||
                      values[item.name] === undefined)
                  }
                  onChange={this.measurementValueSet}
                  placeholder={DEFAULT_VALUE}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  measurementValueSet = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    this.props.onChange &&
      this.props.onChange({
        measurement: this.props.measurement,
        key: e.target.name,
        value: parseFloat(e.target.value),
        date: this.state.date,
      });
  };

  handleDateChange = (value: string) => {
    this.setState({ date: value }, () => console.log(this.state.date));
  };
}

export default MeasurementsValue;
