import React from "react";
import classnames from "classnames";

import "./styles.scss";
import Input, { InputProps } from "../Input";

export interface Props extends InputProps {
  label?: string | React.ReactNode;
  name: string;
  checked?: boolean;
  icon?: string;
}

export function Checkbox(props: Props) {
  const {
    id,
    label,
    className,
    value,
    meta: { error = "" } = {},
    tabIndex,
    checked,
    icon,
  } = props;

  const classes = classnames(
    { checkbox__wrapper__error: !!error },
    "checkbox__wrapper",
    "flex flex-col",
    className
  );

  const newProps = {
    ...props,
    type: "checkbox",
    value,
    checked,
    tabIndex: undefined,
  };

  return (
    <div style={{ opacity: checked ? 1 : 0.6 }} className={classes}>
      <label htmlFor={id} tabIndex={tabIndex} className="checkbox__label">
        <Input {...newProps} className="checkbox__input" />
        <span className="checkbox__mark" />
        {icon && <img src={icon} alt="icon" className="checkbox__icon" />}
        {label}
      </label>
      {error && <div className="text__error">{error}</div>}
    </div>
  );
}
export default Checkbox;
