import React from "react";

import "./styles.scss";
import Loader from "../Loader";
import { FormikProps, Form } from "formik";
import spinnerIcon from "../../assets/images/ic-spinner-white.svg";
import { ValuesType } from "./ResetPasswordModal.types";
import FormikInputField from "../Form/FormikInputField";
import { FIELD_NAMES } from "./ResetPasswordModal.constants";
import { useCancelHandler } from "./ResetPasswordModal.hooks";
import InputEye from "../InputEye";

interface IProps extends FormikProps<ValuesType> {
  loading: boolean;
  error?: string | string[];
  closeModal: () => void;
  showPassword: boolean;
  toogleShowPassword: () => void;
}

export default function ResetPasswordComponent({
  resetForm,
  isValid,
  loading,
  showPassword,
  closeModal,
  toogleShowPassword,
}: IProps) {
  const onCancel = useCancelHandler(closeModal, resetForm);
  return (
    <div className="reset-password">
      {loading && <Loader />}
      <Form className="reset-password__form">
        <div className="reset-password__input_element reset-password__input_password">
          <FormikInputField
            name={FIELD_NAMES.PASSWORD}
            id="password"
            type={showPassword ? "text" : "password"}
            label="Enter old password"
            placeholder=""
          />
          <InputEye showEye={showPassword} toogleEye={toogleShowPassword} />
        </div>
        <div className="reset-password__input_element reset-password__input_password">
          <FormikInputField
            name={FIELD_NAMES.NEW_PASSWORD}
            id="newPassword"
            type={showPassword ? "text" : "password"}
            label="Enter new password"
            placeholder=""
          />
          <InputEye showEye={showPassword} toogleEye={toogleShowPassword} />
        </div>
        <div className="reset-password__input_element reset-password__input_password">
          <FormikInputField
            name={FIELD_NAMES.REPEAT_PASSWORD}
            id="rePassword"
            type={showPassword ? "text" : "password"}
            label="Repeat password"
            placeholder=""
          />
          <InputEye showEye={showPassword} toogleEye={toogleShowPassword} />
        </div>
        <div className="buttons-wrap">
          <button
            className="buttons-wrap__button"
            onClick={onCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading || !isValid}
            className="buttons-wrap__button buttons-wrap__button_primary"
          >
            {!loading ? (
              "Continue"
            ) : (
              <img
                src={spinnerIcon}
                className="screen-wrapper__actions__loader"
                alt="loader"
              />
            )}
          </button>
        </div>
      </Form>
    </div>
  );
}
