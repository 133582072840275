export enum MEASUREMENT_TYPES {
  BLOOD_PRESSURE_SYSTOLIC = "blood_pressure_SYS",
  BLOOD_PRESSURE_DIASTOLIC = "blood_pressure_DIA",
  BLOOD_PRESSURE_PULSE = "blood_pressure_HB",
  BLOOD_GLUCOSE_BG = "blood_glucose_BG",
  THERMOMETER = "thermometer_C",
  BODY_SCALE = "body_scale_KG",
  PULSE_OXIMETER = "pulse_oxymeter_O",
  SUMMARY_BP = "summary_bp",
}

export const DATA_ITEMS = {
  SYSTOLIC: "systolic",
  DIASTOLIC: "diastolic",
  PULSE: "pulse",
  GLUCOSE: "glucose",
  THERMOMETER: "thermometer",
  SCALE: "scale",
  PULSE_OXIMETER: "pulseOximeter",
  SUMMARY_BP: "summaryBp",
};

const measurementTypesList = [
  MEASUREMENT_TYPES.BLOOD_GLUCOSE_BG,
  MEASUREMENT_TYPES.BLOOD_PRESSURE_PULSE,
  MEASUREMENT_TYPES.BLOOD_PRESSURE_DIASTOLIC,
  MEASUREMENT_TYPES.BLOOD_PRESSURE_SYSTOLIC,
  MEASUREMENT_TYPES.THERMOMETER,
  MEASUREMENT_TYPES.BODY_SCALE,
  MEASUREMENT_TYPES.SUMMARY_BP,
] as const;

export const MEASUREMENT_TYPES_TO_ITEMS = {
  [MEASUREMENT_TYPES.BLOOD_PRESSURE_SYSTOLIC]: DATA_ITEMS.SYSTOLIC,
  [MEASUREMENT_TYPES.BLOOD_PRESSURE_DIASTOLIC]: DATA_ITEMS.DIASTOLIC,
  [MEASUREMENT_TYPES.BLOOD_PRESSURE_PULSE]: DATA_ITEMS.PULSE,
  [MEASUREMENT_TYPES.BLOOD_GLUCOSE_BG]: DATA_ITEMS.GLUCOSE,
  [MEASUREMENT_TYPES.THERMOMETER]: DATA_ITEMS.THERMOMETER,
  [MEASUREMENT_TYPES.BODY_SCALE]: DATA_ITEMS.SCALE,
  [MEASUREMENT_TYPES.PULSE_OXIMETER]: DATA_ITEMS.PULSE_OXIMETER,
  [MEASUREMENT_TYPES.SUMMARY_BP]: DATA_ITEMS.SUMMARY_BP,
};

export default measurementTypesList;

export type measurementsOldNames = typeof measurementTypesList[number];

export enum MEASUREMENT_GROUPS {
  BLOOD_PRESSURE = "BLOOD_PRESSURE",
  BLOOD_GLUCOSE = "BLOOD_GLUCOSE",
  BODY_SCALE = "BODY_SCALE",
  PULSE_OXIMETER = "PULSE_OXIMETER",
  TEMPERATURE = "TEMPERATURE",
}
