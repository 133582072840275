import actions from "../../constants/actionsTypes";
import { IClients } from "../reducers/clients";

export const getClientsStart = () => ({
  type: actions.clients.start,
});

export const getClientsSuccess = (payload: IClients) => ({
  type: actions.clients.success,
  payload,
});

export const getClientsFailure = (error: any) => ({
  type: actions.clients.fail,
  payload: error,
});
