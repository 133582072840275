import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { taskTimerPause } from "../../store/actions/taskTimer";
import TIMER_STATES from "../../constants/timerStates";
import { useTaskTimer, useStartTimerHandler } from "../../hooks";

function useUpdateDuration() {
  const { startTimeHistory, duration } = useTaskTimer();

  return useCallback(
    (data: Date) => {
      const lastStartTime = [...startTimeHistory].pop()?.getTime();
      const memoized = lastStartTime
        ? data.getTime() - lastStartTime + Number(duration)
        : duration;
      return memoized;
    },
    [duration, startTimeHistory]
  );
}


export function usePauseTimerHandler() {
  const dispatch = useDispatch();
  const getDuration = useUpdateDuration();
  const { state } = useTaskTimer();

  return useCallback(() => {
    if (TIMER_STATES.PAUSED !== state && state !== TIMER_STATES.INITIAL) {
      dispatch(
        taskTimerPause({
          duration: getDuration(new Date()),
          state: TIMER_STATES.PAUSED,
        })
      );
    }
  }, [dispatch, getDuration, state]);
}

export function useTimeCounter() {
  const { duration, state } = useTaskTimer();
  const [time, setTime] = useState(duration);
  const getDuration = useUpdateDuration();
  useEffect(() => {
    const timerId = setInterval(() => {
      if (state === TIMER_STATES.STARTED) {
        setTime(() => {
          return getDuration(new Date());
        });
      }
    }, 1000);
    if (state === TIMER_STATES.INITIAL) {
      setTime(0);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [setTime, state, getDuration]);
  return time;
}


export function useStartIconHandler() {
  const { state } = useTaskTimer();
  const onStart = useStartTimerHandler();

  return useCallback(() => {
    if (
      state === TIMER_STATES.INITIAL ||
      state === TIMER_STATES.PAUSED ||
      state === TIMER_STATES.STOPPED
    ) {
      return onStart();
    }
  }, [state, onStart]);
}
