import { useEffect, useRef } from "react";

const loaderDOMElement: HTMLElement | null = document.getElementById("loader");

export function useElement() {
  return useRef(document.createElement("div"));
}
export function useLoader() {
  const el = useElement()?.current;
  useEffect(() => {
    loaderDOMElement !== null && loaderDOMElement.appendChild(el);
    return () => {
      loaderDOMElement !== null && loaderDOMElement.removeChild(el);
    };
  }, [el]);
  return el
}
