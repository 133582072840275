import { MeasurementSetValue, Measurement } from "../models/basicModels";

export default function groupMeasurementValues(
  selectedMeasurements: MeasurementSetValue[]
) {
  let measurements = [];
  for (let selected of selectedMeasurements) {
    let _values = selectedMeasurements
      .filter(
        (val: MeasurementSetValue) =>
          val.measurement.type === selected.measurement.type
      )
      .map((val: MeasurementSetValue) => ({
        measurement: val.key,
        value: val.value,
      }));

    const findIndex = measurements.findIndex(
      (item) => item.type === selected.measurement.type
    );
    if (findIndex < 0) {
      measurements.push({
        type: selected.measurement.type,
        values: _values,
      });
    }
  }
  return measurements;
}

export function groupMeasurementWithOriginValues(
  selectedMeasurements: Measurement[],
  origin: MeasurementSetValue[]
) {
  let measurements = [];
  if (origin.length > 0) {
    for (let selected of selectedMeasurements) {
      let _values = origin
        .filter(
          (val: MeasurementSetValue) => val.measurement.type === selected.type
        )
        .map((val: MeasurementSetValue) => ({
          measurement: val.key,
          value: val.value,
        }));

      let _date = origin.filter(
        (val: MeasurementSetValue) => val.measurement.type === selected.type
      )[0].date;

      measurements.push({
        type: selected.type,
        values: _values,
        date: _date,
      });
    }
  }

  return measurements;
}
