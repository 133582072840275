import actions from "../../constants/actionsTypes";

export interface IPcpRateData {
  allPatients: number | null;
  needWelcomeCall: number | null;
  noReadings: number | null;
  alerts: number | null;
  complianceRate: number | null;
}

export interface IPcpRate {
  data: IPcpRateData;
  loading: boolean;
  error: any;
}

const initialState: IPcpRate = {
  data: {
    allPatients: null,
    needWelcomeCall: null,
    noReadings: null,
    alerts: null,
    complianceRate: null
  },
  loading: false,
  error: null,
};

export default function patient(
  state = initialState,
  { payload, type }: { payload: IPcpRate; type: string }
) {
  switch (type) {
    case actions.pcpRate.get.start:
      return {
        ...state,
        loading: true,
      };
    case actions.pcpRate.get.success:
      return {
        ...state,
        ...payload,
        error: null,
        loading: false,
      };
    case actions.pcpRate.get.fail:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case actions.pcpRate.reset:
      return {
        ...initialState,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
}
