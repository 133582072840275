import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";

export interface IPhysician {
  firstName?: string;
  lastName?: string;
  phone?: string;
  uuid: string;
  email: string
}

export interface IPhysicians {
  list?: IPhysician[];
  error?: any;
  loading?: boolean;
}

const initialState: IPhysicians = {
  list: [],
  error: null,
  loading: false,
};

export default function Physicians(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.physician.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.physician.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.physician.fail:
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
