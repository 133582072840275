import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";
import { IDevice } from "../../models/basicModels";
export type { IDevice } from "../../models/basicModels";

export interface IDevices {
  list: { [key: string]: IDevice };
  error?: any;
  loading?: boolean;
}

const initialState: IDevices = {
  list: {},
  error: null,
  loading: false,
};

export default function Devices(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.devices.success:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.devices.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.devices.reset:
      return initialState;
    default:
      return state;
  }
}
