import { Measurement } from "../models/basicModels";
import measurementsOldNames from "./measurementsOldNames";
import { getMeasurementIconByOldName } from "../utils/getMeasurementIcon";

const measurementsDefaultData: Measurement[] = [
  {
    type: measurementsOldNames.blood_pressure,
    icon: getMeasurementIconByOldName(measurementsOldNames.blood_pressure),
    values: [
      {
        name: "Systolic",
        minValue: 0,
        maxValue: 0,
      },
      {
        name: "Diastolic",
        minValue: 0,
        maxValue: 0,
      },
      {
        name: "Pulse",
        minValue: 0,
        maxValue: 0,
      },
    ],
    orderNumber: 0,
  },
  {
    type: measurementsOldNames.ecg,
    icon: getMeasurementIconByOldName(measurementsOldNames.ecg),
    values: [
      {
        name: "Beats/min",
        minValue: 0,
        maxValue: 0,
      },
    ],
    orderNumber: 1,
  },
  {
    type: measurementsOldNames.glucose,
    icon: getMeasurementIconByOldName(measurementsOldNames.glucose),
    values: [
      {
        name: "Level, mg/dL",
        minValue: 0,
        maxValue: 0,
      },
    ],
    orderNumber: 2,
  },
  {
    type: measurementsOldNames.scale,
    icon: getMeasurementIconByOldName(measurementsOldNames.scale),
    values: [
      {
        name: "Weight, lb",
        minValue: 0,
        maxValue: 0,
      },
    ],
    orderNumber: 3,
  },
  {
    type: measurementsOldNames.temperature,
    icon: getMeasurementIconByOldName(measurementsOldNames.temperature),
    values: [
      {
        name: "Temp, F",
        minValue: 0,
        maxValue: 0,
      },
    ],
    orderNumber: 4,
  },
];
export default measurementsDefaultData;
