import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import rootSaga from "./store/sagas";
import App from "./components/App";
import rootReducer, { AppState } from "./store/reducers";
import * as serviceWorker from "./serviceWorker";
import { setDefaultAxios, setAuthorizationHeader } from "./utils/axios";
import { HeaderProvider } from "./context/header";
import "./assets/css/responsive.scss";
import "./assets/css/print.scss";
import "./assets/css/styles.scss";
import "./assets/css/index.css"; // tailwind

const sagaMiddleware = createSagaMiddleware();
const store: Store<AppState> = createStore(
  rootReducer,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(sagaMiddleware))
    : composeWithDevTools(applyMiddleware(sagaMiddleware)) //applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(rootSaga);

setDefaultAxios(store.dispatch);
setAuthorizationHeader(localStorage.getItem("success_token"));
localStorage.setItem("build v:", "0.0.1");

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <HeaderProvider>
        <App />
      </HeaderProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
