import React, { ReactNodeArray, ReactNode } from "react";
import { StyleSheet } from "@react-pdf/renderer";

import Row from "./Row.component";;

const styles = StyleSheet.create({
  row: {
    backgroundColor: "#D3E2F1",
  }
});

interface IProps {
  children: ReactNodeArray | ReactNode;
}

function TableHeader({ children }: IProps) {
  return <Row style={styles.row}>{children}</Row>;
}

export default TableHeader;
