import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";

import { usePortal } from "./Modal.hooks";
import ModalContent from "./ModalContent.component";
import { useCloseOnEsc } from "./Modal.hooks";

export interface IProps {
  children?: ReactNode;
  isDisabled: boolean;
  onClose?: () => void;
  title: string;
  className?: string;
  classNames?: { body: string };
  usePortal: boolean;
}

export function Modal({ isDisabled, className, classNames, ...rest }: IProps) {
  const classes = classnames("modal", className, {
    "modal--disabled": isDisabled,
  });
  const bodyClasses = classnames("modal__body", classNames?.body);
  const el = usePortal("#root");
  const onEsc = useCloseOnEsc(rest.onClose);
  return (
    <>
      {usePortal &&
        !isDisabled &&
        ReactDOM.createPortal(
          <ModalContent
            classes={classes}
            bodyClasses={bodyClasses}
            {...rest}
            onEsc={onEsc}
            
          />,
          el
        )}
      {!usePortal && !isDisabled && (
        <ModalContent
          classes={classes}
          bodyClasses={bodyClasses}
          {...rest}
          onEsc={onEsc}
        />
      )}
    </>
  );
}

Modal.defaultProps = {
  className: "",
  classNames: {
    body: "",
  },
  usePortal: false,
};

export default Modal;
