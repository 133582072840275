import axios from "axios";
import { put, select } from "redux-saga/effects";
import { createSelector } from "reselect";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";
import { compose } from "redux";
import { FILE_FORMATS } from "../../components/SumaryReport/SummaryReport.constants";

import { errorNotify } from "../../components/Toaster/toster";
import { recognizeErrorMessage } from "../../utils/errors";
import { API_SUMMARY_REPORT } from "../../constants/urls";

import { AppState } from "../reducers";
import { ISummaryReport, IReport } from "../reducers/summaryReport";
import {
  getSummaryReportFailure,
  getSummaryReportSuccess,
  sortSummaryReportStart,
  sortSummaryReportSuccess,
} from "../actions/summaryReport";
import { ValuesType } from "../../components/SumaryReport/SummaryReport.types";
import { clientsSelector } from "./clients";
import { IClient } from "../reducers/clients";
import {
  downloadreportByFormatType,
  replaceNulls,
  renamePulseOxymeterItem,
} from "./utils";
import sortSummaryReport from "../../utils/sortSummaryReport";
import showDuration from "../../utils/showDuration";
interface IPayload extends ValuesType {
  format?: FILE_FORMATS;
}

export const summaryReportListSelector = createSelector(
  ({ summaryReport }: AppState): ISummaryReport => summaryReport,
  (report) => report.list
);

export const summaryReportSelector = createSelector(
  ({ summaryReport }: AppState): ISummaryReport => summaryReport,
  (report) => report
);

export function* getSummaryReportSaga({
  payload: { format, createDateGte, createDateLte, clientId },
}: {
  type: string;
  payload: IPayload;
}) {
  const params = snakeCaseKeys({
    createDateGte: new Date(createDateGte.setUTCHours(0, 0, 0, 0)),
    createDateLte: new Date(createDateLte.setUTCHours(23, 59, 59, 0)),
    clientId,
  });
  try {
    const clients: IClient[] = yield select(clientsSelector);
    const summaryReports: ISummaryReport = yield select(summaryReportSelector);

    const { data } = yield axios.get(API_SUMMARY_REPORT, {
      params,
    });
    const reportList = camelCaseKeys(data, {
      deep: true,
    }) as IReport[];

    const summaryList = compose(
      replaceNulls,
      renamePulseOxymeterItem
    )(reportList);

    yield downloadreportByFormatType({
      clients,
      clientId,
      format: format || "",
      reportList: sortSummaryReport(summaryList, summaryReports.sortBy).map(
        (item) => {
          return { ...item, timer: showDuration(item.timer) };
        }
      ),
      createDateGte,
      createDateLte,
    });

    yield put(
      getSummaryReportSuccess({
        list: summaryList,
        createDateGte,
        createDateLte,
        clientId,
        showNoDataModal: !summaryList.length,
      })
    );
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put(getSummaryReportFailure(errorMsg));
    errorNotify(errorMsg[0]);
  }
}

export function* sortSummaryReportSaga({
  payload,
}: ReturnType<typeof sortSummaryReportStart>) {
  const summaryReports = yield select(summaryReportSelector);
  yield put(
    sortSummaryReportSuccess({
      ...summaryReports,
      sortBy: payload,
    })
  );
}
