import React, { ReactNode } from "react";

import Routes from "../../routes";
import Modal from "../Modal";
import { Button } from "../Form/Button";
import { useResetPassword } from "../../hooks";
import { useManageModal, useModal } from "./Content.hooks";

import "./Content.styles.scss";

interface IProps {
  children: ReactNode;
}

function Content({ children }: IProps) {
  const { success } = useResetPassword();
  const { toggleModal, showModal, setShowModal } = useModal();
  useManageModal(setShowModal, success);

  return (
    <div className="content mt-4">
      <div className="mx-4 md:mx-0">
        {children}
        <Routes />
        <Modal
          isDisabled={!showModal}
          title="Password changed"
          onClose={toggleModal}
        >
          <p>Your password has been changed successfully.</p>
          <div className="buttons-timeout-logout">
            <Button onClick={toggleModal} type="primary" value="ok" />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Content;
