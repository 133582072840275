import axios from "axios";
import { put, call, select } from "redux-saga/effects";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";
import { createSelector } from "reselect";

import { Action, AppState } from "../reducers";
import { API_PATIENT, API_ZIP } from "../../constants/urls";
import actions from "../../constants/actionsTypes";
import { recognizeErrorMessage } from "../../utils/errors";
import { errorNotify, successNotify } from "../../components/Toaster/toster";
import {
  IAddPatientPayload,
  IUpdatePatientPayload,
  getCityStateByZipStart,
  getCityStateByZipSuccess,
} from "../actions/patient";
import {
  getPatientSuccess,
  addPatientSuccess,
  addPatientFail,
  updatePatientFail,
  updatePatientSuccess,
  getCityStateByZipFail,
} from "../actions/patient";
import { PatientData, PatientStore } from "../reducers/patient";
import { addPatientDevicesSaga } from "../../store/sagas/patientDevices";
import { physiciansSelector } from "./physicians";
import { IPhysician } from "../reducers/physicians";
import { serialize } from "object-to-formdata";

export const patientSelector = createSelector(
  ({ patient }: AppState) => patient,
  ({ data }) => data
);

export const patientLoadingSelector = createSelector(
  ({ patient }: AppState) => patient,
  ({ loading }) => loading
);

export const patientErrorSelector = createSelector(
  ({ patient }: AppState) => patient,
  ({ error }) => error
);
export const cityAndStateByZipSelector = createSelector(
  ({ patient }: AppState) => patient,
  ({ cityStateByZip }) => cityStateByZip
);

export function* getPatientSaga({ payload }: Action) {
  const uuid = payload.uuid || payload;
  try {
    const { data } = yield axios.get(`${API_PATIENT}${uuid}/`);
    if (!data.error) {
      const preparedData = camelCaseKeys(data, { deep: true });

      preparedData.address =
        preparedData.address && preparedData.address.address;
      yield put({
        type: actions.patient.success,
        payload: { data: preparedData },
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put({
      type: actions.patient.fail,
      payload: { errors: errorMsg },
    });
    errorNotify(errorMsg);
  }
}

export function* addPatientSaga({
  payload: { history, devices, ...payload },
}: {
  type: string;
  payload: IAddPatientPayload;
}) {
  try {
    const formData = serialize(snakeCaseKeys(payload));

    if (payload.insuranceImage) {
      //@ts-ignore
      formData.append("insurance_image", payload.insuranceImage);
    }

    if (payload.demographicSheetImage) {
      //@ts-ignore
      formData.append("demographic_sheet_image", payload.demographicSheetImage);
    }

    if (payload.backsiteInsuranceImage) {
      formData.append(
        "backsite_insurance_image",
        //@ts-ignore
        payload.backsiteInsuranceImage
      );
    }

    const { data, status } = yield axios.post(`${API_PATIENT}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      validateStatus: (status) => {
        return (
          Number(status) === 200 ||
          Number(status) === 201 ||
          Number(status) === 400
        );
      },
    });
    if (status === 400) {
      yield put(addPatientFail(camelCaseKeys(data)));
      throw new Error("Invalid data, please check your form");
    }
    const patient = camelCaseKeys(data, { deep: true });
    yield put(getPatientSuccess({ data: patient }));
    yield put(addPatientSuccess());
    yield call(successNotify, "Patient has been created");
    history.replace(`${history.location.pathname}/${data.uuid}`);
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    errorNotify(errorMsg);
  }
}

export function* updatePatientSaga({
  payload: { devices, ...payload },
}: {
  type: string;
  payload: IUpdatePatientPayload;
}) {
  try {
    const formData = serialize(snakeCaseKeys(payload));

    //@ts-ignore
    if (payload.insuranceImage) {
      //@ts-ignore
      formData.append("insurance_image", payload.insuranceImage);
    }

    if (payload.demographicSheetImage) {
      //@ts-ignore
      formData.append("demographic_sheet_image", payload.demographicSheetImage);
    }

    if (payload.backsiteInsuranceImage) {
      formData.append(
        "backsite_insurance_image",
        //@ts-ignore
        payload.backsiteInsuranceImage
      );
    }

    if (devices?.length) {
      yield addPatientDevicesSaga({
        payload: devices,
        type: actions.patientDevices.add.start,
      });
    }

    const patient: PatientData = yield select(patientSelector);
    const { data, status } = yield axios.patch(
      `${API_PATIENT}${patient.uuid}/`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        validateStatus: (status) => {
          return (
            Number(status) === 200 ||
            Number(status) === 201 ||
            Number(status) === 400
          );
        },
      }
    );
    if (status === 400) {
      yield put(updatePatientFail(camelCaseKeys(data)));
      throw new Error("Invalid data, please check your form");
    }
    const receivedData = camelCaseKeys(data, { deep: true });
    const physicians: IPhysician[] = yield select(physiciansSelector);
    if (typeof receivedData.physician === "string") {
      receivedData.physician = physicians.find(
        (item) => item.uuid === String(receivedData.physician)
      );
    }
    yield put(getPatientSuccess({ data: receivedData }));
    yield put(updatePatientSuccess());
    yield call(successNotify, "Patient has been updated");
  } catch (error) {
    console.log(error);
    const errorMsg = recognizeErrorMessage(error);
    console.log(errorMsg);
    errorNotify(errorMsg);
  }
}

export function* getCityStatebyZipSaga({
  payload,
}: ReturnType<typeof getCityStateByZipStart>) {
  try {
    const { data } = yield axios.get(`${API_ZIP}`, {
      params: snakeCaseKeys(payload),
    });
    const cityandState = camelCaseKeys(
      data?.pop()
    ) as PatientStore["cityStateByZip"];
    yield put(getCityStateByZipSuccess(cityandState || null));
  } catch (error) {
    yield put(getCityStateByZipFail(error));
  }
}
