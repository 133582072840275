import { toast } from "react-toastify";
import "./styles.scss";

export const successNotify = (text: string) => {
  toast(text, {
    className: "toaster-wrapper toaster-wrapper_success",
    bodyClassName: "toaster-wrapper__content",
  });
};

export const errorNotify = (text: string) => {
  toast(text, {
    className: "toaster-wrapper toaster-wrapper_error",
    bodyClassName: "toaster-wrapper__content",
  });
};

export const warningNotify = (text: string) => {
  toast(text, {
    className: "toaster-wrapper toaster-wrapper_warning",
    bodyClassName: "toaster-wrapper__content",
  });
};
