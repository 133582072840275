import actions from "../../constants/actionsTypes";
import { SearchPanelState } from "../../models/basicModels";

export interface PatientAction {
  uuid: string;
}

export const getPatients = () => ({
  type: actions.patients.list.get,
  // payload
});

export const resetPatients = () => ({
  type: actions.patients.list.reset,
});

export const getPatientsExportStart = (payload: Partial<SearchPanelState>) => ({
  type: actions.patients.export.get,
  payload,
});

export const getPatientsExportSuccess = () => ({
  type: actions.patients.export.success,
});

export const getPatientsExportFail = (payload?: any) => ({
  type: actions.patients.export.fail,
  payload,
});
