import axios from "axios";
import { put } from "redux-saga/effects";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";

import { Action } from "../reducers";

import actions from "../../constants/actionsTypes";
import {
  API_CASE,
  API_CASE_CHAIN,
  API_CASE_CREATE,
  API_CASE_FOLLOWUP,
  API_CASE_HOLDING,
  API_CASE_UNSUCCESSFUL,
  API_HISTORY_CASES,
  API_UNDER_REVIEW_CASES,
} from "../../constants/urls";
import { Case } from "../../models/basicModels";
import { InterventionState } from "../../components/Intervention";
import { recognizeErrorMessage } from "../../utils/errors";
import { successNotify, errorNotify } from "../../components/Toaster/toster";
import { groupMeasurementWithOriginValues } from "../../utils/groupMeasurementValues";

export function* getCaseSaga(action: Action) {
  try {
    const { data } = yield axios.get(`${API_CASE}/${action.payload.id}/`);
    if (!data.error) {
      yield put({
        type: actions.cases.view.success,
        payload: camelCaseKeys(data, { deep: true }),
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    yield put({
      type: actions.cases.view.fail,
      payload: recognizeErrorMessage(error),
    });
    errorNotify(recognizeErrorMessage(error));
  }
}

interface CreateCaseAction extends Action {
  payload?: InterventionState;
}

export function* createCaseSaga(action: CreateCaseAction) {
  if (action.payload === undefined || action.payload.patient === undefined) {
    return false;
  }

  const { interventionInfo, interventionSummary, diabeticTriage } =
    action.payload;

  let data = {
    status: action.payload.status,
    cause: action.payload.cause,
    summary: "",
    escalation: "",
    patientId: action.payload.patient.uuid,
    parentId: action.payload.parentId,
    doctorId: action.payload.doctorId,
    callResult: interventionInfo.callResult,
    measurements: [{}],
    diabeticTriage: {},
  };

  if (interventionSummary) {
    data.summary = interventionSummary.caseSummary;
    data.escalation = interventionSummary.escalation;
    if (
      interventionSummary.escalation &&
      interventionSummary.escalation.length === 0
    ) {
      delete data.escalation;
    }
    if (interventionSummary.caseSummary.length === 0) {
      delete data.summary;
    }
  }

  if (interventionInfo) {
    const step2Date = interventionInfo.step2Date;
    data.measurements = [];
    if (action.payload.cause === "intervention") {
      if (interventionInfo.measurementsValues.length) {
        data.measurements.push({
          step: 1,
          data: groupMeasurementWithOriginValues(
            interventionInfo.selectedMeasurements,
            interventionInfo.measurementsValues
          ),
          date: null,
        });
      }
      if (interventionInfo.measurementsReportedValues.length) {
        data.measurements.push({
          step: 2,
          data: groupMeasurementWithOriginValues(
            interventionInfo.selectedMeasurements,
            interventionInfo.measurementsReportedValues
          ),
          date: step2Date,
        });
      }
    } else {
      if (interventionInfo.measurementsReportedValues.length) {
        data.measurements.push({
          step: 1,
          data: interventionInfo.measurementsReportedValues,
          date: step2Date,
        });
      }

      if (interventionInfo.selectedMeasurements.length) {
        let measurements: any[] = [];
        interventionInfo.selectedMeasurements.forEach((value) => {
          measurements.push({
            type: value.type,
            values: [],
          });
        });

        data.measurements.push({
          step: 2,
          data: measurements,
          date: step2Date,
        });
      }

      if (interventionInfo.measurementsValues.length) {
        data.measurements.push({
          step: 3,
          data: interventionInfo.measurementsValues,
          date: null,
        });
      }
    }
  }

  if (data.measurements.length === 0) {
    delete data.measurements;
  }

  if (diabeticTriage) {
    data.diabeticTriage = diabeticTriage;
  }

  try {
    let response;
    if (action.payload.id) {
      response = yield axios.patch(
        `${API_CASE}/${action.payload.id}/`,
        snakeCaseKeys(data)
      );
    } else {
      response = yield axios.post(API_CASE_CREATE, snakeCaseKeys(data));
    }
    if (!response.data.error) {
      yield put({
        type: actions.cases.creation.success,
        payload: camelCaseKeys(response.data, { deep: true }),
      });
      successNotify("Success!");
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    yield put({
      type: actions.cases.creation.fail,
      payload: recognizeErrorMessage(error),
    });
    errorNotify(recognizeErrorMessage(error));
  }
}

export function* updateCaseSaga(action: Action) {
  if (action.payload === undefined) {
    return false;
  }

  const updatedCase: Case = { ...action.payload };

  delete updatedCase.doctorFirstName;
  delete updatedCase.doctorLastName;
  delete updatedCase.patient;

  yield put({
    type: actions.cases.update.start,
  });

  const changedResults = updatedCase.callResults.map((item) => ({
    comment: item.comment,
    date: item.date,
    result: item.result,
    medical_assistant__first_Name: item.medicalAssistantFirstName,
    medical_assistant__lastName: item.medicalAssistantFirstName,
  }));
  updatedCase.callResults = changedResults;
  try {
    yield axios.patch(
      `${API_CASE}/${action.payload.id}/`,
      snakeCaseKeys(updatedCase)
    );
    yield put({
      type: actions.cases.update.success,
    });
  } catch (e) {
    yield put({
      type: actions.cases.update.fail,
    });
  }
}

export function* getUnderReviewCases(action: Action) {
  try {
    const { data } = yield axios.get(API_UNDER_REVIEW_CASES, {
      params: action.payload,
    });
    if (!data.error) {
      yield put({
        type: actions.cases.success.review,
        payload: {
          data: camelCaseKeys(data.results, { deep: true }),
          count: data.count,
        },
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    yield put({
      type: actions.cases.view.fail,
      payload: recognizeErrorMessage(error),
    });
    errorNotify(recognizeErrorMessage(error));
  }
}

export function* getHistoryCases(action: Action) {
  try {
    const { data } = yield axios.get(API_HISTORY_CASES, {
      params: action.payload,
    });
    if (!data.error) {
      yield put({
        type: actions.cases.success.history,
        payload: {
          data: camelCaseKeys(data.results, { deep: true }),
          count: data.count,
        },
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    errorNotify(recognizeErrorMessage(error));
  }
}

export function* getFollowUpCases(action: Action) {
  try {
    const { data } = yield axios.get(API_CASE_FOLLOWUP, {
      params: action.payload,
    });
    if (!data.error) {
      yield put({
        type: actions.cases.success.followup,
        payload: {
          data: camelCaseKeys(data.results, { deep: true }),
          count: data.count,
        },
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    errorNotify(recognizeErrorMessage(error));
  }
}

export function* getHoldingCases(action: Action) {
  try {
    const { data } = yield axios.get(API_CASE_HOLDING, {
      params: action.payload,
    });
    if (!data.error) {
      yield put({
        type: actions.cases.success.holding,
        payload: {
          data: camelCaseKeys(data.results, { deep: true }),
          count: data.count,
        },
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    errorNotify(recognizeErrorMessage(error));
  }
}

export function* getUnsuccessfulCases(action: Action) {
  try {
    const { data } = yield axios.get(API_CASE_UNSUCCESSFUL, {
      params: action.payload,
    });
    if (!data.error) {
      yield put({
        type: actions.cases.success.unsuccessful,
        payload: {
          data: camelCaseKeys(data.results, { deep: true }),
          count: data.count,
        },
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    errorNotify(recognizeErrorMessage(error));
  }
}

export function* getCaseChain(action: Action) {
  try {
    const { data } = yield axios.get(`${API_CASE_CHAIN}/${action.payload}`);
    if (!data.error) {
      yield put({
        type: actions.cases.chain.success,
        payload: { data: camelCaseKeys(data, { deep: true }) },
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    yield put({
      type: actions.cases.chain.fail,
      payload: recognizeErrorMessage(error),
    });
    errorNotify(recognizeErrorMessage(error));
  }
}
