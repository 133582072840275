import { SORT_FIELDS } from "../../store/reducers/summaryReport";

import { SUMMARY_REPORT_FORM_FIELDS } from "../../constants/summaryReportFormFields";

export { SUMMARY_REPORT_FORM_FIELDS } from "../../constants/summaryReportFormFields";

const FROM_DATE = 7;
export const initialValues = {
  [SUMMARY_REPORT_FORM_FIELDS.CLIENT]: "",
  [SUMMARY_REPORT_FORM_FIELDS.FROM_DATE]: (() => {
    const day = new Date();
    day.setDate(day.getDate() - FROM_DATE);
    return day;
  })(),
  [SUMMARY_REPORT_FORM_FIELDS.TO_DATE]: new Date(),
};

export const SUMMARY_REPORT_HEADER = [
  {
    text: "First name",
  },
  {
    text: "Last name",
  },
  {
    text: "DOB",
  },
  {
    text: "Days with Readings",
    className: "hidden-mobile",
    sortField: SORT_FIELDS.daysWithReading,
  },
  {
    text: "Time tracked",
    className: "hidden-mobile",
    sortField: SORT_FIELDS.timer,
  },
  {
    text: "Total Readings Received",
    className: "hidden-mobile",
    sortField: SORT_FIELDS.allMeasurements,
  },
  {
    text: "Blood Pressure",
    className: "hidden-mobile",
  },
  {
    text: "Glucose",
    className: "hidden-mobile",
  },
  {
    text: "Temp",
    className: "hidden-mobile",
  },
  {
    text: "Weight",
    className: "hidden-mobile",
  },
  { text: "Pulse Ox", className: "hidden-mobile" },

];

export enum FILE_FORMATS {
  PDF = "PDF",
  CSV = "CSV",
}
