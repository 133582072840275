import axios from "axios";
import { put, select } from "redux-saga/effects";
import { createSelector } from "reselect";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";
import { compose } from "redux";

import { errorNotify } from "../../components/Toaster/toster";
import { recognizeErrorMessage } from "../../utils/errors";
import { API_DEVICES } from "../../constants/urls";

import { AppState } from "../reducers";
import { IDevices, IDevice } from "../reducers/devices";
import {
  getDevicesFailure,
  getDevicesSuccess,
  getDevicesStart,
} from "../actions/devices";

export const devicesSelector = createSelector(
  ({ devices }: AppState): IDevices => devices,
  (devices) => devices.list
);

export const devicesByDeviceTypeSelector = (deviceType: string) =>
  createSelector(
    ({ devices }: AppState): IDevices => devices,
    (devices) =>
      Object.values(devices.list).filter((item) => item.type === deviceType)
  );

export function buildDevicesList(list: IDevice[]) {
  return list.reduce((result, item) => {
    result[item.id] = item;
    return result;
  }, {} as { [key: string]: IDevice });
}

export function* getDevicesSaga({
  payload,
}: ReturnType<typeof getDevicesStart>) {
  const params = snakeCaseKeys(payload);
  const devices: IDevices = yield select(({ devices }) => devices);
  try {
    const { data } = yield axios.get(API_DEVICES, {
      params,
    });
    const list = compose(
      (list) => {
        return { ...devices.list, ...list };
      },
      buildDevicesList,
      (data: any) => camelCaseKeys(data, { deep: true })
    )(data);
    yield put(getDevicesSuccess({ list }));
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put(getDevicesFailure(errorMsg));
    errorNotify(errorMsg[0]);
  }
}
