import actions from "../../constants/actionsTypes";
import { IVitalAlerts } from "../reducers/vitalAlerts";

export const getVitalAlertsStart = (params: { [key: string]: string }) => ({
  type: actions.vitalAlerts.get.start,
  payload: params,
});

export const getVitalAlertsSuccess = (payload: IVitalAlerts) => ({
  type: actions.vitalAlerts.get.success,
  payload,
});

export const getVitalAlertsFailure = (error: any) => ({
  type: actions.vitalAlerts.get.fail,
  payload: error,
});

export const updateVitalAlertsStart = (payload: {
  onUpdateData: () => void;
  id: number,
  status: string
}) => ({
  type: actions.vitalAlerts.update.start,
  payload: payload,
});

export const updateVitalAlertsSuccess = (payload: IVitalAlerts) => ({
  type: actions.vitalAlerts.update.success,
  payload,
});

export const updateVitalAlertsFailure = (error: any) => ({
  type: actions.vitalAlerts.update.fail,
  payload: error,
});

export const resetVitalAlerts = () => ({
  type: actions.vitalAlerts.reset,
});
