import moment from "moment";

export default function showDuration(duration: number) {
  const days = moment.duration(duration, "milliseconds").days();
  const hours = moment.duration(duration, "milliseconds").hours();
  const minutes = moment.duration(duration, "milliseconds").minutes();
  const seconds = moment.duration(duration, "milliseconds").seconds();
  const hms = `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;
  return days ? `${days} d, ${hms}` : hms;
}
//or use https://github.com/EvanHahn/HumanizeDuration.js