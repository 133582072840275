export const recognizeErrorMessage = (error: any) => {
  if (error && error.message && !error.response) {
    return error.message;
  }
  if (
    error.response &&
    error.response.data &&
    error.response.data.non_field_errors
  ) {
    return error.response.data.non_field_errors;
  }

  return "Something went wrong, repeat attempt";
};
