import React, { memo } from "react";
import moment from "moment";

import startIcon from "../../assets/images/ic_start.svg";
import stopIcon from "../../assets/images/ic_stop.svg";
import pauseIcon from "../../assets/images/ic_pause.svg";
import {
  usePauseTimerHandler,
  useTimeCounter,
  useStartIconHandler,
} from "./TaskTimerPanel.hooks";

import "./TaskTimerPanel.styles.scss";
import TaskTimerModalForm from "../TaskTimerForm";
import { useTaskTimer } from "../../hooks";
import TIMER_STATES from "../../constants/timerStates";
import { usePatient, useStopTimerHandler } from "../../hooks";

function TaskTimerPanel() {
  const onStart = useStartIconHandler();
  const onStop = useStopTimerHandler();
  const onPause = usePauseTimerHandler();
  const time = useTimeCounter();
  const { state } = useTaskTimer();
  const showStart =
    state === TIMER_STATES.INITIAL ||
    state === TIMER_STATES.PAUSED ||
    state === TIMER_STATES.STOPPED;

  const showStop = state === TIMER_STATES.STARTED;
  const showPause = state === TIMER_STATES.STARTED;
  const auth = usePatient();
  return (
    <div className="task-timer-panel">
      <div className="flex flex-col items-center justify-center task-timer-panel__wrapper">
        <span className="task-timer-panel__time">
          {moment.utc(time || 0).format("HH:mm:ss")}
        </span>
        <span className="task-timer-panel__message mt-1">
          {state === TIMER_STATES.INITIAL && `Start tracking`}
          {(state === TIMER_STATES.PAUSED || state === TIMER_STATES.STOPPED) &&
            "Tap to resume tracking"}
          {state === TIMER_STATES.STARTED &&
            `Tracking - ${auth.firstName} ${auth.lastName}`}
        </span>
        <div className="flex mt-5">
          {showStart && (
            <img
              src={startIcon}
              onClick={onStart}
              className="cursor-pointer"
              alt="start"
            />
          )}

          <div>
            {showStop && (
              <img
                src={stopIcon}
                onClick={onStop}
                className="cursor-pointer mr-5"
                alt="stop"
              />
            )}
            {showPause && (
              <img
                src={pauseIcon}
                onClick={onPause}
                className="cursor-pointer"
                alt="pause"
              />
            )}
          </div>
        </div>
        {(state === TIMER_STATES.PAUSED || state === TIMER_STATES.STOPPED) && (
          <div
            className="task-timer-panel__finish mt-4 cursor-pointer"
            onClick={onStop}
          >
            Finish tracking
          </div>
        )}
        <TaskTimerModalForm />
      </div>
    </div>
  );
}
export default memo(TaskTimerPanel);
