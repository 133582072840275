import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";

export type JobType =
  | "medical_assistant"
  | "doctor"
  | null
  | "Primary_Care_Physician";

export interface Auth {
  token: string | null;
  id: number | null;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
  email: string;
  jobTitle: JobType;
  pcpUuid?: string;
}

export interface AuthState extends Auth {
  loading: boolean;
  errors: string[];
}

const initialState: AuthState = {
  token: null,
  id: null,
  firstName: null,
  lastName: null,
  avatar: null,
  email: "",
  jobTitle: null,
  loading: false,
  errors: [],
};

export default function auth(state: AuthState = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.login.signin.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.login.signin.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.login.signin.fail:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
