import { OptionProps } from "../models/basicModels";

const callResults: Array<OptionProps> = [
  { value: "ok", name: "Spoke to member" },
  { value: "voicemail", name: "Left voicemail" },
  { value: "care_team", name: "Spoke to care team" },
  { value: "message", name: "Left verbal message" },
  { value: "fail", name: "No answer" },
];
export default callResults;
