import React, { lazy, Suspense, memo } from "react";
import { Redirect, Route, Switch } from "react-router";

import Loader from "../components/Loader";
import AuthRoute from "../components/Routes/AuthRoute";
import GuestRoute from "../components/Routes/GuestRoute";
import CommonRoute from "../components/Routes/CommonRoute";
import {
  LOCAL_CASES,
  LOCAL_DASHBOARD,
  LOCAL_DOC_CASES,
  LOCAL_FORGOT,
  LOCAL_INTERVENTION,
  LOCAL_LOGIN,
  LOCAL_NEW_PASSWORD,
  LOCAL_OUTREACH,
  LOCAL_SEARCH,
  LOCAL_PATIENT,
  LOCAL_SUMMARY_REPORT,
  LOCAL_CHATS,
  LOCAL_PATIENT_REPORT,
  LOCAL_PCP,
  LOCAL_PROFILE,
} from "../constants/urls";
import SingleCase from "../components/Cases/SingleCase";
import WithBackButtonHeader from "../hoc/WithBackButtonHeader";
import { useAuth } from "../hooks";
import WithNavigationTaskTimerPrompt from "../hoc/WithNavigationTaskTimerPrompt";
import NavigationModal from "../components/ConfirmationModal";

const Login = lazy(() => import("../components/Login"));
const Forgot = lazy(() => import("../components/Forgot"));
const NewPassword = lazy(() => import("../components/NewPassword"));
const Dashboard = lazy(() => import("../components/Dashboard"));
const Intervention = lazy(() => import("../components/Intervention"));
const SearchPatient = lazy(() => import("../components/SearchPatient"));
const Outreach = lazy(() => import("../components/Outreach"));
const Cases = lazy(() => import("../components/Cases"));
const DocCases = lazy(() => import("../components/DocCases"));
const SingleDocCase = lazy(
  () => import("../components/DocCases/SingleDocCase/SingleDocCase")
);
const PatientForm = lazy(() => import("../components/PatientForm"));
const SummaryReport = lazy(() => import("../components/SumaryReport"));
const Chats = lazy(() => import("../components/Chats"));
const PatientReport = lazy(() => import("../components/PatientReport"));
const PrimaryCarePhysicianForm = lazy(
  () => import("../components/PrimaryCarePhysicianForm")
);
const Patient = lazy(() => import("../components/Patient"));

const TASK_TIMER_ALLOW_ROUTES = [
  LOCAL_PROFILE,
  `${LOCAL_INTERVENTION}/:uuid/:caseID?`,
  `${LOCAL_OUTREACH}/:uuid/:caseID?`,
  LOCAL_PATIENT,
];

function Routes() {
  const { token } = useAuth();
  const isAuth = token !== null && token.length > 0;

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <GuestRoute
          isAuth={isAuth}
          exact={true}
          path={LOCAL_LOGIN}
          component={Login}
        />
        <GuestRoute
          isAuth={isAuth}
          exact={true}
          path={LOCAL_FORGOT}
          component={Forgot}
        />
        <GuestRoute
          isAuth={isAuth}
          exact={false}
          path={LOCAL_NEW_PASSWORD}
          component={NewPassword}
        />
        <CommonRoute
          exact={false}
          path={LOCAL_PCP}
          component={PrimaryCarePhysicianForm}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={LOCAL_SEARCH}
          component={SearchPatient}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={LOCAL_PROFILE}
          component={() => (
            <WithNavigationTaskTimerPrompt
              Modal={NavigationModal}
              allowRoutes={TASK_TIMER_ALLOW_ROUTES}
              modalProps={{
                title:
                  "Do you want to stop tracker befor leaving the Patient page?",
                cancelButtonText: "Continue with tracker",
                confirmButtonText: "Stop tracker",
                message:
                  "You can keep the tracker enable while browsing the patient profile, outreach or intervention",
                className: "max-w-xs",
              }}
            >
              <Patient />
            </WithNavigationTaskTimerPrompt>
          )}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={LOCAL_PATIENT}
          component={() => (
            <WithNavigationTaskTimerPrompt
              Modal={NavigationModal}
              allowRoutes={TASK_TIMER_ALLOW_ROUTES}
              modalProps={{
                title:
                  "Do you want to stop tracker befor leaving the Patient page?",
                cancelButtonText: "Continue with tracker",
                confirmButtonText: "Stop tracker",
                message:
                  "You can keep the tracker enable while browsing the patient profile, outreach or intervention",
                className: "max-w-xs",
              }}
            >
              <PatientForm />
            </WithNavigationTaskTimerPrompt>
          )}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={LOCAL_DASHBOARD}
          component={Dashboard}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={`${LOCAL_INTERVENTION}/:uuid/:caseID?`}
          component={() => (
            <WithBackButtonHeader title="Intervention">
              <WithNavigationTaskTimerPrompt
                Modal={NavigationModal}
                PushModal={NavigationModal}
                allowRoutes={TASK_TIMER_ALLOW_ROUTES}
                isPushModal
                modalProps={{
                  title:
                    "Do you want to stop tracker befor leaving the Intervention page?",
                  cancelButtonText: "Continue with tracker",
                  confirmButtonText: "Stop tracker",
                  message:
                    "You can keep the tracker enable while browsing the patient profile, outreach or intervention",
                  className: "max-w-xs",
                }}
                pushModalProps={{
                  title: "Do you want to start task timer?",
                  message: "",
                  cancelButtonText: "Cancel",
                  confirmButtonText: "Ok",
                }}
              >
                <Intervention />
              </WithNavigationTaskTimerPrompt>
            </WithBackButtonHeader>
          )}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={`${LOCAL_OUTREACH}/:uuid/:caseID?`}
          component={() => (
            <WithBackButtonHeader title="Outreach">
              <WithNavigationTaskTimerPrompt
                Modal={NavigationModal}
                PushModal={NavigationModal}
                allowRoutes={TASK_TIMER_ALLOW_ROUTES}
                isPushModal
                modalProps={{
                  title:
                    "Do you want to stop tracker befor leaving the Outreach page?",
                  cancelButtonText: "Continue with tracker",
                  confirmButtonText: "Stop tracker",
                  message:
                    "You can keep the tracker enable while browsing the patient profile, outreach or intervention",
                  className: "max-w-xs",
                }}
                pushModalProps={{
                  title: "Do you want to start task timer?",
                  message: "",
                  cancelButtonText: "Cancel",
                  confirmButtonText: "Ok",
                }}
              >
                <Outreach />
              </WithNavigationTaskTimerPrompt>
            </WithBackButtonHeader>
          )}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={LOCAL_CASES}
          component={Cases}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={`${LOCAL_CASES}/:id`}
          component={SingleCase}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={`${LOCAL_CASES}/:id/view`}
          component={() => <SingleCase isReadOnly={true} />}
        />

        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={LOCAL_DOC_CASES}
          component={DocCases}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={`${LOCAL_DOC_CASES}/:id`}
          component={SingleDocCase}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={`${LOCAL_DOC_CASES}/:id/view`}
          component={() => <SingleDocCase isReadOnly={true} />}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={`${LOCAL_SUMMARY_REPORT}/`}
          component={SummaryReport}
        />
         <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={`${LOCAL_CHATS}/`}
          component={Chats}
        />
        <AuthRoute
          isAuth={isAuth}
          exact={true}
          path={`${LOCAL_PATIENT_REPORT}/:id`}
          component={PatientReport}
        />
        <Route
          exact
          path="/"
          render={() => (
            <Redirect to={isAuth ? LOCAL_DASHBOARD : LOCAL_LOGIN} />
          )}
        />
        <Route
          exact
          component={() => <div>404 component or redirect to home</div>}
        />
      </Switch>
    </Suspense>
  );
}

export default memo(Routes);
