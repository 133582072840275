import actionTypes from "../../constants/actionsTypes";
import { Action } from "./index";
import { INote } from "../../models/basicModels";
export type { INote } from "../../models/basicModels";

export interface INotes {
  data?: INote[];
  error?: any;
  loading?: boolean;
  loadingTable?: boolean;
  count?: number | null;
}

const initialState: INotes = {
  data: [],
  error: null,
  loading: false,
  count: null,
};

export default function notes(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.notes.get.start:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.notes.get.success:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.notes.get.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.notes.add.start:
      return {
        ...state,
      };
    case actionTypes.notes.add.success:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.notes.add.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.notes.update.start:
      return {
        ...state,
      };
    case actionTypes.notes.update.success:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.notes.update.fail:
      return {
        error: action.payload,
        loading: false,
      };
    case actionTypes.notes.reset:
      return initialState;
    default:
      return state;
  }
}
