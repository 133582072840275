import React from "react";

import DatePicker, { Props as DatePickerProps } from "../DatePicker";
import {
  useDatePickerField,
  useDateValue,
  useFormattedDateTime,
} from "./FormikDatePickerField.hooks";

interface IDatePickerField extends DatePickerProps {
  name: string;
  format: string;
  formatOfIncommigStringValue?: string;
}

function FormikDatePickerField({ name, ...rest }: IDatePickerField) {
  const { field, ...props } = useDatePickerField(name);
  const formattedDateTime = useFormattedDateTime({
    value: field.value,
    format: rest.format,
  });
  const dateValue = useDateValue({
    value: field.value,
    formatOfIncommigStringValue: rest.formatOfIncommigStringValue,
  });
  return (
    <DatePicker
      {...field}
      value={formattedDateTime}
      {...rest}
      {...props}
      dateValue={dateValue}
    />
  );
}

FormikDatePickerField.defaultProps = {
  format: "yyyy-MM-DD",
};

export default FormikDatePickerField;
