import * as React from "react";
import classnames from "classnames";

import "./styles.scss";
import { Select } from "../Select";
import { SelectProps } from "../Select";

export interface Props extends SelectProps {
  label?: string;
  className?: string;
  error?: string | false;
  classNames?: {
    input: string;
  };
}

export function SelectField({
  id,
  label,
  options,
  value,
  disabled,
  className,
  onChange,
  onBlur,
  placeholder,
  error,
  classNames,
}: Props) {
  const inputClasses = classnames(!!classNames && classNames.input, {
    select__error: !!error,
  });
  const classes = classnames("form-row", className, {
    "form-row__error": !!error,
  });
  return (
    <div className={classes}>
      {label && (
        <label className={disabled ? "label-disabled" : "label"} htmlFor={id}>
          {label}
        </label>
      )}
      <Select
        disabled={disabled}
        id={id}
        options={options}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={inputClasses}
      />
      {error && (
        <label htmlFor={id} className="text__error">
          {error}
        </label>
      )}
    </div>
  );
}

SelectField.defaultProps = {
  disabled: false,
  classNames: {
    input: "",
  },
};
export default SelectField;
