import { useCallback, useMemo } from "react";
import { useFormikContext, useField } from "formik";
import moment from "moment";

import stringToDate from "../../../utils/stringToDate";

export function useDatePickerField(name: string) {
  const formik = useFormikContext();
  const { setFieldValue, setFieldTouched } = formik;
  const [field, meta] = useField(name);
  const onChange = useCallback(
    (value: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue, name]
  );

  const onBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [setFieldTouched, name]);
  return { field, onChange, onBlur, error: meta.touched && meta.error };
}

export function useDateValue({
  value,
  formatOfIncommigStringValue,
}: {
  value: string | Date;
  formatOfIncommigStringValue?: string;
}) {
  return useMemo(() => {
    if (!(value instanceof Date) && value && formatOfIncommigStringValue) {
      const parsed = stringToDate(value, formatOfIncommigStringValue, "-", "-");
      //@ts-ignore
      if (!isNaN(parsed)) {
        return parsed;
      }
    }
    if (value instanceof Date) {
      return value;
    }
    return null;
  }, [value, formatOfIncommigStringValue]);
}

export function useFormattedDateTime({
  value,
  format,
}: {
  value: string | Date;
  format: string;
}) {
  return useMemo(() => {
    return value ? moment(value).format(format) : "";
  }, [value, format]);
}
