import React from "react";
import "./styles.scss";
import { ScreenWrapper } from "../../ScreenWrapper";
import { Chain } from "../../../models/basicModels";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import moment from "moment";
import { LOCAL_CASES } from "../../../constants/urls";
import externalLink from "../../../assets/images/ic-external-link.svg";
import arrowDown from "../../../assets/images/ic-arrow-down.svg";
import { Link } from "react-router-dom";

const mainClassName = "chainInfo_container";
const moreDivClassName = `${mainClassName}__more_container`;
const rowClassName = `${mainClassName}__row`;
const columnClassName = `${rowClassName}__column`;
const borderedColumnClassName = `${columnClassName} ${columnClassName}-border`;
const valueClassName = `${columnClassName}__value`;
const titleClassName = `${columnClassName}__title`;
const linkClassName = `${columnClassName}__link`;
const moreClassName = `${columnClassName}__more-title`;

const dateFormatter: string = "DD MMM hh:mm A";

interface Props {
  chain: Chain[];
}

interface State {
  showAll: boolean;
}

class ChainInfo extends React.Component<Props, State> {
  state = {
    showAll: false,
  };

  render(): React.ReactNode {
    const { chain } = this.props;
    const { showAll } = this.state;
    const chainList = showAll ? chain : chain.slice(0, 2);
    const moreIconClassName =
      "link__image active " + (showAll ? "rotated" : "");

    return (
      <ScreenWrapper leftBorderColor={"#627595"}>
        <div className={mainClassName}>
          <div className={columnClassName}>
            {chainList.map((item: Chain, index: number) => {
              return (
                <div key={index} className={borderedColumnClassName}>
                  <label className={titleClassName}>{`${capitalizeFirstLetter(
                    item.cause
                  )} case`}</label>
                  <div className={rowClassName}>
                    <label className={valueClassName}>
                      {`Created: ${moment(item.createdAt).format(
                        dateFormatter
                      )}`}
                    </label>
                    <label className={valueClassName}>
                      {`Closed: ${moment(item.closedAt).format(dateFormatter)}`}
                    </label>
                    <Link
                      className="hidden-mobile"
                      to={`${LOCAL_CASES}/${item.id}/view`}
                      target="_blank"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open(`${LOCAL_CASES}/${item.id}/view`);
                      }}
                    >
                      <label className={linkClassName}>View details</label>
                      <img
                        src={externalLink}
                        alt="intervention"
                        className="link__image active"
                      />
                    </Link>
                  </div>
                </div>
              );
            })}

            {chain.length > 2 && (
              <div className={columnClassName}>
                <div
                  className={moreDivClassName}
                  onClick={() => this.handlePressMore()}
                >
                  <label className={moreClassName}>
                    {showAll ? "Show less" : "Show more"}
                  </label>
                  <img
                    src={arrowDown}
                    alt="intervention"
                    className={moreIconClassName}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </ScreenWrapper>
    );
  }

  handlePressMore = () => {
    this.setState({
      showAll: !this.state.showAll,
    });
  };
}

export default ChainInfo;
