import axios from "axios";
import { put } from "redux-saga/effects";
import { createSelector } from "reselect";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";
import { errorNotify } from "../../components/Toaster/toster";
import { recognizeErrorMessage } from "../../utils/errors";
import { API_PHYSICIANS } from "../../constants/urls";

import { AppState } from "../reducers";
import { IPhysicians } from "../reducers/physicians";
import {
  getPhysiciansSuccess,
  getPhysiciansFailure,
  IGetPCPStartPayload,
} from "../actions/physicians";

export const physiciansSelector = createSelector(
  ({ physicians }: AppState): IPhysicians => physicians,
  ({ list }) => list
);

export function* getPhysiciansSaga({
  payload,
}: {
  type: string;
  payload: IGetPCPStartPayload;
}) {
  try {
    const { data } = yield axios.get(API_PHYSICIANS, {
      params: snakeCaseKeys(payload),
    });
    yield put(
      getPhysiciansSuccess({
        list: camelCaseKeys(data, { deep: true }),
      })
    );
  } catch (error) {
    const errorMsg = recognizeErrorMessage(error);
    yield put(getPhysiciansFailure(errorMsg));
    errorNotify(errorMsg[0]);
  }
}
