import { all, takeEvery, call, throttle, takeLatest } from "redux-saga/effects";

import actions from "../../constants/actionsTypes";
import {
  authLoginSaga,
  authCheckLocalStorageSaga,
  clearLocalStorageSaga,
  logoutTimeOutSaga,
} from "./auth";
import {
  resetPasswordSaga,
  forgotPasswordSaga,
  setResetTokenSaga,
  resetForgotenPasswordSaga,
} from "./resetPassword";
import { getPatientsSaga, getPatientsExportSaga } from "./patients";
import {
  getPatientSaga,
  addPatientSaga,
  updatePatientSaga,
  getCityStatebyZipSaga,
} from "./patient";
import { getClientsSaga } from "./clients";
import { getPhysiciansSaga } from "./physicians";
import { getDevicesSaga } from "./devices";
import { getPatientDevicesSaga, addPatientDevicesSaga } from "./patientDevices";
import {
  createCaseSaga,
  getCaseChain,
  getCaseSaga,
  getFollowUpCases,
  getHistoryCases,
  getHoldingCases,
  getUnderReviewCases,
  getUnsuccessfulCases,
  updateCaseSaga,
} from "./cases";
import { getSummaryReportSaga, sortSummaryReportSaga } from "./summaryReport";

import {
  setPatientReportChartSaga,
  getPatientReportDataStartSaga,
} from "./patientReport";
import { addPrimaryCarePhysicianSaga } from "./primaryCarePhysician";
import { getNotificationsSaga } from "./notifications";
import { getNotesSaga, addNotesSaga } from "./notes";
import { getHealthGoalssSaga, updateHealthGoalsSaga } from "./healthGoals";
import { getPcpRateSaga } from "./pcpRate";
import { getVitalAlertsSaga, updateVitalAlertsSaga } from "./vitalAlerts";
import {
  addTaskTimerSaga,
  updateTaskTimerSaga,
  deleteTaskTimerSaga,
} from "./taskTimer";
import { getTasksTimersSaga, downloadTasksTimersSaga } from "./tasksTimers";

export default function* rootSaga() {
  yield all([
    yield call(authCheckLocalStorageSaga),
    yield takeEvery(actions.login.signin.start, authLoginSaga),
    yield takeEvery(actions.logout.start, clearLocalStorageSaga),
    yield takeEvery(actions.logoutTimeOut.start_time_out, logoutTimeOutSaga),
    yield throttle(1000, actions.patients.list.get, getPatientsSaga),
    yield takeEvery(actions.cases.get.followup, getFollowUpCases),
    yield takeEvery(actions.cases.get.holding, getHoldingCases),
    yield takeEvery(actions.cases.get.review, getUnderReviewCases),
    yield takeEvery(actions.cases.get.history, getHistoryCases),
    yield takeEvery(actions.cases.view.get, getCaseSaga),
    yield takeEvery(actions.cases.creation.create, createCaseSaga),
    yield takeEvery(actions.cases.update.send, updateCaseSaga),
    yield takeEvery(actions.forgot.start, forgotPasswordSaga),
    yield takeEvery(actions.patient.get, getPatientSaga),
    yield takeEvery(
      actions.changePassword.resetPassword.start,
      resetPasswordSaga
    ),
    yield takeEvery(actions.cases.chain.get, getCaseChain),
    yield takeEvery(actions.cases.get.unsuccessful, getUnsuccessfulCases),
    yield takeEvery(actions.clients.start, getClientsSaga),
    yield takeEvery(actions.physician.start, getPhysiciansSaga),
    yield takeEvery(actions.devices.start, getDevicesSaga),
    yield takeEvery(actions.patient.add.start, addPatientSaga),
    yield takeEvery(actions.patient.update.start, updatePatientSaga),
    yield takeLatest(actions.patientDevices.get.start, getPatientDevicesSaga),
    yield takeEvery(actions.patientDevices.add.start, addPatientDevicesSaga),
    yield takeLatest(actions.summaryReport.get.start, getSummaryReportSaga),
    yield takeLatest(actions.summaryReport.sort.start, sortSummaryReportSaga),
    yield takeEvery(
      actions.patientReport.charts.start,
      setPatientReportChartSaga
    ),
    yield takeEvery(
      actions.patientReport.data.start,
      getPatientReportDataStartSaga
    ),
    yield takeEvery(
      actions.primaryCarePhysician.add.start,
      addPrimaryCarePhysicianSaga
    ),
    yield takeEvery(actions.notifications.get.start, getNotificationsSaga),
    yield throttle(1000, actions.notes.get.start, getNotesSaga),
    yield takeEvery(actions.healthGoals.get.start, getHealthGoalssSaga),
    yield takeEvery(actions.healthGoals.update.start, updateHealthGoalsSaga),
    yield takeEvery(actions.notes.add.start, addNotesSaga),
    yield takeEvery(actions.pcpRate.get.start, getPcpRateSaga),
    yield takeEvery(actions.vitalAlerts.get.start, getVitalAlertsSaga),
    yield takeEvery(actions.vitalAlerts.update.start, updateVitalAlertsSaga),
    yield throttle(
      1000,
      actions.patient.getCityState.start,
      getCityStatebyZipSaga
    ),
    yield takeEvery(actions.changePassword.resetToken.start, setResetTokenSaga),
    yield takeEvery(
      actions.changePassword.resetForgottenPassword.start,
      resetForgotenPasswordSaga
    ),
    yield takeEvery(actions.taskTimer.add.start, addTaskTimerSaga),
    yield takeEvery(actions.taskTimer.update.start, updateTaskTimerSaga),
    yield takeEvery(actions.tasksTimers.get.start, getTasksTimersSaga),
    yield takeEvery(actions.taskTimer.delete.start, deleteTaskTimerSaga),
    yield takeEvery(
      actions.tasksTimers.download.start,
      downloadTasksTimersSaga
    ),
    yield takeEvery(actions.patients.export.get, getPatientsExportSaga),
  ]);
}
