import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import classnames from "classnames";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import searchIcon from "../../../assets/images/ic-shape.svg";
import { useOnChangeHandler, useSelectedDate } from "./DatePicker.hooks";

export interface Props {
  value?: string;
  title?: string;
  onChange?: (value: string) => void;
  error?: string | false;
  format?: string;
  showTimeInput?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  dropdownMode?: "select" | "scroll" | undefined;
  range?: { startDate: Date; endDate: Date };
  classNames?: { datetimeContainer: string };
  datePickerProps?: Partial<ReactDatePickerProps>;
  showCalendarIcon?: boolean;
  dateValue?: Date | null;
}

export function DatePicker({
  value,
  title,
  format,
  showTimeInput, 
  onBlur,
  className,
  error,
  minDate,
  maxDate,
  showMonthDropdown,
  showYearDropdown,
  dropdownMode,
  range,
  onChange,
  classNames,
  datePickerProps,
  showCalendarIcon,
  dateValue,
}: Props) {
  const { selectedDate, setSelectedDate } = useSelectedDate(dateValue);
  const containerClasses = classnames(
    "datetime-container",
    classNames?.datetimeContainer
  );
  const handleChange = useOnChangeHandler(onChange, setSelectedDate);

  const inputClasses = classnames(className, error ? "error" : "");
  const datePickerOptions: ReactDatePickerProps = {
    value,
    className: inputClasses,
    minDate,
    maxDate,
    showMonthDropdown,
    showYearDropdown,
    dropdownMode,
    selected: selectedDate,
    timeInputLabel: "Time:",
    onChange: handleChange,
    dateFormat: format,
    showTimeInput: showTimeInput,
    onBlur,
  };
  if (range) {
    datePickerOptions.startDate = range.startDate;
    datePickerOptions.endDate = range.endDate;
    datePickerOptions.selected = range.startDate;
    datePickerOptions.selectsStart = true;
    datePickerOptions.selectsRange = true;
    //@ts-ignore
    datePickerOptions.onChange = onChange;
    datePickerOptions.showTimeInput = false;
  }
  return (
    <div className={containerClasses}>
      {title && <label className="datetime-container__title">{title}</label>}
      <label className="datetime-container__inner">
        {showCalendarIcon && (
          <div className="datetime-container__inner__icon">
            <img
              style={{ opacity: 50 }}
              src={searchIcon}
              className="datetime-container__icon__image"
              alt="search-icon"
            />
          </div>
        )}

        <ReactDatePicker {...datePickerProps} {...datePickerOptions} />
      </label>
      {error && <p className="datetime-container__error">{error}</p>}
    </div>
  );
}

DatePicker.defaultProps = {
  error: "",
  format: "yyyy-MM-dd h:mm aa",
  showTimeInput: true,
  className: "datetime-picker",
  range: null,
  custonInput: null,
  onChange: () => {},
  showCalendarIcon: true,
};
export default DatePicker;
