import { put, select, call } from "redux-saga/effects";

import { createSelector } from "reselect";
import snakeCaseKeys from "snakecase-keys";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import { camelToSnake } from "../../utils/stringUtils";

import {
  getNotesStart,
  getNotesFailure,
  getNotesSuccess,
  addNotesStart,
  addNotesSuccess,
  addNotesFailure,
  updateNotesStart,
  updateNotesSuccess,
  updateNotesFailure,
} from "../actions/notes";
import { AppState } from "../reducers";
import { API_NOTES } from "../../constants/urls";
import { patientSelector } from "./patient";
import { PatientData } from "../reducers/patient";
import { recognizeErrorMessage } from "../../utils/errors";
import { errorNotify, successNotify } from "../../components/Toaster/toster";

export const notesSelector = createSelector(
  ({ notes }: AppState) => notes,
  (notes) => notes
);

export function* getNotesSaga({ payload }: ReturnType<typeof getNotesStart>) {
  try {
    const patient: PatientData = yield select(patientSelector);
    if (!patient?.uuid) {
      throw new Error("There is no patient!");
    }
    const params = snakeCaseKeys({
      ...payload,
      patient: patient.uuid,
      ordering: payload.ordering ? camelToSnake(payload.ordering) : "",
    });
    const { data } = yield axios.get(API_NOTES, { params });
    yield put(
      getNotesSuccess({
        data: camelCaseKeys(data.results),
        count: data.count,
      })
    );
  } catch (error) {
    yield put(getNotesFailure(error));
  }
}
export function* addNotesSaga({ payload }: ReturnType<typeof addNotesStart>) {
  try {
    const transformed = snakeCaseKeys(payload);
    const { data } = yield axios.post(API_NOTES, transformed);
    yield put(addNotesSuccess());
    successNotify("New note successfully added");
    yield call(payload.onSuccess);
    return data;
  } catch (error) {
    yield put(addNotesFailure(error));
    errorNotify(recognizeErrorMessage(error));
  }
}

export function* updateNotesSaga({
  payload,
}: ReturnType<typeof updateNotesStart>) {
  try {
    const transformed = snakeCaseKeys(payload);
    const { data } = yield axios.patch(`${API_NOTES}${transformed.id}/`, transformed);
    yield put(updateNotesSuccess());
    successNotify("Note successfully updated");
    if (payload.onSuccess) {
      yield call(payload.onSuccess);
    }

    return data;
  } catch (error) {
    yield put(updateNotesFailure(error));
    errorNotify(recognizeErrorMessage(error));
  }
}
