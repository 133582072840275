import React from "react";
import TextareaAutosize from "react-textarea-autosize";

interface Meta {
  active?: boolean;
  error?: string;
  value?: string;
}

export interface TextAreaProps {
  id?: string;
  name?: string;
  readonly?: boolean;
  placeholder?: string;
  value?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | any>) => void;
  tabIndex?: number;
  meta?: Meta;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  required?: boolean;
  autoComplete?: string;
  error?: any;
  readOnly?: boolean;
}

const TextArea = ({
  placeholder,
  rows = 1,
  meta: { active = true } = {},
  error,
  ...rest
}: TextAreaProps) => (
  <TextareaAutosize
    placeholder={active ? placeholder : undefined}
    rows={rows}
    {...rest}
  />
);

export default TextArea;
