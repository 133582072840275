import React from "react";

import { Formik } from "formik";
import { INITIAL_VALUES } from "./ResetPasswordModal.constants";
import validationSchema from "./ResetPasswordModal.schema";
import {
  useSubmitHandler,
  useResetPassword,
  useErrors,
} from "./ResetPasswordModal.hooks";
import ResetPasswordModal from "./ResetPasswordModal.component";
import useShowPassword from "../../hooks/useShowPassword";

interface IProps {
  closeModal: () => void;
}
export default function ResetPasswordContainer({ closeModal }: IProps) {
  const onSubmit = useSubmitHandler(closeModal);
  const { showPassword, toggleShowPassword } = useShowPassword();
  const { loading } = useResetPassword();
  const errors = useErrors();
  return (
    <Formik
      validationSchema={validationSchema()}
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      enableReinitialize
      //@ts-ignore
      initialErrors={errors}
    >
      {({ ...rest }) => {
        return (
          <ResetPasswordModal
            {...rest}
            closeModal={closeModal}
            showPassword={showPassword}
            toogleShowPassword={toggleShowPassword}
            loading={loading}
          />
        );
      }}
    </Formik>
  );
}
