import React, { useCallback } from "react";
import { useField} from "formik";
import TimeField from "react-simple-timefield";
import moment from "moment";

import { TextField, Props as TextFieldProps } from "../TextField";

interface IInputField extends TextFieldProps {}

function getWithNulls(duration: number) {
  if (duration < 10) {
    return "0" + duration;
  }
  return duration;
}

export default function DurationInputField({ ...rest }: IInputField) {
  const [field, form, helpers] = useField(rest.name);
  const message = form.touched && form.error;
  const onChange = useCallback(
    (event: any, time: string) => {
      const miliseconds = time.split(":").reduce((summ, item, key) => {
        if (key === 0) {
          return summ + Number(item) * 60 * 60 * 1000;
        }
        if (key === 1) {
          return summ + Number(item) * 60 * 1000;
        }
        if (key === 2) {
          return summ + Number(item) * 1000;
        }
        return summ;
      }, 0);
      helpers.setValue(miliseconds);
    },
    [helpers]
  );
  const duration = Number(field.value);
  const value = `${getWithNulls(
    moment.duration(duration, "millisecond").hours()
  )}:${getWithNulls(
    moment.duration(duration, "millisecond").minutes()
  )}:${getWithNulls(moment.duration(duration, "millisecond").seconds())})`;
  return (
    <TimeField
      value={value}
      onChange={onChange}
      colon=":"
      showSeconds
      input={
        <TextField
          {...field}
          {...rest}
          value={value}
          error={message}
          label="Time Overal"
          classNames={{ label: "leading-4", input: "h-8 m-0 p-0 flex items-center text-center justify-center text-sm", }}
        />
      }
    />
  );
}
