import actions from "../../constants/actionsTypes";
import { IPcpRate } from "../reducers/pcpRate";

export const getPcpRateStart = () => ({
  type: actions.pcpRate.get.start,
});

export const getPcpRateSuccess = (payload: IPcpRate) => ({
  type: actions.pcpRate.get.success,
  payload,
});

export const getPcpRateFailure = (error: any) => ({
  type: actions.pcpRate.get.fail,
  payload: error,
});

export const resetPcpRate = () => ({
  type: actions.pcpRate.reset,
});
