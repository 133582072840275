import React from "react";
import { useField } from "formik";
import { TextField, Props as TextFieldProps } from "../TextField";

interface IInputField extends TextFieldProps {}

export default function InputField({ ...rest }: IInputField) {
  const [field, form] = useField(rest.name);
  const message = form.touched && form.error;
  return <TextField {...field} {...rest} error={message} />;
}
