const actions = {
  login: {
    signin: {
      start: "signIn_start",
      success: "signIn_success",
      fail: "signIn_fail",
    },
  },
  forgot: {
    start: "forgot_start",
    success: "forgot_success",
    fail: "forgot_fail",
  },
  changePassword: {
    resetPassword: {
      start: "reset_start",
      success: "reset_success",
      fail: "reset_fail",
      reset_error: "reset_error",
    },
    resetToken: {
      start: "reset_token_start",
      success: "reset_token_success",
      fail: "reset_token_fail",
    },
    resetForgottenPassword: {
      start: "reset_resetForgotten_start",
      success: "reset_resetForgotten_success",
      fail: "reset_resetForgotten_fail",
    },
  },
  logout: {
    start: "logout_start",
  },
  logoutTimeOut: {
    start_time_out: "logout_start_time_out",
  },
  patients: {
    list: {
      get: "patients_get",
      success: "patients_success",
      fail: "patients_fail",
      reset: "patients_reset",
    },
    export: {
      get: "patients_export_get",
      success: "patients_export_success",
      fail: "patients_export_fail",
      reset: "patients_export_reset",
    },
  },
  patient: {
    add: { start: "add_start", fail: "add_fail", success: "add_sucess" },
    update: {
      start: "update_start",
      fail: "update_fail",
      success: "update_sucess",
    },
    get: "patient_get",
    success: "patient_success",
    fail: "patient_fail",
    reset: "patient_reset",
    getCityState: {
      start: "get_city_state_start",
      success: "get_city_state_success",
      fail: "get_city_state_fail",
    },
  },
  cases: {
    get: {
      followup: "cases_get_followup",
      holding: "cases_get_holding",
      review_start: "cases_get_review_start",
      review: "cases_get_review",
      history: "cases_get_history",
      unsuccessful: "case_get_unsuccessful",
      untrained: "case_get_untrained",
    },
    success: {
      followup: "cases_success_followup",
      holding: "cases_success_holding",
      review_start: "cases_get_review_start",
      review: "cases_success_review",
      history: "cases_success_history",
      unsuccessful: "case_success_unsuccessful",
    },
    creation: {
      create: "cases_creation_create",
      success: "cases_creation_success",
      reset: "cases_creation_reset",
      fail: "cases_update__fail",
    },
    update: {
      start: "cases_update_start",
      send: "cases_update_send",
      success: "cases_update_success",
      fail: "cases_update__fail",
    },
    view: {
      get: "cases_review_get",
      success: "cases_review_success",
      fail: "cases_review_fail",
      reset: "case_review_reset",
    },
    chain: {
      get: "case_get_chain",
      success: "case_get_chain_success",
      fail: "case_get_chain__fail",
    },
  },
  settings: {
    setup: "settings_setup",
  },
  searchPanelFilters: {
    get: "search_panel_filters_get",
    update: "search_panel_filters_update",
    reset: "search_panel_filters_reset",
  },
  clients: {
    start: "get_clients_start",
    success: "get_clients_success",
    fail: "get_clients_fail",
  },
  devices: {
    start: "get_devices_start",
    success: "get_devices_success",
    fail: "get_devices_fail",
    reset: "devices_reset",
  },
  patientDevices: {
    reset: "reset_patient_devices",
    get: {
      start: "get_patient_devices_start",
      success: "get_patient_devices_success",
      fail: "get_patient_devices_fail",
    },
    add: {
      start: "add_patient_devices_start",
      success: "add_patient_devices_success",
      fail: "add_patient_devices_fail",
    },
  },
  physician: {
    start: "physician_start",
    success: "physician_success",
    fail: "physician_fail",
  },
  summaryReport: {
    get: {
      start: "summary_report_start",
      success: "summary_report_success",
      fail: "summary_report_fail",
    },
    resetModal: "summary_report_modal_reset",
    sort: {
      start: "summary_report_sort_start",
      success: "summary_report_sort_success",
      fail: "summary_report_sort_fail",
    },
    reset: "summary_report_reset",
  },
  patientReport: {
    charts: {
      start: "patient_report_start",
      success: "patient_report_success",
      fail: "patient_report_fail",
    },
    data: {
      start: "patient_report_data_start",
      success: "patient_repor_data_success",
      fail: "patient_report_data_fail",
    },
    reports: {
      start: "patient_reports_start",
      success: "patient_repors_success",
      fail: "patient_reports_fail",
    },
    modal: {
      reset: "patient_report_modal_reset",
    },
    reset: "patient_report_reset",
  },
  primaryCarePhysician: {
    add: {
      start: "primary_care_physician_start",
      success: "primary_care_physician_success",
      fail: "patient_care_physician_fail",
    },
    reset: "primary_care_physician_reset",
  },
  notifications: {
    get: {
      start: "notifications_start",
      success: "notifications_success",
      fail: "notifications_fail",
    },
    reset: "notifications_reset",
  },
  notes: {
    get: {
      start: "get_notes_start",
      success: "get_notes_success",
      fail: "get_notes_fail",
    },
    add: {
      start: "add_notes_start",
      success: "add_notes_success",
      fail: "add_notes_fail",
    },
    update: {
      start: "add_notes_start",
      success: "add_notes_success",
      fail: "add_notes_fail",
    },
    reset: "notes_reset",
  },

  healthGoals: {
    get: {
      start: "health_goals_start",
      success: "health_goals_success",
      fail: "health_goals_fail",
    },
    update: {
      start: "health_goals_update_start",
      success: "health_goals_update_success",
      fail: "health_goals_update_fail",
    },
    reset: "health_goals_reset",
  },
  pcpRate: {
    get: {
      start: "pcp_rate_start",
      success: "pcp_rate_success",
      fail: "pcp_rate_fail",
    },
    reset: "pcp_rate_reset",
  },
  vitalAlerts: {
    get: {
      start: "vital_alerts_start",
      success: "vital_alerts_success",
      fail: "vital_alerts_fail",
    },
    update: {
      start: "vital_alerts_update_start",
      success: "vital_alerts_update_success",
      fail: "vital_alerts_update_fail",
    },
    reset: "vital_alerts_reset",
  },
  taskTimer: {
    timer: {
      start: "task_timer_start",
      stop: "task_timer_stop",
      pause: "task_timer_pause",
      reset: "task_timer_reset_timer",
      resetData: "task_timer_resetData_timer",
    },
    panel: {
      show: "task_timer_panel_show",
      hide: "task_timer_panel_hide",
    },
    form: {
      show: "task_timer_form_show",
      hide: "task_timer_form_hide",
      set: "task_timer_form_set",
    },
    add: {
      start: "task_timer_add_start",
      success: "task_timer_add_success",
      fail: "task_timer_add_fail",
    },
    update: {
      start: "task_timer_update_start",
      success: "task_timer_update_success",
      fail: "task_timer_update_fail",
    },
    get: {
      start: "task_timer_get_start",
      success: "task_timer_get_success",
      fail: "task_timer_get_fail",
    },
    delete: {
      start: "task_timer_delete_start",
      success: "task_timer_delete_success",
      fail: "task_timer_delete_fail",
    },
    reset: "task_timer_reset",
    resetStarted: "task_timer_resetStarted",
  },
  tasksTimers: {
    get: {
      start: "tasks_timers_start",
      success: "tasks_timers_success",
      fail: "tasks_timers_pause",
    },
    download: {
      start: "download_tasks_timers_start",
      success: "download_tasks_timers_stop",
      fail: "download_tasks_timers_pause",
    },
    reset: "tasks_timers_reset",
  },
};

export default actions;
