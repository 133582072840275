import { useState, useCallback, useEffect } from "react";

export function useOnChangeHandler(
  onChange: ((value: string) => void) | undefined,
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
) {
  return useCallback(
    (selectedDate) => {
      setSelectedDate(() => selectedDate);
      onChange && onChange(selectedDate);
    },
    [onChange, setSelectedDate]
  );
}

export function useSelectedDate(dateValue?: Date | null) {
  const [selectedDate, setSelectedDate] = useState<Date>(
    dateValue || new Date()
  );
  useEffect(() => {
    if (dateValue) {
      setSelectedDate(dateValue);
    }
  }, [dateValue]);
  return { selectedDate, setSelectedDate };
}
