import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { taskTimerStart } from "../store/actions/taskTimer";
import TIMER_STATES from "../constants/timerStates";
import { useTaskTimer, usePatient } from "../hooks";

export default function useStartTimerHandler(cb?: () => void) {
  const dispatch = useDispatch();
  const patient = usePatient();
  const { startTimeHistory, state } = useTaskTimer();
  return useCallback(() => {
    const newStartTime = [...startTimeHistory];
    newStartTime.push(new Date());
    if (TIMER_STATES.STARTED !== state) {
      dispatch(
        taskTimerStart({
          patient: patient.uuid,
          startTimeHistory: newStartTime,
          state: TIMER_STATES.STARTED,
        })
      );
      cb&&cb();
    }
  }, [dispatch, patient, state, startTimeHistory, cb]);
}
