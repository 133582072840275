import actions from "../../constants/actionsTypes";
import { ITimers } from "../reducers/tasksTimers";

interface IPayload extends Partial<ITimers> {
  ordering?: string;
}

export const getTasksTimersStart = (
  payload: Omit<IPayload, "data" | "loading" | "error">
) => ({
  type: actions.tasksTimers.get.start,
  payload,
});

export const getTasksTimersSuccess = (payload: IPayload) => ({
  type: actions.tasksTimers.get.success,
  payload,
});

export const getTasksTimersFail = (payload: any) => ({
  type: actions.tasksTimers.get.fail,
  payload,
});

export const resetTasksTimers = () => ({
  type: actions.tasksTimers.reset,
});

export const downloadTasksTimersReportStart = (payload: {
  patientId: string | null;
  startDate: string | null;
  endDate: string | null;
  firstName: string,
  lastName: string
}) => ({
  type: actions.tasksTimers.download.start,
  payload,
});

export const downloadTasksTimersReportSuccess = () => ({
  type: actions.tasksTimers.download.success,
});

export const downloadTasksTimersReportFail = (payload: IPayload) => ({
  type: actions.tasksTimers.download.fail,
  payload,
});
