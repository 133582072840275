import { useState, useCallback } from "react";

export function useHeaderModal() {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal((value) => !value);
  }, []);
  return { showModal, setShowModal, toggleModal };
}

export function usePrintHandler() {
  return useCallback(() => window.print(), []);
}

export function useCheckAvatarLink() {
  const [isBrokenLink, setIsBrokenLink] = useState(false);
  const onErrorHandler = useCallback(() => {
    setIsBrokenLink(true);
  }, []);
  return { isBrokenLink, setIsBrokenLink, onErrorHandler };
}
