import actions from "../../constants/actionsTypes";
import { IPhysicians } from "../reducers/physicians";

export interface IGetPCPStartPayload {
  client: string;
}
export const getPhysiciansStart = (payload: IGetPCPStartPayload) => ({
  type: actions.physician.start,
  payload,
});

export const getPhysiciansSuccess = (payload: IPhysicians) => ({
  type: actions.physician.success,
  payload,
});

export const getPhysiciansFailure = (error: any) => ({
  type: actions.physician.fail,
  payload: error,
});
