import moment from "moment";

import { Case, MeasurementStepsValue } from "../models/basicModels";
import callResults from "../constants/callResults";

const dateFormatter: string = "YYYY-MM-DD hh:mm A";

export default function prepareMeasurement(
  currentCase: Case,
  step: number
): MeasurementStepsValue[] {
  let result: MeasurementStepsValue[] = [];
  const { measurements } = currentCase;
  if (measurements.length === 0) {
    return [];
  }

  const stepMeasurement = measurements.find((val) => val.step === step);
  if (stepMeasurement) {
    const stepDate = stepMeasurement.date
      ? moment(stepMeasurement.date).format(dateFormatter)
      : "";
    let callResult: string = "";
    const callResultIndex: number = callResults.findIndex(
      (item) => item.value === stepMeasurement.callResult
    );
    if (callResultIndex >= 0) {
      callResult = callResults[callResultIndex].name as string;
    }

    // eslint-disable-next-line array-callback-return
    stepMeasurement.values.map((value: any, index: number) => {
      const valueStr =
        value.values && value.values.length
          ? value.values
              .map(function (item: any) {
                return `${item["measurement"]}: ${item["value"]}`;
              })
              .join(" | ")
          : value.values;

      const findIndex = result.findIndex((item) => item.type === value.type);
      if (findIndex < 0) {
        const newItem: MeasurementStepsValue = {
          type: value.type,
          column1: step === 2 ? (index === 0 ? stepDate : "") : value.type,
          column2:
            step === 2
              ? index === 0
                ? callResult
                : ""
              : moment(value.date).format(dateFormatter),
          values: valueStr,
        };
        result.push(newItem);
      }
    });
  }

  return result;
}
