import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

import { patientSelector } from "../store/sagas/patient";

import { getPatient, resetPatient } from "../store/actions/patient";

export default function usePatientByParamId() {
  const patient = useSelector(patientSelector);
  const params: { id: string } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (params.id) {
      dispatch(getPatient({ uuid: params.id }));
      return () => {
        dispatch(resetPatient());
      };
    }
  }, [params.id, dispatch]);
  return patient;
}
